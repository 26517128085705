// userManagementSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [], // Array to store user data
};

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    createUser: (state, action) => {
      state.users.push(action.payload); // Add new user to the users array
    },
  },
});

export const { createUser } = userManagementSlice.actions;

export const selectUsers = (state) => state.userManagement.users; // Selector to access user data

export default userManagementSlice.reducer;
