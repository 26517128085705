import { ReportedPropsProvider } from "../../context/ReportedPropsContext";
import ReportedPropsDashboard from "./components/ReportedPropsDashboard";

const ReportedProps = () => {
  return (
    <ReportedPropsProvider>
      <div className="bg-[#F5F4F8]">
        <ReportedPropsDashboard />
      </div>
    </ReportedPropsProvider>
  );
};

export default ReportedProps;
