import { createContext, useEffect, useState } from "react";
import axiosInstance from "../Interceptor/axiosInstance";

const UserReviewContext = createContext();
const UserReviewProvider = ({ children }) => {
  const [showDropDown, setShowDropDown] = useState("");
  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [filteredData, setFilteredData] = useState();
  const [selectedDateForFilter, setSelectedDateForFilter] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [reviewCount, setReviewCount] = useState(0);

  

  const handleFilterRating = (rating) => {
    setSelectedRating(rating);

    const filteredDataByRating = filteredData.filter(
      (data) => data.Rating === rating
    );
    setTableData(filteredDataByRating);
    setReviewCount(filteredDataByRating.length);
    setShowDropDown("");
  };

  const handleDateChange = (e) => {
    const date = new Date(e);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;

    setSelectedDateForFilter(formattedDate);
  };

  const handleDateFilter = () => {
    const filteredData = originalData.filter((data) =>
      data.createdAt.includes(selectedDateForFilter)
    );
    setTableData(filteredData);
    setFilteredData(filteredData);
    setReviewCount(filteredData.length);
    
    setShowDropDown("");
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/admin/getAll/reviewData"
      );
      const data = response?.data;

      setTableData(data.message);
      setOriginalData(data.message);
      setFilteredData(data.message);
      setReviewCount(data.message.length);
      
    } catch (error) {
      
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <UserReviewContext.Provider
      value={{
        tableData,
        setTableData,
        currentUser,
        setCurrentUser,
        handleDateFilter,
        selectedDateForFilter,
        setSelectedDateForFilter,
        handleDateChange,
        showDropDown,
        setShowDropDown,
        handleFilterRating,
        fetchData,
        filteredData,
        selectedRating,
        setSelectedRating,
        reviewCount,
      }}
    >
      {children}
    </UserReviewContext.Provider>
  );
};

export { UserReviewContext, UserReviewProvider };
