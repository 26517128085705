

const CallCenter = () => {
  return (
    <div>
      hi this is call centerpage
    </div>
  )
}

export default CallCenter
