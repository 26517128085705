import { useState } from "react";
import SelectPlace from "./SelectPlace";
import {tndistrict} from "../../../districts";


const ManagePopularPlace = () => {
  const [placesData, setPlacesData] = useState(tndistrict.districts);

  // const fetchData = async () => {
  //   const response = await fetch("https://dummyjson.com/users");
  //   const data = await response.json();
  //   setPlacesData(data.users);
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []); 

  return (
    <div>
      <h3 className="font-roboto font-bold text-[19px]">
        Manage Popular Places
      </h3>
      <p className="font-inter font-bold text-[#7B7D8C] text-xs py-3">
        Home -{`>`} In-App Customization -{`>`} Popular Places
      </p>
      <div className="w-[65%] mx-auto mt-12">
        <SelectPlace placesData={placesData} />
      </div>
    </div>
  );
};

export default ManagePopularPlace;
