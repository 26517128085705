import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { formSlice } from './formSlice'; // Import formSlice correctly
import authReducer from './authSlice';
import userManagementReducer from './userManagementSlice';
import imageReducer from './imageSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  form: formSlice.reducer, // Use formSlice.reducer correctly
  userManagement: userManagementReducer,
  image: imageReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
