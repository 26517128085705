
const ViewComponent = (props) => (
  <svg
    width={34}
    height={34}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={19} cy={19} r={19} fill="#D9D9D9" />
    <g clipPath="url(#clip0_4005_284)">
      <path
        opacity={0.5}
        d="M9 19C9 20.64 9.425 21.191 10.275 22.296C11.972 24.5 14.818 27 19 27C23.182 27 26.028 24.5 27.725 22.296C28.575 21.192 29 20.639 29 19C29 17.36 28.575 16.809 27.725 15.704C26.028 13.5 23.182 11 19 11C14.818 11 11.972 13.5 10.275 15.704C9.425 16.81 9 17.361 9 19Z"
        fill="#8E8E8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 19C15.25 18.0054 15.6451 17.0516 16.3483 16.3483C17.0516 15.6451 18.0054 15.25 19 15.25C19.9946 15.25 20.9484 15.6451 21.6517 16.3483C22.3549 17.0516 22.75 18.0054 22.75 19C22.75 19.9946 22.3549 20.9484 21.6517 21.6517C20.9484 22.3549 19.9946 22.75 19 22.75C18.0054 22.75 17.0516 22.3549 16.3483 21.6517C15.6451 20.9484 15.25 19.9946 15.25 19ZM16.75 19C16.75 18.4033 16.9871 17.831 17.409 17.409C17.831 16.9871 18.4033 16.75 19 16.75C19.5967 16.75 20.169 16.9871 20.591 17.409C21.0129 17.831 21.25 18.4033 21.25 19C21.25 19.5967 21.0129 20.169 20.591 20.591C20.169 21.0129 19.5967 21.25 19 21.25C18.4033 21.25 17.831 21.0129 17.409 20.591C16.9871 20.169 16.75 19.5967 16.75 19Z"
        fill="#8E8E8E"
      />
    </g>
    <defs>
      <clipPath id="clip0_4005_284">
        <rect width={24} height={24} fill="white" transform="translate(7 7)" />
      </clipPath>
    </defs>
  </svg>
);
export default ViewComponent;
