import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setImageUrl } from "../store/imageSlice"; // Assuming the path to your imageSlice is correct
import AWS from "aws-sdk";
import Resizer from "react-image-file-resizer";
import { CircularProgress } from "@mui/material";
import userProfile from "../assets/userProfile.jpg";

AWS.config.update({
  region: "us-east-1",
  accessKeyId: "AKIAXKPUZW5AH27B7EGM",
  secretAccessKey: "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",
});

const S3 = new AWS.S3();

function UserProfile({ profilePic }) {
  
  const [uploading, setUploading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    profilePic ? profilePic : userProfile
  );

  useEffect(() => {
    setProfilePicture(profilePic || userProfile);
  }, [profilePic]);
  

  const uploadFileToS3 = async (bucketName, file) => {
    const fileName = file.name
      ? `images/${Date.now()}_${file.name}`
      : `images/${Date.now()}_default.png`; // Unique file name

    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };

    try {
      const uploadResponse = await S3.upload(params).promise();
      return uploadResponse.Location; // URL of the uploaded file
    } catch (error) {
      throw new Error(`Error uploading file to S3: ${error.message}`);
    }
  };

  const resizeImage = (file, fileName) => {
    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        2000, // New width
        1200, // New height
        "JPEG", // Format
        90, // Quality
        0, // Rotation
        (uri) => {
          resolve({
            blob: uri,
            name: fileName,
          });
        },
        "blob"
      );
    });
  };

  const dispatch = useDispatch(); // Initializing useDispatch hook

  const handleUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const { blob: resizedImageBlob, name: fileName } = await resizeImage(
          file,
          file.name
        );

        const resizedFile = new File([resizedImageBlob], fileName, {
          type: resizedImageBlob.type,
        });
        setUploading(true);
        const fileUrl = await uploadFileToS3("vattara-img", resizedFile);
        setProfilePicture(fileUrl); // Set local state for immediate display
        dispatch(setImageUrl(fileUrl)); // Dispatch action to store image URL in Redux store
        setUploading(false);
      } catch (error) {
        
      } finally {
        e.target.value = null;
      }
    }
  };

  // const uploadImage = async (formData) => {
  //   try {
  //     const response = await axios.post(
  //       "https://vattaram.in/user/imageUpload",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     const imageUrl = response.data[0].url; // Assuming the image URL is received correctly
  //     setProfilePicture(imageUrl); // Set local state for immediate display
  //     dispatch(setImageUrl(imageUrl)); // Dispatch action to store image URL in Redux store
  //   } catch (error) {
  //     console.error("Error uploading image:", error);
  //   }
  // };

  const handleRemove = () => {
    setProfilePicture(userProfile);
  };

  return (
    <>
      <div className="flex items-center">
        {/* Avatar */}
        <div className="relative flex flex-row">
          {/* Profile Picture */}
          <div className="relative rounded-full ">
            <img
              src={profilePicture}
              alt="Profile Picture"
              className="w-32 h-32 rounded-full object-cover"
            />
            {uploading && (
              <div className="absolute inset-0 rounded-[4px] flex items-center justify-center w-full h-full rounded-full bg-black opacity-50">
                {<CircularProgress />}
              </div>
            )}
          </div>

          <input
            type="file"
            id="photo-upload"
            accept="image/*"
            className="hidden"
            onChange={handleUpload}
          />
          <div className="ml-5 w-36 relative top-2">
            <p className="text-xs leading-3 ">
              We recommend an image of at least 512x512 for the profile picture
            </p>
            <button
              className="w-40 h-12 rounded-md border border-black cursor-pointer p-2 font-roboto text-base font-bold text-gray-700 relative top-4"
              onClick={() => document.getElementById("photo-upload").click()}
            >
              Choose Image
            </button>
          </div>
        </div>
      </div>
      {profilePicture && (
        <button
          onClick={handleRemove}
          className="mb-5 ml-8 mr-10 border-none w-12 p-2 font-roboto text-sm font-bold text-gray-700 relative top-2"
        >
          Remove
        </button>
      )}
    </>
  );
}

export default UserProfile;
