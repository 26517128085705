export const tndistrict = {
  districts: [
    {
      id: 1,
      name: "Ariyalur",
      name_tamil: "அரியலூர்",
    },
    {
      id: 2,
      name: "Erode",
      name_tamil: "ஈரோடு",
    },
    {
      id: 3,
      name: "Nilgiris",
      name_tamil: "உதகமண்டலம்",
    },
    {
      id: 4,
      name: "Cuddalore",
      name_tamil: "கடலூர்",
    },
    {
      id: 5,
      name: "Karur",
      name_tamil: "கரூர்",
    },
    {
      id: 6,
      name: "Kallakurichi",
      name_tamil: "கள்ளக்குறிச்சி",
    },
    {
      id: 7,
      name: "Kanchipuram",
      name_tamil: "காஞ்சிபுரம்",
    },
    {
      id: 8,
      name: "Krishnagiri",
      name_tamil: "கிருஷ்ணகிரி",
    },
    {
      id: 9,
      name: "Coimbatore",
      name_tamil: "கோயம்புத்தூர்",
    },
    {
      id: 10,
      name: "Sivaganga",
      name_tamil: "சிவகங்கை",
    },
    {
      id: 11,
      name: "Chengalpattu",
      name_tamil: "செங்கல்பட்டு",
    },
    {
      id: 12,
      name: "Chennai",
      name_tamil: "சென்னை",
    },
    {
      id: 13,
      name: "Salem",
      name_tamil: "சேலம்",
    },
    {
      id: 14,
      name: "Thanjavur",
      name_tamil: "தஞ்சாவூர்",
    },
    {
      id: 15,
      name: "Dharmapuri",
      name_tamil: "தர்மபுரி",
    },
    {
      id: 16,
      name: "Dindigul",
      name_tamil: "திண்டுக்கல்",
    },
    {
      id: 17,
      name: "Tiruchirappalli",
      name_tamil: "திருச்சி",
    },
    {
      id: 18,
      name: "Tirunelveli",
      name_tamil: "திருநெல்வேலி",
    },
    {
      id: 19,
      name: "Thirupattur",
      name_tamil: "திருப்பத்தூர்",
    },
    {
      id: 20,
      name: "Tiruppur",
      name_tamil: "திருப்பூர்",
    },
    {
      id: 21,
      name: "Tiruvannamalai",
      name_tamil: "திருவண்ணாமலை",
    },
    {
      id: 22,
      name: "Tiruvallur",
      name_tamil: "திருவள்ளூர்",
    },
    {
      id: 23,
      name: "Thiruvarur",
      name_tamil: "திருவாரூர்",
    },
    {
      id: 24,
      name: "Thoothukudi",
      name_tamil: "தூத்துக்குடி",
    },
    {
      id: 25,
      name: "Tenkasi",
      name_tamil: "தென்காசி",
    },
    {
      id: 26,
      name: "Theni",
      name_tamil: "தேனி",
    },
    {
      id: 27,
      name: "Nagapattinam",
      name_tamil: "நாகப்பட்டினம்",
    },
    {
      id: 28,
      name: "Kanniyakumari",
      name_tamil: "நாகர்கோயில்",
    },
    {
      id: 29,
      name: "Namakkal",
      name_tamil: "நாமக்கல்",
    },
    {
      id: 30,
      name: "Pudukkottai",
      name_tamil: "புதுக்கோட்டை",
    },
    {
      id: 31,
      name: "Perambalur",
      name_tamil: "பெரம்பலூர்",
    },
    {
      id: 32,
      name: "Madurai",
      name_tamil: "மதுரை",
    },
    {
      id: 33,
      name: "Mayiladuthurai",
      name_tamil: "மயிலாடுதுறை",
    },
    {
      id: 34,
      name: "Ranipet",
      name_tamil: "ராணிப்பேட்டை",
    },
    {
      id: 35,
      name: "Ramanathapuram",
      name_tamil: "ராமநாதபுரம்",
    },
    {
      id: 36,
      name: "Virudhunagar",
      name_tamil: "விருதுநகர்",
    },
    {
      id: 37,
      name: "Viluppuram",
      name_tamil: "விழுப்புரம்",
    },
    {
      id: 38,
      name: "Vellore",
      name_tamil: "வேலூர்",
    },
  ],
};
