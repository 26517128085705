import { Radio } from "@mui/material";
import { useContext } from "react";
import { PopularPlacesContext } from "../../../context/PopularPlacesContext";
import axiosInstance from "../../../Interceptor/axiosInstance";

const PlacesModal = ({ showModal, places, imageIndex }) => {
  const { images, updatedImage, setUpdatedImage, setShowConfirmationModal } =
    useContext(PopularPlacesContext);

  // const modalRef = useRef(null);
  // useEffect(() => {
  //   if (modalRef.current) {
  //     modalRef.current?.scrollTo(0, 0);
  //   }
  // }, [images]);

  const handleDivClick = async (place) => {

    try{
    const response = await axiosInstance.get(
      `/api/admin/district/imageGet?district=${place.name}`
    );
   
   
    const imageUrl = response && response.data.message.district_image.url;
   
    const updatedImages = images.map((image, index) =>
      index === imageIndex
        ? {
            ...image,
            image: {
              ...image.image,
              url: imageUrl,
            },
            popular_districts: place.name,
          }
        : image
    );
    setUpdatedImage(updatedImages);
    setShowConfirmationModal(true);
  }
  catch(e){
    
  }
  };

  const currentImage =
    (updatedImage &&
      updatedImage.find((image, index) => index === imageIndex)
        ?.popular_districts) ||
    (images &&
      images.find((image, index) => index === imageIndex)?.popular_districts);


  return (
    <div
      // ref={modalRef}
      className={`${
        showModal ? "opacity-100 z-10" : "opacity-0 -z-10"
      } transition-opacity duration-300 bg-[#FFFFFF] w-[270px] h-[450px] absolute top-11 right-0 flex flex-col overflow-y-scroll drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]`}
    >
      <ul className="font-k2d font-semibold text-[#000000]">
        {places.map((place, i) => (
          <div
            onClick={() =>
              images[imageIndex].popular_districts !== place.name &&
              handleDivClick(place)
            }
            key={i}
            className={` 
             ${
               imageIndex !== "" &&
               images[imageIndex].popular_districts === place.name
                 ? "opacity-50"
                 : "opacity-100"
             }
             cursor-pointer flex justify-between items-center p-1 border-b-[0.3px] border-b[#80848E]`}
          >
            <li className="pl-4">{place.name}</li>
            <div className="text-[8px]">
              <Radio
                onClick={() =>
                  images[imageIndex].popular_districts !== place.name &&
                  handleDivClick(place)
                }
                checked={place.name === currentImage}
                sx={{
                  color: "#80848E",
                  "&.Mui-checked": { color: "#02a7e4" },
                }}
              />
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default PlacesModal;
