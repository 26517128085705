import { useContext, useEffect, useState } from "react";
import { PostPropertyContext } from "../../../context/postPropertyContext";
import { useSelector } from "react-redux";
import {
  selectEmpId,
  selectUserPermissions,
  selectUserData,
} from "../../../store/authSlice";
import axiosInstance from "../../../Interceptor/axiosInstance";
import propImgPlaceholder from "../../../assets/dummy.webp";
import { CircularProgress } from "@mui/material";

const SinglePropertyCard = ({ property, index }) => {
  const [access, setAccess] = useState();
  const { fetchData, currentPropertyTypeTab } = useContext(PostPropertyContext);
  const empId = useSelector(selectEmpId);
  const empData = useSelector(selectUserData);
  const [loader, setLoader] = useState("");
  const empName = empData?.username;
  

  const expectedPermission = "Property Management";
  const userRolePermission = useSelector(selectUserPermissions);

  useEffect(() => {
    const hasAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.edit === "true"
    );

    setAccess(hasAccess);
  }, [userRolePermission]);

  const { selectedProperty, setSelectedProperty, setShowDetails } =
    useContext(PostPropertyContext);

  const handleViewDetails = async () => {
    // setSelectedProperty(property);
    setShowDetails(true);

    try {
      const response = await axiosInstance.get(
        `/api/admin/findId/propertyDetail/${property?.propertyID}`
      );

      setSelectedProperty(response.data?.Data[0]);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleApproveOrReject = async (status) => {
    setLoader(status);
    try {
      const response = await axiosInstance.put(
        "/api/admin/property/approval",
        {
          emp_id: empId,
          post_id: property?.post_id,
          property_type: property?.property_type,
          looking_to: property?.property_available_for,
          post_approval: status,
        }
      );

      setLoader("");
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  // const handleReject = async () => {
  //   setLoader("reject");
  //   try {
  //     const response = await fetch(
  //       "https://vattaram.in/api/admin/property/approval",
  //       {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           emp_id: empId,
  //           post_id: property?.post_id,
  //           property_type: property?.property_type,
  //           looking_to: property?.property_available_for,
  //           post_approval: "Rejected",
  //         }),
  //       }
  //     );
  //     const result = await response.json();
  //     
  //     fetchData();
  //     setLoader("");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <div
      onClick={handleViewDetails}
      className={`${
        selectedProperty?.propertyID === property?.propertyID
          ? "bg-[#E4E0EE]"
          : "bg-white"
      } flex items-center shadow-sm text-xs  rounded-[8px]`}
    >
      <div className="w-[40%] px-3 py-5 flex gap-3 items-center">
        <div className="w-[35%] h-full relative">
          <span className="absolute bg-[#02a7e4] px-2 text-white rounded-r-[8px]">
            {index + 1}
          </span>
          <img
            className="w-full h-[100px] object-cover"
            src={
              property?.images && property?.images.length > 0
                ? property?.images[0].uri
                : propImgPlaceholder
            }
            alt="property"
          />
        </div>
        {/* {property?.looking_to}
        {property.property_available_for}
        {property?.property_type} */}
        <div className="w-[65%] flex flex-col gap-2">
          <h4 className="font-bold text-[#18181B]">
            {property?.building_name && property?.building_name}
          </h4>
          <p className="font-medium text-[#71717A]">
            {property?.city && property?.city}
          </p>
          <div className="font-bold">
            <span className="text-[#18181B] border-r-[1px] border-r-[#18181B] pr-1">
              {(property?.cost && property?.cost) ||
                (property?.monthly_rent && property?.monthly_rent) ||
                (property?.price && property?.price) ||
                (property?.lease_amount && property?.lease_amount) ||
                (property?.PriceperSqft && property?.PriceperSqft)}
            </span>
            <span className="text-[#404040] pl-1">
              {`${property?.built_up_area && property?.built_up_area} Sq.ft`}
            </span>
          </div>
        </div>
      </div>
      <div className="w-[0.5px] h-[90px] bg-[#8E8E8E]"></div>
      <div className="w-[40%] text-[#404040] text-xs flex gap-3 pl-4 whitespace-nowrap">
        <table className="table-fixed">
          <tbody>
            <tr>
              <td>Property ID</td>
              <td className="px-3">:</td>
              <td>{property?.propertyID && property?.propertyID}</td>
            </tr>
            <tr>
              <td>Property Title</td>
              <td className="px-3">:</td>
              <td>{property?.building_name && property?.building_name}</td>
            </tr>
            <tr>
              <td>Date Posted</td>
              <td className="px-3">:</td>
              <td>{property?.createdAt && property?.createdAt}</td>
            </tr>
            {property?.post_approval !== "Pending" && (
              <tr>
                <td>Reviewed on</td>
                <td className="px-3">:</td>
                <td>
                  {property?.verified_time ? property?.verified_time : "-"}
                </td>
              </tr>
            )}

            <tr>
              <td>Status</td>
              <td className="px-3">:</td>
              <td
                className={`${
                  property?.post_approval === "Approved"
                    ? "text-[#8BC83F]"
                    : property?.post_approval === "Rejected"
                    ? "text-red-600"
                    : property?.post_approval === "Pending"
                    ? "text-[#D28A29]"
                    : "text-black"
                }  font-bold`}
              >
                {property?.post_approval}
              </td>
            </tr>
            {property?.post_approval === "Pending" ? null : (
              <>
                <tr>
                  <td>
                    {property?.post_approval === "Approved"
                      ? "Approved By"
                      : "Rejected By"}
                  </td>
                  <td className="px-3">:</td>
                  <td>{property?.emp_name ? property?.emp_name : "-"}</td>
                </tr>
                <tr>
                  <td>Emp Id</td>
                  <td className="px-3">:</td>
                  <td>{property?.emp_id ? property?.emp_id : "-"}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>

      <div className="w-[0.5px] h-[90px] bg-[#8E8E8E]"></div>
      <div className="flex flex-col gap-2 w-fit mx-auto text-[#202020]">
        <button
          onClick={access === true ? handleViewDetails : null}
          className="px-4 py-1 rounded-[8px] bg-white border-b-[0.3px] border-b-[#8E8E8E]"
        >
          View Details
        </button>
        {access !== undefined && access ? (
          <>
            {currentPropertyTypeTab !== "All Properties" &&
            (property?.post_approval === "Rejected" ||
              property?.post_approval === "Pending") ? (
              <div
                onClick={() => handleApproveOrReject("Approved")}
                className={`${
                  loader === "Approved" ? "py-3" : "py-1"
                } px-4 relative cursor-pointer rounded-[8px] border-b-[0.3px] border-b-[#8E8E8E] bg-[#CBE8A8] flex items-center justify-center`}
              >
                <button>{loader === "Approved" ? "" : "Approve"}</button>
                <div className="absolute inset-0 flex items-center justify-center">
                  {loader === "Approved" ? (
                    <CircularProgress size={13} />
                  ) : null}
                </div>
              </div>
            ) : null}

            {currentPropertyTypeTab !== "All Properties" &&
            (property?.post_approval === "Approved" ||
              property?.post_approval === "Pending") ? (
              <div
                onClick={() => handleApproveOrReject("Rejected")}
                className={`${
                  loader === "Rejected" ? "py-3" : "py-1"
                } px-4 relative cursor-pointer rounded-[8px] border-b-[0.3px] border-b-[#8E8E8E] bg-[#EEA5A5] flex items-center justify-center`}
              >
                <button>{loader === "Rejected" ? "" : "Reject"}</button>
                <div className="absolute inset-0 flex items-center justify-center">
                  {loader === "Rejected" ? (
                    <CircularProgress size={13} />
                  ) : null}
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default SinglePropertyCard;
