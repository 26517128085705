import { createContext, useEffect, useState } from "react";
import axiosInstance from "../Interceptor/axiosInstance";

export const UserContext = createContext(null);

const UserContextProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [customersList, setCustomersList] = useState();
  const [originalData, setOriginalData] = useState();
  const [dropDown, setDropDown] = useState(false);
  const [selectedDateForFilter, setSelectedDateForFilter] = useState("");

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("api/admin/getUserData");
      const data = response.data.data;
      setCustomersList(data);
      setOriginalData(data);
      
    } catch (error) {
      
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleDateChange = (e) => {
    const date = new Date(e);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    setSelectedDateForFilter(formattedDate);
  };
  const handleDateFilter = () => {
    // const filteredData = originalData.filter((data) =>
    //   data.Reported_Date.includes(selectedDateForFilter)
    // );
    // setCustomersList(filteredData);
    setDropDown(false);
  };

  const contextValue = {
    data,
    setData,
    customersList,
    setCustomersList,
    dropDown,
    setDropDown,
    handleDateChange,
    handleDateFilter,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserContextProvider;
