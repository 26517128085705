import UserContextProvider from "../../context/userContext";
import Centerbar from "./components/Centerbar";
import UserDetails from "./components/UserDetails";

const UserManagement = () => {
  return (
    <UserContextProvider>
      <div className="App h-screen flex bg-[#F5F4F8] font-Roboto">
        <Centerbar />
        <UserDetails />
      </div>
    </UserContextProvider>
  );
};

export default UserManagement;
