import HelpReqCount from "./HelpReqCount";
import ReviewStatus from "./ReviewStatus";
import Report from "./Report";
import Services from "./Services";
import Postproperty from "./Postproperty";
import AllUser from "./AllUser";
import SearchComponent from "../../icons/Search";
import axiosInstance from "../../Interceptor/axiosInstance";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";

const Dashboard = () => {
  const [totalEmployeesCount, setTotalEmployeesCount] = useState();
  const [activeEmployeesCount, setActiveEmployeesCount] = useState();
  const [inactiveEmployeesCount, setInactiveEmployeesCount] = useState();
  const [loading, setLoading] = useState(true);

  const fetchEmployeeCount = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/admin/employee/status/count"
      );
      setTotalEmployeesCount(response?.data?.TotalEmployee);
      setActiveEmployeesCount(response?.data?.Active);
      setInactiveEmployeesCount(response?.data?.Deactive);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee count:", error);
    }
  };

  useEffect(() => {
    fetchEmployeeCount();
  }, []);

  return (
    <>
      {/* <div>
        <input
          id="outlined-basic"
          type="text"
          placeholder="Search "
          className="font-Roboto w-5/12 h-10 ml-6 box-border font-roboto font-normal bg-white border border-gray-300 pl-10 pr-2 outline-none   "
          style={{
            backgroundColor: "#D9D9D9",
          }}
        />
        <div className="relative bottom-8 left-[650px]">
          <SearchComponent />
        </div>
      </div> */}
      <div className="p-6 grid grid-cols-3 gap-4 ">
        <div className=" uppercase text-[18px] bg-white shadow-md p-8 rounded-[15px] h-32">
          Total Employees
          <p className="text-3xl mt-2">{totalEmployeesCount}</p>
        </div>
        <div className=" uppercase text-[18px] bg-white shadow-md p-8 rounded-[15px] h-32">
          Active Employees
          <p className="text-3xl mt-2">{activeEmployeesCount}</p>
        </div>
        <div className=" uppercase text-[18px] bg-white shadow-md p-8 rounded-[15px] h-32">
          Inactive Employees
          <p className="text-3xl mt-2">{inactiveEmployeesCount}</p>
        </div>
        <Postproperty />
        <ReviewStatus />
        <HelpReqCount />
        <Report />
        <Services />
        {/* <div className="bg-[#EDDFE0] p-8 rounded-[15px] h-40 col-span-2 flex items-center gap-10">
          <h2 className="font-semibold uppercase text-xl">Post Approvals</h2>
          <div className="bg-[#705C53] w-[300px] text-white p-6 rounded-[15px] text-center">
            <h3 className="text-[18px] uppercase">Total Properties</h3>
            <p className="text-3xl">{postApprovalTotalCount}</p>
          </div>
          <div className="bg-[#705C53] w-[300px] text-white p-6 rounded-[15px] text-center">
            <h3 className="text-[18px] uppercase">Pending</h3>
            <p className="text-3xl">{postApprovalPendingCount}</p>
          </div>
        </div> */}
        <AllUser />
      </div>
    </>
  );
};

export default Dashboard;

// const [selectedDateForFilter, setSelectedDateForFilter] = useState(null);
// const handleDateChange = (e) => {
//   const date = new Date(e);
//   const day = String(date.getDate()).padStart(2, "0");
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const year = String(date.getFullYear()).slice(-2);
//   const formattedDate = `${day}-${month}-${year}`;
//   setSelectedDateForFilter(formattedDate);
// };

// const handleDateFilter = () => {
//   if (selectedDateForFilter) {
//     const filteredData = postApprovalData.filter(
//       (prop) =>
//         prop?.createdAt.includes(selectedDateForFilter) &&
//         prop?.post_approval === "Pending"
//     );
//     setPostApprovalPendingCount(filteredData.length);
//   }
// };
