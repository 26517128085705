import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import Sidebar from "./components/Sidebar/Sidebar";
import TableComponent from "./components/Table/TableComponent";
import RolesManagement from "./components/Roles/RolesManagement";
import EditRole from "./components/Roles/EditRole";
import Dashboard from "./components/Dash/Dashboard";
import CallCenter from "./components/Call/CallCenter";
import InAppCustomization from "./components/Inapp/InAppCustomization";
import AdPosting from "./components/Ad/AdPosting";
import "./index.css";
import ProtectedRoute from "./utils/ProtectedRoute";
import ReportedProps from "./components/ReportedProps/ReportedProps";
import "react-calendar/dist/Calendar.css";
import PropertyReviews from "./components/Review/PropertyReviews";
import UserAssistance from "./components/UserAssistance/UserAssistance";
import { AssRequestProvider } from "./context/AssRequestContext";
import PostApproval from "./components/PostApproval/PostApproval";
import PopularPlaces from "./components/PopularPlaces/PopularPlaces";
import UpcomingProjects from "./components/UpcoomingProjects/UpcomingProjects";
import PostProperty from "./components/PostProperty/PostProperty";
import UserManagement from "./components/UserManagement/UserManagement";
import JobPortal from "./components/JobPortal/JobPortal";
import ServiceListing from "./components/ServiceListing/ServiceListing";
import VattaraVerifiedProps from "./components/VattaraVerifiedProps/VattaraVerifiedProps";

const AppLayout = () => (
  <div style={{ display: "flex", padding: "0px", margin: "0px" }}>
    <Sidebar />
    <div style={{ flexGrow: 1, overflow: "hidden" }}>
      <div style={{ padding: "20px", backgroundColor: "#F5F4F8" }}>
        <Routes>
          <Route
            path="/usermanagement"
            element={<ProtectedRoute element={<TableComponent />} />}
          />
          <Route
            path="/rolesmanagement"
            element={<ProtectedRoute element={<RolesManagement />} />}
          />
          <Route
            path="/edit-role"
            element={<ProtectedRoute element={<EditRole />} />}
          />
          <Route
            path="/dashboard"
            element={<ProtectedRoute element={<Dashboard />} />}
          />
          <Route
            path="/callcenter"
            element={<ProtectedRoute element={<CallCenter />} />}
          />
          <Route
            path="/inapp"
            element={<ProtectedRoute element={<InAppCustomization />} />}
          />
          <Route
            path="/adposting"
            element={<ProtectedRoute element={<AdPosting />} />}
          />
          <Route
            path="/reportedproperties"
            element={<ProtectedRoute element={<ReportedProps />} />}
          />
          <Route
            path="/propertyreviews"
            element={<ProtectedRoute element={<PropertyReviews />} />}
          />
          <Route
            path="/userassistancerequest"
            element={<ProtectedRoute element={<UserAssistance />} />}
          />
          <Route
            path="/postapproval"
            element={<ProtectedRoute element={<PostApproval />} />}
          />
          <Route
            path="/popularplaces"
            element={<ProtectedRoute element={<PopularPlaces />} />}
          />
          <Route
            path="/upcomingprojects"
            element={<ProtectedRoute element={<UpcomingProjects />} />}
          />
          <Route
            path="/postproperty"
            element={<ProtectedRoute element={<PostProperty />} />}
          />
          <Route
            path="/customermanagement"
            element={<ProtectedRoute element={<UserManagement />} />}
          />
          <Route
            path="/jobportal"
            element={<ProtectedRoute element={<JobPortal />} />}
          />
          <Route
            path="/servicelistingapproval"
            element={<ProtectedRoute element={<ServiceListing />} />}
          />
          <Route
            path="/vattaraverifiedproperties"
            element={<ProtectedRoute element={<VattaraVerifiedProps />} />}
          />
        </Routes>
      </div>
    </div>
  </div>
);

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/*" element={<AppLayout />} />
      </Routes>
    </Router>
  );
};

export default App;
