

function Plus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#23A55A"
        d="M18 9a.75.75 0 01-.75.75h-7.5v7.5a.75.75 0 11-1.5 0v-7.5H.75a.75.75 0 010-1.5h7.5V.75a.75.75 0 011.5 0v7.5h7.5A.75.75 0 0118 9z"
      ></path>
    </svg>
  );
}

export default Plus;
