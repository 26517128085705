import { useContext } from "react";
import { UpcomingProjectContext } from "../../../context/UpcomingProjectContext";
import defaultImg from "../../../assets/dummy.webp";

const Preview = () => {
  const { projects, currentImgId } = useContext(UpcomingProjectContext);
  const currentProject =
    projects && projects.find((img) => img.id === currentImgId);
  const coverImg =
    currentProject && currentProject.images.find((img) => img.isCover === true);

  return (
    <div className="w-[70%] mx-auto bg-white pb-4 mt-[90px] mb-8 rounded-2xl">
      <div className="bg-[#000000] font-roboto font-bold text-sm text-white rounded-t-2xl px-3 py-2 text-center">
        Preview Screen
      </div>
      <div className="mx-4">
        <div className="flex flex-wrap justify-between gap-3 mb-4">
          <div className="bg-[#E7ECEF] rounded-[10px] px-11 py-7"></div>
          <div className="bg-[#E7ECEF] rounded-[10px] px-11 py-7"></div>
          <div className="bg-[#E7ECEF] rounded-[10px] px-11 py-7"></div>
          <div className="bg-[#E7ECEF] rounded-[10px] px-11 py-7"></div>
        </div>

        <div className="bg-[#E7ECEF] h-[130px] mb-4 rounded-[10px]"></div>
        <div className="h-[130px] mb-4">
          <img
            className="w-full h-full rounded-[10px] object-cover"
            src={coverImg?.uri ? coverImg.uri : defaultImg}
            alt="adCover"
          />
        </div>
      </div>
    </div>
  );
};

export default Preview;
