import { createContext, useEffect, useState } from "react";
import axiosInstance from "../Interceptor/axiosInstance";
import dummy from "../assets/dummy.webp";

const projectsList = [
  {
    id: 1,
    name: "Project 1",
    Title: "New Project 1",
    cardName: "Card 1",
    discription: "Project description",
    home_screen_display: true,
    images: [
      {
        id: 1,
        uri: null,
        isCover: true,
      },
      {
        id: 2,
        uri: null,
        isCover: false,
      },
      {
        id: 3,
        uri: null,
        isCover: false,
      },
      {
        id: 4,
        uri: null,
        isCover: false,
      },
      {
        id: 5,
        uri: null,
        isCover: false,
      },
      {
        id: 6,
        uri: null,
        isCover: false,
      },
    ],
  },

  {
    id: 2,
    name: "Project 2",
    Title: "New Project 2",
    cardName: "Card 2",
    discription: "Project description",
    home_screen_display: true,
    images: [
      {
        id: 1,
        uri: null,
        isCover: true,
      },
      {
        id: 2,
        uri: null,
        isCover: false,
      },
      {
        id: 3,
        uri: null,
        isCover: false,
      },
      {
        id: 4,
        uri: null,
        isCover: false,
      },
      {
        id: 5,
        uri: null,
        isCover: false,
      },
      {
        id: 6,
        uri: null,
        isCover: false,
      },
    ],
  },
  {
    id: 3,
    name: "Project 3",
    Title: "New Project 3",
    cardName: "Card 3",
    discription: "Project description",
    home_screen_display: true,
    images: [
      {
        id: 1,
        uri: null,
        isCover: true,
      },
      {
        id: 2,
        uri: null,
        isCover: false,
      },
      {
        id: 3,
        uri: null,
        isCover: false,
      },
      {
        id: 4,
        uri: null,
        isCover: false,
      },
      {
        id: 5,
        uri: null,
        isCover: false,
      },
      {
        id: 6,
        uri: null,
        isCover: false,
      },
    ],
  },
  {
    id: 4,
    name: "Project 4",
    Title: "New Project 4",
    cardName: "Card 4",
    discription: "Project description",
    home_screen_display: true,
    images: [
      {
        id: 1,
        uri: null,
        isCover: true,
      },
      {
        id: 2,
        uri: null,
        isCover: false,
      },
      {
        id: 3,
        uri: null,
        isCover: false,
      },
      {
        id: 4,
        uri: null,
        isCover: false,
      },
      {
        id: 5,
        uri: null,
        isCover: false,
      },
      {
        id: 6,
        uri: null,
        isCover: false,
      },
    ],
  },
  {
    id: 5,
    name: "Project 5",
    Title: "New Project 5",
    cardName: "Card 5",
    discription: "Project description",
    home_screen_display: true,
    images: [
      {
        id: 1,
        uri: null,
        isCover: true,
      },
      {
        id: 2,
        uri: null,
        isCover: false,
      },
      {
        id: 3,
        uri: null,
        isCover: false,
      },
      {
        id: 4,
        uri: null,
        isCover: false,
      },
      {
        id: 5,
        uri: null,
        isCover: false,
      },
      {
        id: 6,
        uri: null,
        isCover: false,
      },
    ],
  },
];

const UpcomingProjectContext = createContext();

const UpcomingProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState(projectsList);
  const [currentImgId, setCurrentImgId] = useState();
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [currentProjectImgId, setCurrentProjectImgId] = useState();
  const [uploadProjectImages, setUploadProjectImages] = useState([]);
  const [editOn, setEditOn] = useState();

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/vattara/app/homescreen/get/UpcomingProject"
      );

      const data = response?.data;

      if (data.Created) {
        setProjects((prevProjects) => {
          const updatedProjects = [...prevProjects];

          for (const newData of data.Created) {
            const index = updatedProjects.findIndex(
              (project) => project.cardName === newData.cardName
            );
            if (index !== -1) {
              updatedProjects[index] = {
                ...updatedProjects[index],
                ...newData,
              };
            }
          }

          data.Created
            ? setCurrentImgId(updatedProjects[0].id)
            : setCurrentImgId(projectsList[0].id);

          return updatedProjects;
        });
      }
      
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <UpcomingProjectContext.Provider
      value={{
        projects,
        setProjects,
        currentImgId,
        setCurrentImgId,
        currentProjectImgId,
        setCurrentProjectImgId,
        showSaveBtn,
        setShowSaveBtn,
        uploadProjectImages,
        setUploadProjectImages,
        editOn,
        setEditOn,
      }}
    >
      {children}
    </UpcomingProjectContext.Provider>
  );
};

export { UpcomingProjectContext, UpcomingProjectProvider };
