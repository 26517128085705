export const SET_SELECTED_OPTIONS = 'SET_SELECTED_OPTIONS';
export const SET_MERGED_VALUES = 'SET_MERGED_VALUES';
export const RESET_STATE = 'RESET_STATE';

export const setSelectedOptions = (options, transactionType, subType) => ({
  type: SET_SELECTED_OPTIONS,
  payload: {
    options,
    transactionType,
    subType,
  },
});

export const resetForm = () => ({
  type: RESET_STATE,
})

// imageActions.js
export const updatePickerResponse = (response) => ({
  type: 'UPDATE_PICKER_RESPONSE',
  payload: response,
});
