
const UploadComponent = (props) => (
  <svg
    width={34}
    height={34}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={19} cy={19} r={19} fill="#D9D9D9" />
    <path
      d="M17 22.9999H21C21.55 22.9999 22 22.5499 22 21.9999V16.9999H23.59C24.48 16.9999 24.93 15.9199 24.3 15.2899L19.71 10.6999C19.6175 10.6072 19.5076 10.5337 19.3866 10.4835C19.2657 10.4333 19.136 10.4075 19.005 10.4075C18.874 10.4075 18.7443 10.4333 18.6234 10.4835C18.5024 10.5337 18.3925 10.6072 18.3 10.6999L13.71 15.2899C13.08 15.9199 13.52 16.9999 14.41 16.9999H16V21.9999C16 22.5499 16.45 22.9999 17 22.9999ZM13 24.9999H25C25.55 24.9999 26 25.4499 26 25.9999C26 26.5499 25.55 26.9999 25 26.9999H13C12.45 26.9999 12 26.5499 12 25.9999C12 25.4499 12.45 24.9999 13 24.9999Z"
      fill="#8E8E8E"
    />
  </svg>
);
export default UploadComponent;
