import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const SuccessModal = ({ open, handleClose,massageValue }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="success-modal-title"
            aria-describedby="success-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "30%",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography id="success-modal-title" variant="h6" component="h2">
                    Success
                </Typography>
                <Typography id="success-modal-description" sx={{ mt: 2 }}>
                {massageValue}
                </Typography>
                <Button onClick={handleClose} sx={{ mt: 2 }}>
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default SuccessModal;
