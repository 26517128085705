import React, { useContext, useRef, useState } from "react";
import { UpcomingProjectContext } from "../../../context/UpcomingProjectContext";
import { MdOutlineFileUpload } from "react-icons/md";
import AWS from "aws-sdk";
import Resizer from "react-image-file-resizer";
import CircularProgress from "@mui/material/CircularProgress";
import { IoClose } from "react-icons/io5";
import dummy from "../../../assets/dummy.webp";

AWS.config.update({
  region: "us-east-1",
  accessKeyId: "AKIAXKPUZW5AH27B7EGM",
  secretAccessKey: "3SBeqsIC8clvDkZATY6N+DSgRtoQobd3JIPZuNW/",
});

const S3 = new AWS.S3();

const CurrentProjectImages = () => {
  const [uploading, setUploading] = useState(false);
  const {
    projects,
    currentImgId,
    setProjects,
    currentProjectImgId,
    setCurrentProjectImgId,
    setShowSaveBtn,
    editOn,
  } = useContext(UpcomingProjectContext);

  const currentProject =
    projects && projects.find((project) => project.id === currentImgId);

  const projectImages =
    currentProject &&
    currentProject?.images.filter((img) => img.isCover === false && img);

  const coverImage =
    currentProject && currentProject?.images.find((cover) => cover.isCover);

  const fileInputRef = useRef(null);

  const uploadFileToS3 = async (bucketName, file) => {
    const fileName = file.name
      ? `images/${Date.now()}_${file.name}`
      : `images/${Date.now()}_default.png`; // Unique file name

    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };

    try {
      const uploadResponse = await S3.upload(params).promise();
      return uploadResponse.Location; // URL of the uploaded file
    } catch (error) {
      throw new Error(`Error uploading file to S3: ${error.message}`);
    }
  };

  const resizeImage = (file, fileName) => {
    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        2000, // New width
        1200, // New height
        "JPEG", // Format
        90, // Quality
        0, // Rotation
        (uri) => {
          resolve({
            blob: uri,
            name: fileName,
          });
        },
        "blob"
      );
    });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    

    if (file) {
      try {
        const { blob: resizedImageBlob, name: fileName } = await resizeImage(
          file,
          file.name
        );

        const resizedFile = new File([resizedImageBlob], fileName, {
          type: resizedImageBlob.type,
        });

        setUploading(true);
        const fileUrl = await uploadFileToS3("vattara-img", resizedFile);

        const updatedProjects = projects.map((project) =>
          project.id === currentImgId
            ? {
                ...project,
                images: project.images.map((image) =>
                  image.id === currentProjectImgId
                    ? {
                        ...image,
                        uri: fileUrl,
                        filename: file.name,
                        name: file.name,
                      }
                    : image
                ),
              }
            : project
        );
        setProjects(updatedProjects);
        setShowSaveBtn(true);
        setUploading(false);
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        e.target.value = null;
      }
    }
  };

  const handleDeleteImg = (img_id) => {
    
    

    const updatedProjects = projects.map((project) =>
      project.id === currentImgId
        ? {
            ...project,
            images: project.images.map((img) =>
              img?.id === img_id
                ? {
                    ...img,
                    uri: null,
                    filename: null,
                    name: null,
                  }
                : img
            ),
          }
        : project
    );

    setProjects(updatedProjects);
    setShowSaveBtn(true);
  };

  const triggerFileInput = (id) => {
    setCurrentProjectImgId(id);
    fileInputRef.current
      ? fileInputRef.current.click()
      : console.error("File input reference is null.");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    const updatedProjects = projects.map((project) =>
      project.id === currentImgId
        ? {
            ...project,
            [name]: value || "",
          }
        : project
    );
    setProjects(updatedProjects);
    setShowSaveBtn(true);
  };

  return (
    <div className="flex flex-col gap-2">
      <h3 className="font-roboto font-medium text-[#000000] pb-2 -ml-5 text-sm">
        {currentProject && currentProject.cardName} - Edit
      </h3>
      <div className="flex flex-col gap-2 mb-2">
        <div className="flex items-center">
          <h4 className="font-roboto w-[32%] font-medium focus:outline-none text-[#000000] py-2 text-sm">
            Title
          </h4>
          {/* <p>{currentProject && currentProject.Title}</p> */}
          <input
            type="text"
            placeholder="Enter Title"
            name="Title"
            onChange={(e) => handleInputChange(e)}
            disabled={editOn !== currentImgId}
            className="w-2/3 border-[1px] border-[#D7D7D7] focus:outline-none text-[#2B2B2B] text-[13px] rounded-md shadow-sm px-2 py-1"
            value={(currentProject && currentProject.Title) || ""}
          />
        </div>
        <div className="flex items-center">
          <h4 className="font-roboto w-[32%] font-medium text-[#000000] py-2 text-sm">
            Description
          </h4>
          <input
            type="text"
            placeholder="Enter Description"
            name="discription"
            onChange={(e) => handleInputChange(e)}
            disabled={editOn !== currentImgId}
            className="w-2/3 border-[1px] border-[#D7D7D7] focus:outline-none text-[#2B2B2B] text-[13px] rounded-md shadow-sm px-2 py-1"
            value={(currentProject && currentProject.discription) || ""}
          />
        </div>
      </div>
      <div className="flex items-start gap-10">
        <h4 className="font-roboto font-medium text-[#000000] py-2 text-sm">
          Cover Image
        </h4>
        <div className="relative flex items-center justify-center">
          <img
            className="w-[110px] h-[70px] object-cover rounded-[10px]"
            src={coverImage?.uri ? coverImage.uri : dummy}
            alt="projectCoverImg"
          />
          <div
            onClick={() =>
              editOn === currentImgId ? triggerFileInput(coverImage.id) : null
            }
            className="cursor-pointer bg-[#E4E0EE] p-[6px] rounded-full opacity-50 flex items-center justify-center absolute"
          >
            <MdOutlineFileUpload className="text-lg text-[#8E8E8E]" />
          </div>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {currentProjectImgId &&
            currentProjectImgId === coverImage.id &&
            uploading && (
              <div className="absolute inset-0 rounded-[10px] flex items-center justify-center w-full h-full bg-black opacity-50">
                {<CircularProgress />}
              </div>
            )}
        </div>
      </div>

      <h3 className="font-roboto text-[#191D23] text-xs py-5 text-center">
        Browse and choose the files you want to upload from your computer
      </h3>
      <div className="flex items-start gap-20">
        <h4 className="font-roboto font-medium text-[#000000] py-2 text-sm">
          Cards
        </h4>
        <div className="flex flex-wrap gap-5">
          {projectImages &&
            projectImages.map((img, i) => (
              <div
                className="relative flex items-center justify-center"
                key={i}
              >
                {/* {img?.uri ? ( */}
                <img
                  className="w-[110px] h-[70px] object-cover rounded-[10px]"
                  src={img?.uri ? img.uri : dummy}
                  alt="projectImg"
                />
                {/* ) : (
                  <div className="bg-zinc-200 w-[110px] h-[70px] rounded-[10px]"></div>
                )} */}

                <div
                  onClick={
                    editOn === currentImgId
                      ? () => handleDeleteImg(img?.id)
                      : null
                  }
                  className="text-red-600 cursor-pointer bg-white p-[1px] bg-opacity-70 rounded-full absolute -right-1 -top-1"
                >
                  <IoClose />
                </div>
                <div
                  onClick={() =>
                    editOn === currentImgId ? triggerFileInput(img.id) : null
                  }
                  className="cursor-pointer bg-[#E4E0EE] p-[6px] rounded-full opacity-50 flex items-center justify-center absolute"
                >
                  <MdOutlineFileUpload className="text-lg text-[#8E8E8E]" />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                {currentProjectImgId === img.id && uploading && (
                  <div className="absolute inset-0 rounded-[10px] flex items-center justify-center w-full h-full bg-black opacity-50">
                    {<CircularProgress />}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
      {/* <Button
        disabled={!showSaveBtn}
        // onClick={handleUploadImages}
        variant="contained"
        size="small"
        sx={{
          bgcolor: "#D9D9D9",
          color: "#80848E",
          marginTop: "10px",
          width: "fit-content",
          ":hover": { bgcolor: "#D9D9D9" },
        }}
      >
        Discard
      </Button> */}
    </div>
  );
};

export default CurrentProjectImages;
