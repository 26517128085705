import React, { useContext, useEffect, useState } from "react";
import { AssRequestContext } from "../../../context/AssRequestContext";
import copy from "../../../assets/Copy.png";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectEmpId, selectUserPermissions } from "../../../store/authSlice";
import axiosInstance from "../../../Interceptor/axiosInstance";
import { render } from "@testing-library/react";
import SuccessModal from "../../SuccessModal";

const ReportDetails = () => {
  const {
    showDetails,
    setShowDetails,
    selectedRequest,
    fetchData,
    setSelectedRequest,
    redener,
    setRedener
  } = useContext(AssRequestContext);
  const [access, setAccess] = useState();
  const expectedPermission = "Customer Support";
  const userRolePermission = useSelector(selectUserPermissions);
  const [message, setmessage] = useState(false)
  const empId = useSelector(selectEmpId);
  const [open, setOpen] = useState(false);
  const massageValue = "User Assistance Status completed successfully"


  const handleclose = () => {


    setOpen(false);
  }


  useEffect(() => {
    const hasAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.edit === "true"
    );

    setAccess(hasAccess);
  }, [userRolePermission]);

  const bodyToSend = {
    emp_id: empId,
    id: selectedRequest?.id,
    status: "Completed",
  };

  const updateHelpStatus = async () => {
    try {
      const response = await axiosInstance.put(
        "/api/admin/update/need/helpStatus",
        bodyToSend
      );
      
      fetchData();
      // setRedener(!render)
      setOpen(true)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

     
     
      
      setSelectedRequest();
    } catch (error) {
      console.error("Error updated help status:", error);
    }
  };

  return (
    <div
      className={`${showDetails ? "opacity-100" : "opacity-0"
        } transition-opacity flex flex-col gap-2`}
    >
      <div className="text-[#202020]  bg-[#E4E0EE]  rounded-[8px]">
        <h3 className="text-sm mx-5 px-2 py-4 border-b-[0.5px] font-bold border-b-[#8E8E8E]">
          User Details
        </h3>
        <div className="flex mx-5 px-2 py-4 justify-between">
          <div className="">
            <h5 className="text-sm font-bold">
              {selectedRequest?.tbl_user_reg?.name}
            </h5>
            <p className="text-xs font-normal mt-1">
              {selectedRequest?.tbl_user_reg?.category}
            </p>
          </div>
          <div className="bg-[#8E8E8E] w-[0.3px] h-[45px]"></div>

          <div className="text-sm flex flex-col gap-1">
            <h5>{selectedRequest?.tbl_user_reg?.email}</h5>
            <h5 className="flex items-center gap-1 font-bold">
              {selectedRequest?.tbl_user_reg?.mobile_no}{" "}
              <img className="w-4 h-4" src={copy} alt="" />
            </h5>
          </div>
        </div>
        <p className="text-[13px] mx-5 px-2 pb-4">
          Company Name :{" "}
          <span>{selectedRequest?.tbl_user_reg?.company_name}</span>
        </p>
        <div className="mx-5 py-4 border-t-[0.5px] border-t-[#8E8E8E] flex justify-end">
          {access !== undefined && access ? (
            <Button
              size="small"
              sx={{
                borderRadius: "8px",
                backgroundColor: "#23A55A",
                ":hover": {
                  backgroundColor: "#23A55A",
                },
                color: "#202020",
              }}
              variant="contained"
              disabled={selectedRequest?.status === "Completed"}
              onClick={() => {
                updateHelpStatus();
                setShowDetails();
              }}
            // className="bg-[#23A55A] text-xs font-medium px-4 py-2 rounded-[8px]"
            >
              Mark as Completed
            </Button>
          ) : null}

          <div>
            <SuccessModal open={open} handleClose={handleclose} massageValue={massageValue} />
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default ReportDetails;
