
const LogoComponent = (props) => (
  <svg
    width={25}
    height={31}
    viewBox="0 0 25 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.6965 13.0756V17.1582L11.9731 30.1128L9.25012 27.3405V23.281L21.984 10.314L24.6965 13.0756Z"
      fill="#FBB040"
    />
    <path
      d="M15.4372 7.73238L7.28747 16.0626L7.30585 28.161L0.967346 21.7078V12.86L12.7247 0.887207L15.4372 3.64879V7.73238Z"
      fill="#2E3192"
    />
  </svg>
);
export default LogoComponent;
