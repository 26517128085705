import { Button } from "@mui/material";
import { useContext } from "react";
import { PopularPlacesContext } from "../../../context/PopularPlacesContext";
import axiosInstance from "../../../Interceptor/axiosInstance";

const ImageUpdateConfirmationModal = ({
  updatedImage,
  imageIndex,
  setImages,
  setShowModal,
  setShowConfirmationModal,
  images,
}) => {
  const { updatedDataToBackEnd, setUpdatedDataToBackEnd } =
    useContext(PopularPlacesContext);

  const handleUpdateImage = async () => {
    setImages(updatedImage);
    setShowModal(false);
    setShowConfirmationModal(false);

    const dataToSend = {
      id: images[imageIndex].id || null,
      new_district: updatedImage[imageIndex].popular_districts,
      imageUrl: {
        url: updatedImage[imageIndex].image.url,
        imageName: updatedImage[imageIndex].image.imagename,
      },
    };

    setUpdatedDataToBackEnd(dataToSend);
    

    try {
      const response = await axiosInstance.put(
        "/api/admin/district/update",
        dataToSend
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      
    } catch (error) {
      console.error("Error updating image:", error);
    }
  };

  const handleCancelUpdate = () => {
    setShowModal(false);
    setShowConfirmationModal(false);
  };

  return (
    <div className="bg-[#FFFFFF] absolute text-nowrap top-40 left-40 font-roboto font-medium text-base rounded-[20px] p-6 shadow-lg z-20">
      <div>{`Do you want to change the city from ${images[imageIndex].popular_districts} to ${updatedImage[imageIndex].popular_districts} ?`}</div>
      <div className="flex justify-center gap-3 pt-4">
        <Button
          onClick={handleCancelUpdate}
          variant="contained"
          sx={{
            bgcolor: "#D9D9D9",
            color: "#80848E",
            ":hover": { bgcolor: "#D9D9D9" },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpdateImage}
          variant="contained"
          sx={{ bgcolor: "#7DAE3F", ":hover": { bgcolor: "#7DAE3F" } }}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};

export default ImageUpdateConfirmationModal;
