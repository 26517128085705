import { combineReducers } from 'redux';
import { SET_SELECTED_OPTIONS, SET_MERGED_VALUES, RESET_STATE } from '../redux/actions';

// Initial state for the form slice of the Redux store
const initialState = {
  selectedOptions: {}, // Object to store selected options
  transactionType: null, // Initial value for transaction type
  subType: null, // Initial value for sub type
  mergedValues: {}, // Initial empty object for merged values
  pickerResponse: [],
};

// Reducer function for handling form-related actions
const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_OPTIONS:
      return {
        ...state,
        selectedOptions: action.payload.options, // Update selected options
        transactionType: action.payload.transactionType, // Update transaction type
        subType: action.payload.subType, // Update sub type
      };
    case SET_MERGED_VALUES:
      return {
        ...state,
        mergedValues: action.payload, // Update merged values
      };
    case RESET_STATE :
      return initialState; // Reset
    default:
      return state; // Return current state if action type doesn't match
  }
};

const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_PICKER_RESPONSE':
      return {
        ...state,
        pickerResponse: action.payload,
      };
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};

// Combine formReducer with other reducers if necessary
export default combineReducers({
  form: formReducer, // Assign formReducer to 'form' slice of the Redux store
  imageReducer,
});
