import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../store/authSlice";

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const location = useLocation();

  // Array of paths to restrict access to for unauthenticated users
  const restrictedPaths = [
    "/usermanagement",
    "/rolesmanagement",
    "/edit-role",
    "/dashboard",
    "/callcenter",
    "/inapp",
    "/adposting",
    "/reportedproperties",
    "/propertyreviews",
    "/userassistancerequest",
    "/postapproval",
    "/popularplaces",
    "/upcomingprojects",
    "/postproperty",
    "/customermanagement",
    "/jobportal",
    "/servicelistingapproval",
    "/vattaraverifiedproperties",
  ];

  

  // Check if the user is not authenticated and trying to access any protected route
  if (
    !isAuthenticated &&
    restrictedPaths.includes(location.pathname.toLowerCase())
  ) {
    
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return element;
};

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
};

export default ProtectedRoute;
