import React from "react";

const Shimmer = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex items-center gap-20 h-[110px] bg-white rounded-[8px] shadow-md px-4 py-2">
        <div className="flex gap-4 items-center">
          <div className="bg-zinc-200 h-[70px] w-[100px]"></div>
          <div className="flex flex-col items-start gap-2">
            <div className="bg-zinc-200 h-[5px] w-[77px]"></div>
            <div className="bg-zinc-200 h-[5px] w-[65px]"></div>
            <div className="bg-zinc-200 h-[5px] w-[56px]"></div>
          </div>
        </div>
        <div className="w-[0.3px] bg-[#8E8E8E] h-[65%]"></div>
        <div className="flex flex-col items-start gap-2">
          <div className="bg-zinc-200 h-[5px] w-[77px]"></div>
          <div className="bg-zinc-200 h-[5px] w-[65px]"></div>
          <div className="bg-zinc-200 h-[5px] w-[56px]"></div>
        </div>
        <div className="w-[0.3px] bg-[#8E8E8E] h-[65%]"></div>
        <div className="flex flex-col items-start gap-3">
          <div className="bg-zinc-200 h-[13px] w-[77px] rounded-sm"></div>
          <div className="bg-zinc-200 h-[13px] w-[77px] rounded-sm"></div>
        </div>
      </div>
      <div className="w-full flex items-center gap-20 h-[110px] bg-white rounded-[8px] shadow-md px-4 py-2">
        <div className="flex gap-4 items-center">
          <div className="bg-zinc-200 h-[70px] w-[100px]"></div>
          <div className="flex flex-col items-start gap-2">
            <div className="bg-zinc-200 h-[5px] w-[77px]"></div>
            <div className="bg-zinc-200 h-[5px] w-[65px]"></div>
            <div className="bg-zinc-200 h-[5px] w-[56px]"></div>
          </div>
        </div>
        <div className="w-[0.3px] bg-[#8E8E8E] h-[65%]"></div>
        <div className="flex flex-col items-start gap-2">
          <div className="bg-zinc-200 h-[5px] w-[77px]"></div>
          <div className="bg-zinc-200 h-[5px] w-[65px]"></div>
          <div className="bg-zinc-200 h-[5px] w-[56px]"></div>
        </div>
        <div className="w-[0.3px] bg-[#8E8E8E] h-[65%]"></div>
        <div className="flex flex-col items-start gap-3">
          <div className="bg-zinc-200 h-[13px] w-[77px] rounded-sm"></div>
          <div className="bg-zinc-200 h-[13px] w-[77px] rounded-sm"></div>
        </div>
      </div>
      <div className="w-full flex items-center gap-20 h-[110px] bg-white rounded-[8px] shadow-md px-4 py-2">
        <div className="flex gap-4 items-center">
          <div className="bg-zinc-200 h-[70px] w-[100px]"></div>
          <div className="flex flex-col items-start gap-2">
            <div className="bg-zinc-200 h-[5px] w-[77px]"></div>
            <div className="bg-zinc-200 h-[5px] w-[65px]"></div>
            <div className="bg-zinc-200 h-[5px] w-[56px]"></div>
          </div>
        </div>
        <div className="w-[0.3px] bg-[#8E8E8E] h-[65%]"></div>
        <div className="flex flex-col items-start gap-2">
          <div className="bg-zinc-200 h-[5px] w-[77px]"></div>
          <div className="bg-zinc-200 h-[5px] w-[65px]"></div>
          <div className="bg-zinc-200 h-[5px] w-[56px]"></div>
        </div>
        <div className="w-[0.3px] bg-[#8E8E8E] h-[65%]"></div>
        <div className="flex flex-col items-start gap-3">
          <div className="bg-zinc-200 h-[13px] w-[77px] rounded-sm"></div>
          <div className="bg-zinc-200 h-[13px] w-[77px] rounded-sm"></div>
        </div>
      </div>
    </div>
  );
};

export default Shimmer;
