// CustomAlertModal.js
function CustomAlertModal1({ show, onClose }) {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Default Role</h2>
        <p className="mb-4">
          You can't delete this role because this is default role.
        </p>
        <button
          onClick={onClose}
          className="p-2 bg-black text-white rounded-lg"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default CustomAlertModal1;
