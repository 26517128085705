import React, { useContext } from "react";
import PropertiesList from "./PropertiesList";
import ReportDetails from "./ReportDetails";
import Filters from "./Filters";
import { ReportedPropsContext } from "../../../context/ReportedPropsContext";

const ReportedPropsDashboard = () => {
  const { reportsList } = useContext(ReportedPropsContext);
  return (
    <div>
      <h2 className="font-bold text-xl py-4 border-b-[1px] border-[#404040]">
        Reported Properties Dashboard
      </h2>
      <Filters />
      <div className="bg-[#F5F4F8]">
        <PropertiesList />
      </div>
    </div>
  );
};

export default ReportedPropsDashboard;
