import React, { useEffect, useState } from 'react'
import axiosInstance from '../../Interceptor/axiosInstance';
import { Skeleton } from '@mui/material';

const Postproperty = () => {

    const [review, setReview] = useState("");
    const [loading, setLoading] = useState(true); // New loading state
  
    useEffect(() => {
      async function review() {
        try {
          const response = await axiosInstance.get('api/admin/postproperty/status/count');
          
          setReview(response.data);
        } catch (e) {
          
        } finally {
          setLoading(false); // Set loading to false after the request is done
        }
      }
      review();
    }, []);

 
    return (
      // <div className="bg-gray-200 p-8 rounded h-64 ">
      //   {loading ? ( // Conditional rendering based on loading state
      //     <Skeleton className='h-64' />
      //   ) : (
      //     <div>
      //       <div>
      //         <p className='font-semibold text-lg'>Post Property</p>
      //       </div>
      //       <div className='flex flex-col gap-2'>
      //         <div className='flex mt-5'>
      //           <p className='w-[50%]'>Verified:</p>

      //           <p  className='w-[50%]'> {review.Approved }</p>
      //         </div>
      //         <div className='flex '>
      //           <p className='w-[50%]'>Pending:</p>

      //           <p  className='w-[50%]'> {review.Pending}</p>
      //         </div>
      //         <div className='flex '>
      //           <p className='w-[50%]'>Rejected:</p>

      //           <p  className='w-[50%]'> {review.Rejected
      //           }</p>
      //         </div>

      //       </div>
      //     </div>
      //   )}
      // </div>
      <div className="bg-white shadow-md p-8 rounded-[15px] h-56 col-span-3 ">
        {loading ? ( // Conditional rendering based on loading state
          <Skeleton className="h-64" />
        ) : (
          <div className=" flex flex-col items-center gap-6">
            <h2 className="font-semibold uppercase text-xl">Post Property</h2>
            <div className="flex gap-10 justify-between">
              <div className="bg-gray-200 w-[240px]  p-6 rounded-[15px] text-center">
                <h3 className="text-[18px] uppercase">Verified</h3>
                <p className="text-3xl">{review.Approved}</p>
              </div>
              <div className="bg-gray-200 w-[240px]  p-6 rounded-[15px] text-center">
                <h3 className="text-[18px] uppercase">Pending</h3>
                <p className="text-3xl">{review.Pending}</p>
              </div>
              <div className="bg-gray-200 w-[240px]  p-6 rounded-[15px] text-center">
                <h3 className="text-[18px] uppercase">Rejected</h3>
                <p className="text-3xl">{review.Rejected}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  
}

export default Postproperty