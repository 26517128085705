import { createContext, useEffect, useState } from "react";
import axiosInstance from "../Interceptor/axiosInstance";

const VattaraPropsContext = createContext();

const VattaraPropsProvider = ({ children }) => {
  const [properties, setProperties] = useState([]);
  const [originalList, setOriginalList] = useState();
  const [showDropDown, setShowDropDown] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedDateForFilter, setSelectedDateForFilter] = useState(null);
  const [setAsMenu, setSetAsMenu] = useState();
  const [viewTab, setViewTab] = useState("Verified Properties");
  const [selectedProperty, setSelectedProperty] = useState();
  const [propCount, setPropCount] = useState(0);
  const [shimmerState, setShimmerState] = useState(false);

  const handleSearchInput = (e) => {
    setSearchText(e.target.value);
  };
  const handleSearch = () => {
    const filteredList = originalList.filter(
      (item) =>
        item?.propertyID
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item?.property_type.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.district.toLowerCase().includes(searchText.toLowerCase())
    );
    setProperties(filteredList);
    setPropCount(filteredList.length||0);
  };
  const handleDateChange = (e) => {
    const date = new Date(e);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    setSelectedDateForFilter(formattedDate);
  };
  const handleDateFilter = () => {
    const filteredData = originalList.filter((data) =>
      data.createdAt.includes(selectedDateForFilter)
    );
    setProperties(filteredData);
    setPropCount(filteredData.length ||0);
    setShowDropDown("");
  };

  const fetchData = async () => {
    setShimmerState(true);
    const response = await axiosInstance.get("/api/admin/vattaraProperty");
    const data = response?.data;
    const results = data?.flattenedResults || []; // Default to an empty array if undefined
    setProperties(results);
    setOriginalList(results);
    setPropCount(results.length)
    setShimmerState(false);
  };

  const handleReset = () => {
    fetchData();
    setSelectedDateForFilter(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <VattaraPropsContext.Provider
      value={{
        properties,
        setProperties,
        originalList,
        setOriginalList,
        showDropDown,
        setShowDropDown,
        searchText,
        setSearchText,
        selectedDateForFilter,
        setSelectedDateForFilter,
        handleSearchInput,
        handleSearch,
        handleDateChange,
        handleDateFilter,
        fetchData,
        setAsMenu,
        setSetAsMenu,
        viewTab,
        setViewTab,
        selectedProperty,
        setSelectedProperty,
        propCount,
        handleReset,
        shimmerState,
      }}
    >
      {children}
    </VattaraPropsContext.Provider>
  );
};

export { VattaraPropsContext, VattaraPropsProvider };
