import { useContext } from "react";
import { PopularPlacesContext } from "../../../context/PopularPlacesContext";

const Preview = () => {
  const { images } = useContext(PopularPlacesContext);
  return (
    <div className="w-[70%] mx-auto bg-white pb-4 mt-[120px] rounded-2xl">
      <div className="bg-[#000000] font-roboto font-bold text-sm text-white rounded-t-2xl mb-4 px-3 py-2 text-center">
        Preview Screen
      </div>
      <div className="flex justify-center gap-2 items-center">
        <div className="bg-[#E7ECEF] px-10 py-4 rounded-full"></div>
        <div className="bg-[#E7ECEF] px-10 py-4 rounded-full"></div>
        <div className="bg-[#E7ECEF] px-10 py-4 rounded-full"></div>
      </div>
      <div className="mt-3 flex justify-center items-center">
        <div className="bg-[#F3F3F3] px-[85px] py-4 rounded-l-full"></div>
        <div className="bg-[#E7ECEF] px-10 py-4 rounded-r-full"></div>
      </div>
      <div className="font-k2d text-[#252B5C] p-4">
        <h4 className="font-semibold text-sm">Popular Places</h4>
        <p className="font-semibold text-[10px] tracking-tighter pt-2">
          Explore Residential Properties for Sale in Tamil Nadu
        </p>
      </div>
      <div className="flex flex-wrap items-center px-4 justify-center gap-5">
        {images &&
          images.map((image, i) => (
            <div className="relative" key={i}>
              <img
                src={image.image.url}
                className="w-[90px] h-[65px] object-cover rounded-[10px]"
                alt="Dummy Img"
              />
              <div className="font-roboto font-semibold text-[#ffffff] tracking-wide text-[13px] absolute inset-0 flex items-end justify-center mb-3">
                {image.popular_districts}
              </div>
            </div>
          ))}
      </div>
      <div className="bg-[#E7ECEF] h-[130px] mx-4 mt-4 rounded-[10px]"></div>
    </div>
  );
};

export default Preview;
