import React, { useEffect, useState } from "react";
import axiosInstance from "../../Interceptor/axiosInstance";
import { Skeleton } from "@mui/material";

const Services = () => {
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    async function review() {
      try {
        const response = await axiosInstance.get(
          "/api/admin/services/status/count"
        );

        setReview(response.data);
      } catch (e) {
      } finally {
        setLoading(false); // Set loading to false after the request is done
      }
    }
    review();
  }, []);

  return (
    <div className="bg-white shadow-md p-8 rounded-[15px] h-56 col-span-3 ">
      {loading ? ( // Conditional rendering based on loading state
        <Skeleton className="h-64" />
      ) : (
        <div className=" flex flex-col items-center gap-6">
          <h2 className="font-semibold uppercase text-xl">Services count</h2>
          <div className="flex gap-10 justify-between">
            <div className="bg-gray-200 w-[240px] p-6 rounded-[15px] text-center">
              <h3 className="text-[18px] uppercase">Verified</h3>
              <p className="text-3xl">{review.Verified}</p>
            </div>
            <div className="bg-gray-200 w-[240px]  p-6 rounded-[15px] text-center">
              <h3 className="text-[18px] uppercase">Pending</h3>
              <p className="text-3xl">{review.Pending}</p>
            </div>
            <div className="bg-gray-200 w-[240px]  p-6 rounded-[15px] text-center">
              <h3 className="text-[18px] uppercase">Rejected</h3>
              <p className="text-3xl">{review.Rejected}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
