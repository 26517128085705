const ReloadComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 13.3333C11.3925 13.3333 12.5 12.2241 12.5 10.8333C12.5 9.44242 11.3925 8.33325 10 8.33325C8.6075 8.33325 7.5 9.44242 7.5 10.8333C7.5 12.2241 8.6075 13.3333 10 13.3333Z"
      fill="#006CE7"
    />
    <path
      d="M17.3475 9.32175C17.1511 8.36204 16.7674 7.45048 16.2183 6.63925C15.6788 5.84115 14.9914 5.15377 14.1933 4.61425C13.382 4.06535 12.4705 3.68166 11.5108 3.48508C11.0067 3.38286 10.4935 3.33259 9.97917 3.33508V1.66675L6.66667 4.16675L9.97917 6.66675V5.00175C10.3825 5.00008 10.7858 5.03841 11.175 5.11841C11.9209 5.27125 12.6293 5.56942 13.26 5.99591C13.882 6.41528 14.4173 6.9506 14.8367 7.57258C15.4878 8.53527 15.835 9.67121 15.8333 10.8334C15.8332 11.6133 15.6774 12.3853 15.375 13.1042C15.2279 13.4505 15.0482 13.782 14.8383 14.0942C14.6276 14.4046 14.3882 14.6945 14.1233 14.9601C13.3167 15.7652 12.2926 16.3175 11.1767 16.5492C10.4006 16.7059 9.60106 16.7059 8.825 16.5492C8.0788 16.3963 7.37003 16.0978 6.73917 15.6709C6.1179 15.2519 5.58316 14.7172 5.16417 14.0959C4.5138 13.1322 4.16642 11.996 4.16667 10.8334H2.5C2.50089 12.3282 2.94741 13.7887 3.7825 15.0284C4.32237 15.8252 5.00905 16.5119 5.80583 17.0517C7.04385 17.8896 8.50509 18.3362 10 18.3334C10.5077 18.3334 11.0142 18.2823 11.5117 18.1809C12.4706 17.9829 13.3816 17.5993 14.1933 17.0517C14.5919 16.7832 14.9634 16.4765 15.3025 16.1359C15.6421 15.7954 15.949 15.4238 16.2192 15.0259C17.0564 13.7882 17.5026 12.3277 17.5 10.8334C17.5 10.3257 17.4489 9.81925 17.3475 9.32175Z"
      fill="#006CE7"
    />
  </svg>
);
export default ReloadComponent;
