import React from "react";
import { UpcomingProjectProvider } from "../../context/UpcomingProjectContext";
import UpComingProjects from "./components/UpComingProjects";
import Preview from "./components/Preview";
import PreviewForSingleProject from "./components/PreviewForSingleProject";

const UpcomingProjects = () => {
  return (
    <UpcomingProjectProvider>
      <div className="flex">
        <div className="w-[70%] bg-[#F5F4F8] border-r-[1px] border-r-[#404040] py-4">
          <UpComingProjects />
        </div>
        <div className="w-[30%] bg-[#F5F4F8] relative">
          <Preview />
          <PreviewForSingleProject />
        </div>
      </div>
    </UpcomingProjectProvider>
  );
};

export default UpcomingProjects;
