import { useContext, useEffect, useState } from "react";
import { UpcomingProjectContext } from "../../../context/UpcomingProjectContext";
import CurrentProjectImages from "./CurrentProjectImages";
import axiosInstance from "../../../Interceptor/axiosInstance";
import Switch from "@mui/material/Switch";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUserPermissions } from "../../../store/authSlice";
import defaultImage from "../../../assets/dummy.webp";

const UpComingProjects = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [access, setAccess] = useState();
  const expectedPermission = "Upcoming Projects Ad";
  const userRolePermission = useSelector(selectUserPermissions);

  useEffect(() => {
    const hasAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.edit === "true"
    );

    setAccess(hasAccess);
  }, [userRolePermission]);

  const {
    projects,
    setProjects,
    currentImgId,
    setCurrentImgId,
    editOn,
    setEditOn,
    showSaveBtn,
    setShowSaveBtn,
  } = useContext(UpcomingProjectContext);

  const currProject =
    projects && projects.find((project) => project.id === currentImgId);

  // const fileInputRef = useRef(null);

  const getCoverImageUrl = (images) => {
    const coverImage = images.find((img) => img.isCover === true);
    return coverImage ? coverImage.uri : null;
  };

  const handleSwitchChange = async (project) => {
    const updatedProjects = projects.map((project) =>
      project.id === currentImgId
        ? {
            ...project,
            home_screen_display: !project.home_screen_display,
          }
        : project
    );
    setProjects(updatedProjects);
    setShowConfirmationModal(false);

    const currentlyUpdatedProject = updatedProjects.find(
      (project) => project.id === currentImgId
    );

    const dataToSend = {
      home_screen_display: currentlyUpdatedProject.home_screen_display,
      cardName: currentlyUpdatedProject.cardName,
    };

    try {
      const response = await axiosInstance.put(
        `/api/admin/homescreen/upcomingProject/deactivate`,
        dataToSend
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      
    } catch (error) {
      console.error(error);
    }
  };

  const handleUploadImages = async () => {
    setShowSaveBtn(false);
    try {
      const response = await axiosInstance.post(
        "/api/admin/upcoming/projectUpdate",
        currProject
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error updating images:" + error);
    }
  };

  return (
    <div className="w-full">
      <h3 className="font-roboto font-bold text-[20px]">
        Manage Upcoming Projects
      </h3>
      <div className="flex justify-between items-center">
        <p className="font-inter font-bold text-[#7B7D8C] text-xs py-3">
          Home -{`>`} In-App Customization -{`>`} Manage Upcoming Projects
        </p>
        <div className="flex items-center gap-2 mr-6 relative">
          {access !== undefined && access ? (
            <Button
              disabled={
                editOn === currentImgId || !currProject.home_screen_display
              }
              onClick={
                access !== undefined && access
                  ? () => setEditOn(currentImgId)
                  : null
              }
              size="small"
              variant="contained"
              sx={{
                bgcolor: "#7DAE3F",
                ":hover": { bgcolor: "#7DAE3F" },
              }}
            >
              Edit
            </Button>
          ) : null}
          <span
            className={`transition-opacity duration-300 ${
              currProject && !currProject.home_screen_display
                ? "opacity-100"
                : "opacity-0"
            } text-red-500 text-xs absolute font-medium whitespace-nowrap tracking-tight -bottom-6 -left-3`}
          >
            Activate card to enable editing
          </span>
          {/* <span
            className={`transition-opacity duration-300 ${
              accessWarning
                ? "opacity-100"
                : "opacity-0"
            } text-red-500 text-xs absolute font-bold whitespace-nowrap tracking-tight -bottom-6 -left-6`}
          >
            You do not have access to edit cards
          </span> */}
          <Button
            onClick={handleUploadImages}
            disabled={!showSaveBtn}
            size="small"
            variant="contained"
            sx={{
              bgcolor: "#7DAE3F",
              ":hover": { bgcolor: "#7DAE3F" },
            }}
          >
            Save
          </Button>
        </div>
      </div>

      <div className="my-6 flex items-stretch gap-12">
        <div className="w-[40%]">
          <h4 className="font-k2d text-[#252B5C] py-5 text-center font-semibold text-sm">
            Select a card you want to edit
          </h4>
          <div className="flex flex-col items-center gap-4 justify-center">
            {projects.map((project, i) => (
              <div key={i} className="relative w-fit">
                <img
                  onClick={() => {
                    setCurrentImgId(project.id);
                    setShowConfirmationModal(false);
                    setEditOn();
                  }}
                  className={`${
                    currentImgId === project.id ? "opacity-100" : "opacity-40"
                  } w-[240px] h-[130px] object-cover rounded-[10px] mt-2 mb-6`}
                  src={
                    getCoverImageUrl(project.images)
                      ? getCoverImageUrl(project.images)
                      : defaultImage
                  }
                  alt="coverImg"
                />

                {currentImgId === project.id && (
                  <div>
                    <div className="flex items-center gap-2">
                      {access !== undefined && access ? (
                        <>
                          <Switch
                            color="success"
                            checked={project.home_screen_display}
                            onChange={() => setShowConfirmationModal(true)}
                            size="small"
                            sx={{}}
                          />
                          <span
                            className={`text-[#7B7D8C] font-roboto font-bold`}
                          >
                            {project.home_screen_display
                              ? "Deactivate"
                              : "Activate"}
                          </span>
                        </>
                      ) : null}

                      {showConfirmationModal ? (
                        <div className="z-10 absolute top-20 left-36 font-roboto font-medium bg-white rounded-[10px] px-10 py-8 shadow-md">
                          <h4 className="whitespace-nowrap">
                            Do you want to{" "}
                            {project.home_screen_display
                              ? "deactivate"
                              : "activate"}{" "}
                            this project?
                          </h4>
                          <div className="flex items-center gap-4 justify-center my-2">
                            <Button
                              onClick={() => setShowConfirmationModal(false)}
                              variant="contained"
                              sx={{
                                bgcolor: "#D9D9D9",
                                color: "#80848E",
                                ":hover": { bgcolor: "#D9D9D9" },
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => handleSwitchChange(project)}
                              variant="contained"
                              sx={{
                                bgcolor: "#7DAE3F",
                                ":hover": { bgcolor: "#7DAE3F" },
                              }}
                            >
                              Yes
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="bg-[#CCCCCC] w-[1px]"></div>
        <div className="w-[60%]">
          <CurrentProjectImages />
        </div>
      </div>
    </div>
  );
};

export default UpComingProjects;
