
const CircleComponent = (props) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={50} height={50} rx={25} fill="white" />
    <g clipPath="url(#clip0_4141_4992)">
      <path
        d="M21.1757 24.0833H32.3334V25.9166H21.1757L26.0927 30.8336L24.7965 32.1298L17.6667 25L24.7965 17.8701L26.0927 19.1663L21.1757 24.0833Z"
        fill="#02053C"
      />
    </g>
    <defs>
      <clipPath id="clip0_4141_4992">
        <rect
          width={22}
          height={22}
          fill="white"
          transform="translate(14 14)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default CircleComponent;
