import { createContext, useEffect, useState } from "react";
import dummyImg from "../assets/dummy.webp";
import axiosInstance from "../Interceptor/axiosInstance";

const PopularPlacesContext = createContext();

const PopularPlacesProvider = ({ children }) => {
  const [images, setImages] = useState([
    {
      id: 1,
      image: {
        url: dummyImg,
        imagename: "",
      },
      popular_districts: "",
    },
    {
      id: 1,
      image: {
        url: dummyImg,
        imagename: "",
      },
      popular_districts: "",
    },
    {
      id: 1,
      image: {
        url: dummyImg,
        imagename: "",
      },
      popular_districts: "",
    },
    {
      id: 1,
      image: {
        url: dummyImg,
        imagename: "",
      },
      popular_districts: "",
    },
    {
      id: 1,
      image: {
        url: dummyImg,
        imagename: "",
      },
      popular_districts: "",
    },
    {
      id: 1,
      image: {
        url: dummyImg,
        imagename: "",
      },
      popular_districts: "",
    },
  ]);
  const [imageIndex, setImageIndex] = useState("");
  const [updatedImage, setUpdatedImage] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updatedDataToBackEnd, setUpdatedDataToBackEnd] = useState();

  const fetchInitialImagesData = async () => {
    const response = await axiosInstance.get(
      "api/vattara/app/homescreen/get/districts"
    );
    const data = response?.data;
    setImages(data?.message);
  };
  useEffect(() => {
    fetchInitialImagesData();
  }, []);

  return (
    <PopularPlacesContext.Provider
      value={{
        images,
        setImages,
        showConfirmationModal,
        setShowConfirmationModal,
        updatedImage,
        setUpdatedImage,
        imageIndex,
        setImageIndex,
        updatedDataToBackEnd,
        setUpdatedDataToBackEnd,
      }}
    >
      {children}
    </PopularPlacesContext.Provider>
  );
};

export { PopularPlacesContext, PopularPlacesProvider };
