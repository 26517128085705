import React, { useEffect, useState } from "react";
import axiosInstance from "../../Interceptor/axiosInstance";
import { Skeleton } from "@mui/material";

const AllUser = () => {
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    async function review() {
      try {
        const response = await axiosInstance.get("/api/admin/allUser/count");

        setReview(response.data);
      } catch (e) {
      } finally {
        setLoading(false); // Set loading to false after the request is done
      }
    }
    review();
  }, []);

  return (
    <div className=" p-8 h-40 rounded-[15px] bg-white shadow-md  uppercase text-[18px]">
      {loading ? ( // Conditional rendering based on loading state
        <Skeleton className="h-64" />
      ) : (
        <div>
          Total Users Count
          <p className="text-3xl mt-4"> {review.TotalUserCount}</p>
        </div>
      )}
    </div>
  );
};

export default AllUser;
