import SinglePropertyCard from "./RequestDetailCard";
import { useContext } from "react";
import { AssRequestContext } from "../../../context/AssRequestContext";

const RequestsList = () => {
  const { requestsList, viewTab, setViewTab, requestCount } = useContext(AssRequestContext);
  return (
    <>
      <div className="flex mb-4 mx-2 gap-4 text-xs font-bold">
        <button
          onClick={() => {
            setViewTab("Pending");
          }}
          className={`${
            viewTab === "Pending"
              ? "border-[0.3px] border-[#02a7e4] bg-[#02a7e4] text-white"
              : "bg-white text-black"
          } shadow-md rounded-lg px-6 py-[6px]`}
        >
          Pending
        </button>
        <button
          onClick={() => {
            setViewTab("Completed");
          }}
          className={`${
            viewTab === "Completed"
              ? "border-[0.3px] border-[#02a7e4] bg-[#02a7e4] text-white"
              : "bg-white text-black"
          } shadow-md rounded-lg px-6 py-[6px]`}
        >
          Completed
        </button>
      </div>
      <span className="mt-2 mb-4 ml-2 underline font-medium font-Roboto">{`${requestCount} Requests Listed`}</span>
      <div className="px-2 grid grid-cols-3 gap-2">
        {requestsList &&
          requestsList.map((request) => (
            <SinglePropertyCard key={request.id} request={request} />
          ))}
      </div>
    </>
  );
};

export default RequestsList;
