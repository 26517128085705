import SinglePropertyCard from "./SinglePropertyCard";
import { useContext } from "react";
import { ReportedPropsContext } from "../../../context/ReportedPropsContext";
import ReportDetails from "./ReportDetails";
import Shimmer from "../../Shimmer";

const PropertiesList = () => {
  const { reportsList, viewTab, listCount, shimmerState, setViewTab } =
    useContext(ReportedPropsContext);
  return (
    <>
      <div className="flex mb-4 gap-4 text-xs font-bold">
        <button
          onClick={() => {
            setViewTab("Pending");
          }}
          className={`${
            viewTab === "Pending"
              ? "border-[0.3px] border-[#02a7e4] bg-[#02a7e4] text-white"
              : "bg-white text-black"
          } shadow-md rounded-lg px-6 py-[6px]`}
        >
          Pending
        </button>
        <button
          onClick={() => {
            setViewTab("Reviewed");
          }}
          className={`${
            viewTab === "Reviewed"
              ? "border-[0.3px] border-[#02a7e4] bg-[#02a7e4] text-white"
              : "bg-white text-black"
          } shadow-md rounded-lg px-6 py-[6px]`}
        >
          Reviewed
        </button>
      </div>

      <div className="flex items-start gap-2">
        <div className="w-[70%] hide-scrollbar h-[calc(100vh-64px)] overflow-auto flex flex-col gap-2">
          {shimmerState !== true ? (
            <h4 className="font-medium font-Inter text-[15px] px-2 underline">
              {listCount || ""} Properties Listed
            </h4>
          ) : null}
          {shimmerState ? (
            <Shimmer />
          ) : (
            reportsList?.map((property, i) => (
              <SinglePropertyCard key={i} property={property} index={i} />
            ))
          )}
        </div>
        <div className="w-[30%] hide-scrollbar h-[calc(100vh-64px)] overflow-auto bg-[#F5F4F8] border-l-[1px] border-l-[#404040]">
          <ReportDetails />
        </div>
      </div>
    </>
  );
};

export default PropertiesList;
