import React, { useContext } from "react";
import Filters from "./components/Filters";
import UserDetails from "./components/UserDetail";
import RequestsList from "./components/RequestList";
import { AssRequestProvider } from "../../context/AssRequestContext";

const UserAssistance = () => {
  return (
    <AssRequestProvider>
      <div className="font-Roboto">
        <h2 className="font-bold text-xl py-4">User Assistance Requests</h2>
        <div className="flex items-start ">
          <div
            className={`w-[70%] bg-[#F5F4F8] border-r-[1px] border-t-[1px] border-[#404040]
            `}
          >
            <Filters />
            <RequestsList />
          </div>
          <div className="w-[30%] bg-[#F5F4F8] h-[80vh]">
            <UserDetails />
          </div>
        </div>
      </div>
    </AssRequestProvider>
  );
};

export default UserAssistance;
