import { useContext } from "react";
import SinglePropertyCard from "./SinglePropertyCard";
import { PostPropertyContext } from "../../../context/postPropertyContext";
import Shimmer from "../../Shimmer";

const PropertyListing = () => {
  const { properties, shimmerState, listCount } =
    useContext(PostPropertyContext);
  

  return (
    <>
      <div className="px-2 flex flex-col gap-2">
        {shimmerState !== true && (
          <h4 className="font-medium font-Inter text-[15px] px-2 pb-2 underline">
            {listCount} Properties Listed
          </h4>
        )}

        {shimmerState === true ? (
          <Shimmer />
        ) : (
          properties?.map((property, i) => (
            <SinglePropertyCard key={property.propertyID} index={i} property={property} />
          ))
        )}
      </div>
    </>
  );
};

export default PropertyListing;
