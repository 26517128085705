import React from "react";
import { PopularPlacesProvider } from "../../context/PopularPlacesContext";
import ManagePopularPlace from "./components/ManagePopularPlace";
import Preview from "./components/Preview";

const PopularPlaces = () => {
  return (
    <PopularPlacesProvider>
      <div className="flex">
        <div className="w-[65%] bg-[#F5F4F8] border-r-[1px] border-r-[#404040] py-4">
          <ManagePopularPlace />
        </div>
        <div className="w-[35%] bg-[#F5F4F8] relative">
          <Preview />
        </div>
      </div>
    </PopularPlacesProvider>
  );
};

export default PopularPlaces;
