import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import BackComponent from "../../icons/Back";
import KingComponent from "../../icons/King";
import { Box, Divider, Typography, Button } from "@material-ui/core";
import Manage from "./Manage";
import Permission from "./Permission";
import axiosInstance from "../../Interceptor/axiosInstance";
import { Alert, Snackbar } from "@mui/material";


const EditRole = () => {
  const location = useLocation();
  const responseData = location.state?.responseData || {};
  const [selectedRole, setSelectedRole] = useState(responseData.Role);
  const [roles, setRoles] = useState([]);
  const [initialRole, setInitialRole] = useState(responseData.Role);
  const [selectedTab, setSelectedTab] = useState("Display");
  const [open, setOpen] = useState(false);
  const [count,setCount] =useState(0)

  const handleClose = (reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    axiosInstance
      .get("/api/admin/getRole")
      .then((response) => {
        setRoles(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  }, [initialRole]);

  const handleSave = () => {
    axiosInstance
      .post("/api/admin/roleupdate", {
        ExistingRole: initialRole,
        updateRole: selectedRole,
      })
      .then((response) => {
        
        setInitialRole(selectedRole);
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error updating role:", error);
      });
  };

  const handleReset = () => {
    setSelectedRole(initialRole);
  };

  //get method for the managemebers

  return (
    <div className="flex">
      {/* Sidebar */}
      <div className="w-52 h-full bg-gray-100 p-5">
        <Link to="/rolesmanagement">
          <button
            type="button"
            onClick={() => setSelectedRole("")}
            className="mb-5 border-none bg-transparent w-full p-2 font-roboto text-lg font-bold relative right-4 "
          >
            <BackComponent />
            <span className="relative right-6 bottom-5 custom-xl:right-[20px]  ">
              BACK
            </span>
          </button>
        </Link>
        {roles.map((role, index) => (
          <button
            key={index}
            type="button"
            onClick={() => {
              setSelectedRole(role.Roles);
              setInitialRole(role.Roles);
            }}
            className="mb-5 border-none bg-transparent w-full p-0 font-roboto text-lg font-normal text-left"
          >
            <KingComponent className="mr-2 relative top-1 right-2" />
            <span className="relative left-6 bottom-5">{role.Roles}</span>{" "}
          </button>
        ))}
      </div>

      <Divider
        orientation="vertical"
        flexItem
        className="relative right-6 h-3/4 lg:left-[20px] sm:left-[20px]  "
        style={{ height: "900px" }}
      />
      <div className="flex-1">
        <Box className="mt-5 relative lg:left-[40px] sm:left-[40px] ">
          <Typography variant="h6" className="flex flex-row justify-start">
            Edit Role - {initialRole || "New Role"}
          </Typography>
          <div className="flex flex-row justify-start mt-6 space-x-6">
            <button
              type="button"
              onClick={() => setSelectedTab("Display")}
              className="mb-5 border-none bg-transparent w-40  p-1 font-roboto text-base font-normal text-left"
            >
              Display
            </button>
            <button
              type="button"
              onClick={() => setSelectedTab("Permission")}
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              className="mb-5 border-none bg-transparent w-96 p-1 font-roboto text-base font-normal text-left relative sm:right-10"
            >
              Permission
            </button>
            <button
              type="button"
              onClick={() => setSelectedTab("Manage Members")}
              className="mb-5 border-none bg-transparent w-56 p-1 font-roboto text-base font-normal text-left relative sm:right-72  "
            >
              Manage Members {`(${count})`}
            </button>
          </div>

          <Divider
            orientation="horizontal"
            className="w-7/12 border-b border-black relative top-[-10px]"
          />

          {selectedTab === "Display" && (
            <div>
              <p className="font-roboto text-base font-medium relative top-2.5">
                Role Name
              </p>
              <input
                placeholder="Role Name"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                className="w-4/12 rounded-lg p-2.5 border border-gray-400 font-roboto mt-6 outline-none"
              />
              <Divider
                orientation="horizontal"
                color="#bdbdbd"
                className="w-4/12 relative top-4"
              />
              <div className="mt-12 flex gap-2">
                {selectedRole !== "Member" && (
                  <>
                    <Button
                      variant="contained"
                      onClick={handleReset}
                      style={{
                        backgroundColor: "#F5F4F8",
                        border: "none",
                        boxShadow: "none",
                      }}
                      className=" text-black mr-36 w-24 p-2 font-roboto text-sm font-bold relative left-56"
                    >
                      Reset
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      style={{
                        backgroundColor: "#7DAE3F",
                      }}
                      className=" text-white ml-10 w-24 p-2 font-roboto text-sm font-bold relative left-64"
                    >
                      Save
                    </Button>

                    <Snackbar
                      open={open}
                      autoHideDuration={3000}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="success"
                        sx={{ width: "100%" }}
                      >
                        Role Name updated successfully
                      </Alert>
                    </Snackbar>
                  </>
                )}
              </div>
            </div>
          )}
          {selectedTab === "Permission" && (
            <div>
              <Permission selectedRole={selectedRole} />
            </div>
          )}
          {selectedTab === "Manage Members" && (
            <div>
              <Manage selectedRole={selectedRole} setCount={setCount} />
            </div>
          )}
        </Box>
      </div>
    </div>
  );
};

export default EditRole;
