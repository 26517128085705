
const EditComponent = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={19} cy={19} r={19} fill="#D9D9D9" />
    <circle cx={10} cy={19} r={3} fill="#8E8E8E" />
    <circle cx={19} cy={19} r={3} fill="#8E8E8E" />
    <circle cx={28} cy={19} r={3} fill="#8E8E8E" />
  </svg>
);
export default EditComponent;
