import { createContext, useEffect, useState } from "react";
import dummy from "../assets/dummy.webp";
import axiosInstance from "../Interceptor/axiosInstance";

const AdContext = createContext();

const AdProvider = ({ children }) => {
  const [viewTab, setViewTab] = useState("Property List View Ads");

  const [adList, setAdList] = useState([
    {
      Title: "",
      link: "",
      image: dummy,
    },
  ]);

  const [currentAdUpload, setCurrentAdUpload] = useState({});

  const fetchData = async (currentViewTab) => {
    try {
      const response = await axiosInstance.get(
        `/api/ads/allAds/${currentViewTab}`
      );
      setAdList(response?.data?.message);
    } catch (error) {
      
    }
  };

  useEffect(() => {
    fetchData(viewTab);
  }, [viewTab]);

  return (
    <AdContext.Provider
      value={{
        adList,
        setAdList,
        currentAdUpload,
        setCurrentAdUpload,
        fetchData,
        viewTab,
        setViewTab,
      }}
    >
      {children}
    </AdContext.Provider>
  );
};

export { AdContext, AdProvider };
