import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectEmpId } from "../store/authSlice";
import axiosInstance from "../Interceptor/axiosInstance";
// import { reportedProperties } from "../data";

const ReportedPropsContext = createContext();

const ReportedPropsProvider = ({ children }) => {
  const [reportsList, setReportsList] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showDropDown, setShowDropDown] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedDateForFilter, setSelectedDateForFilter] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [reportDetails, setReportDetails] = useState(null);
  const [viewTab, setViewTab] = useState("Pending");
  const [shimmerState, setShimmerState] = useState(false);
  const [listCount, setListCount] = useState(0);
  const [loader, setLoader] = useState("");
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);

  const empId = useSelector(selectEmpId);

  const fetchData = async () => {
    setShimmerState(true);
    try{
    const response = await axiosInstance.get(
      "/api/admin/getAll/reported/property"
    );
    const data = response?.data;
    
    if (viewTab === "Pending") {
      const propertiesList = data?.flattenedResults?.filter(
        (item) => item?.status === "Pending"
      );
      setReportsList(propertiesList);
      setOriginalList(propertiesList || []);  
      setFilteredData(propertiesList || []);  
      setListCount((propertiesList || []).length); 
    } else if (viewTab === "Reviewed") {
      const propertiesList = data?.flattenedResults?.filter(
        (item) => item?.status === "Reviewed"
      );
      setReportsList(propertiesList);
      setOriginalList(propertiesList || []);  
      setFilteredData(propertiesList || []);  
      setListCount((propertiesList || []).length);
    }

    setShimmerState(false);
  }
    catch(e){
      
    }
  };

  useEffect(() => {
    fetchData();
    setSelectedProperty();
    setReportDetails();
    setSelectedDateForFilter(null);
    setSelectedPropertyType(null);
  }, [viewTab]);

  const handlePropTypeFilter = (type) => {
    setSelectedPropertyType(type);
    const filteredList = filteredData.filter(
      (property) => property?.res_or_com === type
    );
    setReportsList(filteredList);
    setListCount(filteredList.length);
  };

  const handleSearchInput = (e) => {
    setSearchText(e.target.value);
  };

  const handleReset = () => {
    setReportsList(originalList);
    setListCount(originalList.length ||0);
    setSelectedDateForFilter(null);
    setSelectedPropertyType(null);
  };

  const handleSearch = () => {
    const filteredList = originalList.filter(
      (property) =>
        (property?.building_name &&
          property?.building_name
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        property?.propertyID.toString().toLowerCase() ===
          searchText.toLowerCase() ||
        property?.userData?.name
          .toLowerCase()
          .includes(searchText.toLowerCase())
    );
    setReportsList(filteredList);
    setListCount(filteredList.length);
  };

  const handleDateChange = (e) => {
    const date = new Date(e);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    setSelectedDateForFilter(formattedDate);
  };

  const handleDateFilter = () => {
    const filteredData = originalList.filter((data) =>
      data.Reported_Date.includes(selectedDateForFilter)
    );
    setReportsList(filteredData);
    setFilteredData(filteredData);
    setShowDropDown("");
    setListCount(filteredData.length);
  };

  const handleMarkAsReviewed = async (property, index) => {
    setLoader(index);
    const bodyToSend = {
      emp_id: empId,
      reviewed_status: "Reviewed",
      post_id: property?.post_id,
      user_id: property?.user_id,
    };

    try {
      const response = await axiosInstance.put(
        "/api/admin/update/reviewedStatus",
        bodyToSend
      );
      if (!response.ok) {
        throw new Error("Failed to update property status");
      }
      const result = await response.json();
      
    } catch (error) {
      console.error("Failed to update property status", error);
    }

    setLoader("");
    fetchData();
  };

  return (
    <ReportedPropsContext.Provider
      value={{
        showDropDown,
        setShowDropDown,
        selectedProperty,
        setSelectedProperty,
        showDetails,
        setShowDetails,
        reportsList,
        setReportsList,
        handlePropTypeFilter,
        handleSearchInput,
        handleSearch,
        handleDateChange,
        handleDateFilter,
        handleMarkAsReviewed,
        handleReset,
        reportDetails,
        setReportDetails,
        viewTab,
        setViewTab,
        shimmerState,
        setShimmerState,
        listCount,
        setListCount,
        selectedDateForFilter,
        loader,
        setLoader,
        selectedPropertyType,
      }}
    >
      {children}
    </ReportedPropsContext.Provider>
  );
};

export { ReportedPropsContext, ReportedPropsProvider };
