import React from 'react'
import JobPortalComp from "./components/JobPortalComp";

const JobPortal = () => {
  return (
    <div className='pb-40 font-Roboto'>
      {" "}
      <JobPortalComp />
    </div> 
  );
}

export default JobPortal