import { RiEditLine } from "react-icons/ri";
import { useContext, useEffect, useState } from "react";
import PlacesModal from "./PlacesModal";
import { PopularPlacesContext } from "../../../context/PopularPlacesContext";
import ImageUpdateConfirmationModal from "./ImageUpdateConfirmationModal";
import { useSelector } from "react-redux";
import { selectUserPermissions } from "../../../store/authSlice";

const SelectPlace = ({ placesData }) => {
  const [showModal, setShowModal] = useState(false);
  const [access, setAccess] = useState();
  const expectedPermission = "In App Customization";
  const userRolePermission = useSelector(selectUserPermissions);

  useEffect(() => {
    const hasAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.edit === "true"
    );

    setAccess(hasAccess);
  }, [userRolePermission]);

  const {
    images,
    showConfirmationModal,
    setShowConfirmationModal,
    updatedImage,
    imageIndex,
    setImageIndex,
    setImages,
  } = useContext(PopularPlacesContext);

  const handelModal = (index) => {
    setShowModal(!showModal);
    setShowConfirmationModal(false);
    setImageIndex(index);
  };

  return (
    <>
      <div className="mx-8 mb-2">
        <h4 className="text-[#252B5C] text-[15px] font-k2d font-semibold">
          Select a place with in Tamil Nadu
        </h4>
      </div>
      <div className="flex flex-wrap items-center justify-center gap-7 p-4 relative">
        {images.map((image, index) => (
          <div key={index} className="relative">
            <div className="relative">
              <img
                src={image.image.url}
                className="w-[190px] h-[110px] object-cover rounded-[10px]"
                alt="Dummy Img"
              />
              <div className="font-roboto font-semibold text-[#ffffff] tracking-wide text-lg absolute inset-0 flex items-end justify-center mb-3">
                {image.popular_districts}
              </div>
            </div>
            {access !== undefined && access ? (
              <div
                onClick={() => handelModal(index)}
                className="bg-[#E4E0EE] w-fit p-[6px] rounded-full absolute -top-2 -right-2"
              >
                <RiEditLine className="text-lg text-[#8E8E8E]" />
              </div>
            ) : null}
          </div>
        ))}
        <PlacesModal
          imageIndex={imageIndex}
          showModal={showModal}
          setShowModal={setShowModal}
          places={placesData}
        />
        {showConfirmationModal && (
          <ImageUpdateConfirmationModal
            updatedImage={updatedImage}
            imageIndex={imageIndex}
            setImages={setImages}
            setShowConfirmationModal={setShowConfirmationModal}
            setShowModal={setShowModal}
            images={images}
          />
        )}
      </div>
    </>
  );
};

export default SelectPlace;
