
const BackComponent = (props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.8333 7.45835H4.15621L9.97913 1.63544L8.49996 0.166687L0.166626 8.50002L8.49996 16.8334L9.96871 15.3646L4.15621 9.54169H16.8333V7.45835Z"
      fill="black"
    />
  </svg>
);
export default BackComponent;
