import { Suspense, lazy, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess, loginFailure } from "../store/authSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import axiosInstance from "../Interceptor/axiosInstance";
import logo from "../assets/framed logo.png";
import { CircularProgress } from "@material-ui/core";

const CustomAlertModal = lazy(() => import("../CustomAlert/CustomAlertModal"));

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [alert, setAlert] = useState(false);
  const [showProgress, setShowProgress] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setShowProgress(true);
      const response = await axiosInstance.post("/api/admin/login", {
        email,
        password,
      });

      if (response.data && response.data.Data) {
        const {
          role,
          email,
          empId,
          profile_pic: profilePic,
        } = response.data.Data;

        if (!role) {
          console.error("Login failed: User role is null or undefined");
          dispatch(loginFailure("User role is null or undefined"));

          return;
        }

        if (rememberMe) {
          localStorage.setItem("rememberedPassword", password);
        } else {
          localStorage.removeItem("rememberedPassword");
        }

        dispatch(
          loginSuccess({
            user: email,
            role,
            email,
            password,
            empId,
            profilePic,
            userRolePermission: response.data.UserRolePermission,
            userData: response.data.Data,
            refreshToken: response.data.encryptedToken,
            accessToken: response.data.token,
          })
        );

        navigate("/dashboard");
      } else {
        console.error("Login failed: Invalid response data");
        dispatch(loginFailure("Invalid response data"));
        // setAlert(true);

        // setTimeout(() => {
        //   setAlert(false);
        // }, 2000);
      }
    } catch (error) {
      setAlert(true);

      // setTimeout(() => {
      //   setAlert(false);
      // }, 4000);
      console.error("Authentication failed:", error.message);
      dispatch(loginFailure(error.message));
    } finally {
      setShowProgress(false);
    }
  };

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex items-center justify-center h-[90vh] p-5 mt-7">
      <div className="flex flex-col items-start p-10 py-0 max-w-md mt-4 relative border-2 shadow-md rounded-lg">
        <img className="mt-4 mb-8 mx-auto" src={logo} alt="vattara-logo" />
        <h1 className="text-lg font-medium up font-roboto mb-1 sm:text-lg">
          Welcome Back
        </h1>
        <p className="text-lg font-normal font-roboto text-gray-800 mb-6 sm:text-[12px]">
          Please enter your details to connect.
        </p>
        <label className="text-base font-medium font-roboto text-black mb-2 sm:text-sm">
          Email address
        </label>
        <input
          type="text"
          placeholder="Company@gmail.com"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setAlert(false);
          }}
          className="w-full px-[12px] py-[7px] mb-3 font-roboto text-base rounded-[4px] border border-gray-300 hover:border-[#02a7e4] focus:outline-[#02a7e4]"
        />
        <label className="text-base font-medium font-roboto text-black mb-2 sm:text-sm ">
          Password
        </label>
        {/* <input
          type="password"
          placeholder="************"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full p-[5px] mb-3 font-roboto text-base rounded-lg border border-gray-400"
        />  */}
        <TextField
          placeholder="************"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setAlert(false);
          }}
          type={showPassword ? "text" : "password"}
          fullWidth
          className="mb-3"
          InputProps={{
            className:
              "w-full mb-3 font-roboto text-base rounded-[20px] hover:outline-none",

            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={togglePasswordVisibility}
                  edge="end"
                  aria-label={showPassword ? "Hide password" : "Show password"}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#02a7e4",
              },
              "&:hover fieldset": {
                borderColor: "#02a7e4",
              },
            },
            "& input": {
              padding: "9px 12px", // Adjust padding as needed
            },
          }}
        />
        <p
          className={`${
            alert ? "opacity-100" : "opacity-0"
          } transition-opacity text-red-500 font-medium text-[13px]`}
        >
          User does not exist or User status is not active
        </p>
        <div className="flex items-center mb-4 mt-4 sm:w-[230px]">
          {/* <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
            className="mr-2"
          /> */}

          {/* <label className="text-base font-sm font-roboto text-black sm:text-[12px]">
            Remember me
          </label> */}
          <a
            href="#"
            onClick={handleForgotPasswordClick}
            className="text-base font-sm font-roboto text-black sm:text-[12px]"
          >
            Forgot password?
          </a>
        </div>
        <button
          onClick={handleLogin}
          className=" p-2 mb-4 font-roboto text-base bg-[#02a7e4] text-white rounded-lg w-full hover:bg-[#02a7e4] focus:outline-none"
        >
          {showProgress ? (
            <CircularProgress color="default" size={17} />
          ) : (
            "Log In"
          )}
        </button>
      </div>
      <div>
        <img
          src="https://vattara-img.s3.amazonaws.com/Admin+panel+image.jpg"
          alt="hi"
          className="w-[200px] h-[300px] object-cover relative rounded-lg sm:w-[500px] sm:h-[500px] md:w-[600px] md:h-[520px] lg:w-[600px] lg:h-[500px] lg:left-[60px] xl:w-[700px] xl:h-[640px] xl:left-[170px] xxl:w-[700px] xxl:h-[700px] xxl:left-[240px] custom-lg:w-[800px] custom-lg:h-[700px] custom-lg:left-[160px] custom-xl:w-[830px] custom-xl:h-[940px] custom-xl:left-[370px]"
        />
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <CustomAlertModal
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      </Suspense>
    </div>
  );
}

export default LoginPage;
