import React from "react";
import { VattaraPropsProvider } from "../../context/VattaraPropsContext";
import VerifiedProperties from "./components/VerifiedProperties";
import VattaraProperties from "./components/VattaraProperties";

const VattaraVerifiedProps = () => {
  return (
    <VattaraPropsProvider>
      <div className="bg-[#F5F4F8] py-4 font-Roboto relative">
        <h3 className="font-Roboto font-bold text-[20px]">
          Manage Vattara Properties
        </h3>
        <p className="font-Inter font-bold text-[#7B7D8C] text-xs py-3">
          Home -{`>`} In-App Customization -{`>`} Vattara Properties
        </p>

        <VerifiedProperties />
        <VattaraProperties />
      </div>
    </VattaraPropsProvider>
  );
};

export default VattaraVerifiedProps;
