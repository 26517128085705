import { useContext } from "react";
import { FaStar } from "react-icons/fa6";
import { UserReviewContext } from "../../../context/UserReviewContext";

const tableHeader = [
  {
    name: "No.",
    width: "1%",
  },
  {
    name: "Name",
    width: "19%",
  },
  {
    name: "Posted Date",
    width: "9%",
  },
  {
    name: "Mobile Number",
    width: "10%",
  },
  {
    name: "E-mail",
    width: "20%",
  },
  {
    name: "Ratings",
    width: "7%",
  },
  {
    name: "Feedback",
    width: "34%",
  },
];

const UserDataTable = () => {
  const { tableData, currentUser, setCurrentUser, reviewCount } =
    useContext(UserReviewContext);
  return (
    <div className="mt-6">
      <span className="font-semibold underline">{`${reviewCount} ${
        reviewCount === 1 ? "Review" : "Reviews"
      } Listed`}</span>
      <table className="w-full border-separate border-spacing-x-0 border-spacing-y-4  tracking-tight">
        <thead className="rounded-[15px] bg-[#D9D9D9]">
          <tr>
            {tableHeader.map((item, i) => (
              <th
                style={{ width: item.width }}
                className={`text-xs font-medium p-3 text-[#202020] ${
                  i < tableHeader.length - 1
                    ? "border-r-[#202020] border-opacity-30 border-r-[0.3px]"
                    : ""
                }  `}
                key={i}
              >
                {item.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="">
          {tableData &&
            tableData?.map((user, i) => (
              <tr
                onClick={() => setCurrentUser(user)}
                className={`cursor-pointer text-[#404040] text-xs ${
                  currentUser && currentUser.id === user.id
                    ? "bg-[#E4E0EE]"
                    : "bg-white"
                } rounded-[15px] text-center`}
                key={i}
              >
                <td className="py-3 px-2 border-r-[#202020] border-opacity-30 border-r-[0.3px]">
                  {i + 1}
                </td>
                <td className="py-3 px-2 border-r-[#202020] border-opacity-30 border-r-[0.3px]">
                  {user?.tbl_user_reg?.name}
                </td>
                <td className="py-3 px-2 border-r-[#202020] border-opacity-30 border-r-[0.3px]">
                  {user.createdAt}
                </td>
                <td className="py-3 px-2 border-r-[#202020] border-opacity-30 border-r-[0.3px]">
                  {user?.tbl_user_reg?.mobile_no}
                </td>
                <td className="py-3 px-2 border-r-[#202020] border-opacity-30 border-r-[0.3px]">
                  {user?.email}
                </td>
                <td className="py-3 px-2 flex items-center justify-center gap-1 text-[13px] ">
                  {[...Array(5)].map((_, i) => (
                    <div
                      key={i}
                      className={
                        i < user?.Rating ? "text-[#FBB337]" : "text-[#929292]"
                      }
                    >
                      <FaStar />
                    </div>
                  ))}
                </td>
                <td className="p-3 text-left border-l-[#202020] border-opacity-30 border-l-[0.3px]">
                  {user?.describe_issuse}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserDataTable;
