import PropTypes from "prop-types";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import RemoveComponent from "../../icons/Remove";
import axios from "axios";
import { useEffect, useState } from "react";
import axiosInstance from "../../Interceptor/axiosInstance";
import { Alert, Snackbar } from "@mui/material";
import userProfile from "../../assets/userProfile.jpg";

const useStyles = makeStyles(() => ({
  menu: {
    position: "absolute",
    left: "45px",
  },
  table: {
    minWidth: 400,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: "700",
    fontFamily: "Roboto",
    position: "absolute",
    visibility: "hidden",
  },
  cell: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "700",
    padding: "10px",
  },
  pagination: {
    "& .MuiPaginationItem-outlined": {
      border: "none",
    },
    "& .Mui-selected": {
      backgroundColor: "#252B5C",
      color: "white",
    },
    "& .MuiPaginationItem-page:hover": {
      backgroundColor: "#252B5C",
      color: "white",
    },
  },
  paper: {
    marginTop: "20px",
    padding: "0px",
    paddingInlineEnd: "20px",
    fontFamily: "Roboto",
    width: "750px",
    height: "auto",
    top: "722px",
    left: "357px",
    background: "#F5F4F8",
    border: "1px solid #F5F4F8",
    borderRadius: "8px",
    boxShadow: "none",
  },
  row: {
    marginTop: "10px", // Adjust spacing between rows
    marginBottom: "10px", // Adjust spacing between rows
  },
  cellContainer: {
    display: "flex",
    alignItems: "center",
  },
  profilePic: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  username: {
    marginLeft: "30px", // Adjust the spacing between the image and the username
  },
}));

const Manage = ({ selectedRole,setCount }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState([]);

  const handleClose = (reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!selectedRole) {
      console.error("No role selected");
      return;
    }

    axiosInstance
      .get(`/api/admin/user/managemember/rolefilter/${selectedRole}`)
      .then((response) => {
        
        setMembers(response.data.message);
        setCount(response.data.message.length)
        
      })
      .catch((error) => {
        console.error("Error fetching administrative members:", error);
      });
  }, [selectedRole]);

  const handleRemove = (emp_id) => {
    axiosInstance
      .delete(`/api/admin/delete/user/role/${emp_id}`)
      .then((response) => {
        
        setMembers((prevMembers) =>
          prevMembers.filter((member) => member.emp_id !== emp_id)
        );
        setOpen(true);
      })
      .catch((error) => {
        console.error("Error removing member:", error);
      });
  };

  const handleSearchMembers = (e) => {
    const searchTerm = e.target.value;
    const filteredMembers = members.filter((member) =>
      member.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setMembers(filteredMembers);
  };

  return (
    <div>
      <input
        id="outlined-basic"
        type="text"
        onChange={(e) => handleSearchMembers(e)}
        placeholder="Search members"
        onFocus={(e) => e.target.select()}
        style={{
          marginBottom: "20px",
          width: "65%",
          height: "40px",
          boxSizing: "border-box",
          marginTop: "10px",
          marginRight: "20px",
          fontFamily: "Roboto",
          fontWeight: "400",
          backgroundColor: "#D9D9D9",
          border: "1px solid #ccc",
          borderRadius: "5px",
          paddingLeft: "8px",
          outline: "none",
        }}
      />
      <TableContainer component={Paper} className={classes.paper}>
        <Table className={classes.table}>
          {/* <TableHead>
            <TableRow>
              <TableCell
                className={classes.cell}
                style={{ borderBottom: 'transparent' }}
              ></TableCell>
              <TableCell
                className={classes.cell}
                style={{ borderBottom: 'transparent' }}
              ></TableCell>
              <TableCell
                className={classes.cell}
                style={{ borderBottom: 'transparent' }}
              ></TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {members.map((member, index) => (
              <TableRow key={index} className={classes.row}>
                <TableCell className={classes.cell}>
                  <div className={classes.cellContainer}>
                    <img
                      src={
                        member.profile_pic ? member.profile_pic : userProfile
                      }
                      alt={member.username}
                      onError={(e) => {
                        console.error("Error loading image:", e.target.src);
                        e.target.src = "https://via.placeholder.com/120"; // Valid fallback image URL
                      }}
                      className={classes.profilePic}
                    />
                    <span className={classes.username}>{member.username}</span>
                    <span className={classes.username}>{member.emp_id}</span>
                  </div>
                </TableCell>
                <TableCell className={classes.cell}></TableCell>
                <TableCell
                  className={classes.cell}
                  style={{
                    position: "relative",
                    left: "20px",
                  }}
                >
                  {selectedRole !== "Member" && (
                    <RemoveComponent
                      onClick={() => handleRemove(member.emp_id)}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Member deleted successfully
              </Alert>
            </Snackbar>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

Manage.propTypes = {
  selectedRole: PropTypes.string.isRequired,
};

export default Manage;
