import React, { useContext } from "react";
import Calendar from "react-calendar";
import { IoMdArrowDropdown, IoMdRefresh } from "react-icons/io";
import { IoSearchSharp } from "react-icons/io5";
import { PostPropertyContext } from "../../../context/postPropertyContext";

const Filters = () => {
  const {
    showDropDown,
    setShowDropDown,
    handleSearchInput,
    handleDateChange,
    handleDateFilter,
    currentPropertyTypeTab,
    setCurrentPropertyTypeTab,
    handleSearch,
    handleReset,
    selectedDateForFilter,
    handleUserCatgFilter,
    selectedFilter,
  } = useContext(PostPropertyContext);

  const PropertiesTab = ["Pending", "Rejected", "Approved", "All Properties"];
  const userCategories = ["Owner", "Agent", "Builder", "Promoter"];

  return (
    <div className="p-2">
      <div className="flex gap-4 mt-2 text-sm">
        {PropertiesTab.map((tab, i) => (
          <button
            onClick={() => setCurrentPropertyTypeTab(tab)}
            key={i}
            className={`flex-1 ${
              currentPropertyTypeTab === tab
                ? "bg-[#02a7e4] text-white"
                : "bg-white text-black"
            }  py-1 rounded-[8px] border-b-[0.3px] border-b-[#8E8E8E]`}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="w-[100%] h-[0.6px] bg-[#404040] my-4"></div>
      <div className="flex relative items-center gap-4 my-2 text-xs">
        <IoMdRefresh
          onClick={handleReset}
          className="text-3xl cursor-pointer bg-white rounded-md p-1"
        />
        <div
          onClick={() =>
            showDropDown === "DateDropDown"
              ? setShowDropDown("")
              : setShowDropDown("DateDropDown")
          }
          className="cursor-pointer  bg-[#FFFFFF] w-[170px] px-2 py-2 rounded-[8px] shadow-sm flex items-center justify-between"
        >
          {`Posted Date ${
            selectedDateForFilter !== null || undefined || "" ? " - " : ""
          } ${selectedDateForFilter !== null ? selectedDateForFilter : ""}`}
          <span className="text-lg">
            <IoMdArrowDropdown />
          </span>
        </div>
        <div
          onClick={() =>
            showDropDown === "UserTypeDropDown"
              ? setShowDropDown("")
              : setShowDropDown("UserTypeDropDown")
          }
          className="cursor-pointer relative bg-[#FFFFFF] w-[150px] pl-6 pr-4 py-2 rounded-[8px] shadow-sm flex items-center justify-between"
        >
          {selectedFilter ? selectedFilter : "User Type"}
          <span className="text-lg">
            <IoMdArrowDropdown />
          </span>
          <div
            className={`absolute ${
              showDropDown === "UserTypeDropDown"
                ? "top-10 z-10"
                : "-top-0 -z-10"
            }  left-0 flex flex-col items-start justify-center w-full px-[33px] bg-white rounded-[8px] shadow-md transition-all duration-500`}
          >
            {userCategories.map((category, i) => (
              <div
                key={i}
                onClick={() => handleUserCatgFilter(category)}
                className="cursor-pointer hover:underline flex items-center gap-2 py-2"
              >
                {category}
              </div>
            ))}
          </div>
        </div>{" "}
        <div
          className={`absolute ${
            showDropDown === "DateDropDown" ? "block" : "hidden"
          } left-12 top-10 w-[250px] z-10 bg-white rounded-[8px] shadow-md transition-all duration-500`}
        >
          <Calendar onChange={(e) => handleDateChange(e)} />
          <div className="flex justify-end items-center px-4 py-2 gap-4 text-[#65558F] font-medium">
            <button
              onClick={() => setShowDropDown("")}
              className="hover:font-bold"
            >
              Cancel
            </button>
            <button onClick={handleDateFilter} className="hover:font-bold">
              Ok
            </button>
          </div>
        </div>
        <div className="bg-[#FFFFFF] rounded-[8px] shadow-sm flex items-center text-[#84919A]">
          <div className="text-xs  flex items-center gap-2 px-2">
            <span className="text-lg">
              <IoSearchSharp />
            </span>
            <input
              onChange={(e) => handleSearchInput(e)}
              className="pl-2 py-2 w-[240px] focus:outline-none"
              type="search"
              placeholder="Search by prop id/type/district, contact no"
            />
          </div>
          <button
            onClick={handleSearch}
            className="px-4 py-1 mr-2 border-[1px] border-[#DDE2E4] rounded-[6px] text-[#252C32]"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filters;
