import React, { useState } from "react";
import { createPortal } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import { updatePickerResponse } from "../../../redux/actions";
import { MdDeleteOutline } from "react-icons/md";
import {
  MenuItem,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  LinearProgress,
  Typography,
  Button,
} from "@mui/material"; // Import MUI components

AWS.config.update({
  region: "us-east-1",
  accessKeyId: "AKIAXKPUZW5AH27B7EGM",
  secretAccessKey: "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",
});

const S3 = new AWS.S3();

const ImageUploader = () => {
  const pickerResponse = useSelector(
    (state) => state.imageReducer.pickerResponse
  );
  const dispatch = useDispatch();

  const { selectedOptions } = useSelector((state) => state.form);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalImageId, setModalImageId] = useState(null);
  const [selectedTag, setSelectedTag] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const tags = {
    residential: {
      exterior: ["Front View", "Entrance", "Backyard / Garden", "Parking Area"],
      interior: [
        "Living Room",
        "Dining Room",
        "Kitchen",
        "Bedroom",
        "Bathroom",
        "Hallways",
        "Stairs",
        "Elevator",
      ],
      amenities: ["Gym"],
    },

    commercial: {
      exterior: ["Building Front", "Entrance", "Parking Area", "Signage"],
      interior: [
        "Lobby / Reception",
        "Hallways",
        "Work Area / Office Space",
        "Meeting / Conference Room",
        "Bathrooms / Restrooms",
        "Storage Area",
        "Stairs",
        "Elevator",
      ],
      amenities: ["Gym"],
    },
  }; // Predefined tag options

  // Function to upload a file to S3
  const uploadFileToS3 = async (bucketName, file) => {
    const fileName = `image_${Date.now()}.jpg`;
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };

    try {
      const uploadPromise = S3.upload(params)
        .on("httpUploadProgress", (event) => {
          const percent = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(percent); // Update the progress state
        })
        .promise();

      const uploadResponse = await uploadPromise;

      return uploadResponse.Location;
    } catch (error) {
      throw new Error(`Error uploading ${fileName} to S3: ${error.message}`);
    }
  };

  // Generate a unique number for the image ID
  const generateUniqueNumber = () => {
    let uniqueNumber = "";
    const digits = "0123456789";

    for (let i = 0; i < 6; i++) {
      uniqueNumber += digits.charAt(Math.floor(Math.random() * digits.length));
    }

    return uniqueNumber;
  };

  // Handle the selection of a cover image
  const handlePress = (id) => {
    const updatedData = pickerResponse.map((item) => ({
      ...item,
      isCover: item.id === id,
    }));
    dispatch(updatePickerResponse(updatedData)); // Update Redux state
  };

  // Handle file drop for uploading images
  const handleDrop = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      alert("Only JPG, JPEG, and PNG files are allowed."); // Alert for unsupported files
    }
    const bucketName = "vattara-img";
    const uploadedImages = await Promise.all(
      acceptedFiles.map(async (file) => {
        try {
          const uploadedImage = await uploadFileToS3(bucketName, file);
          const newImage = {
            id: generateUniqueNumber(),
            isCover: false,
            isVisible: true,
            name: file.name,
            uri: uploadedImage,
            type: file.type,
            filename: file.name,
          };
          return newImage;
        } catch (error) {
          console.error("Failed to upload image to S3:", error);
          throw error;
        }
      })
    );

    if (Array.isArray(uploadedImages)) {
      const updatedPickerResponse = [...pickerResponse, ...uploadedImages];
      dispatch(updatePickerResponse(updatedPickerResponse)); // Update Redux state
    }
    setUploadProgress(0); // Reset progress after upload
  };

  // Remove an image from the list
  const removeImage = (id) => {
    const imageToRemove = pickerResponse.find((image) => image.id === id);
    if (imageToRemove.isCover) {
      alert("Can't delete the cover image");
      return;
    }
    const updatedImages = pickerResponse.filter((image) => image.id !== id);
    dispatch(updatePickerResponse(updatedImages)); // Update Redux state
  };

  // Open the modal to edit the image tag
  const openModal = (id) => {
    setModalImageId(id);
    const image = pickerResponse.find((img) => img.id === id);
    setSelectedTag(image ? image.ImageTag : ""); // Set the initial value of the dropdown
    setModalVisible(true);
  };

  // Save the image tag
  const saveImageTag = () => {
    const updatedImages = pickerResponse.map((image) => {
      if (image.id === modalImageId) {
        return { ...image, ImageTag: selectedTag };
      }
      return image;
    });
    dispatch(updatePickerResponse(updatedImages)); // Update Redux state
    setModalVisible(false);
    setSelectedTag("");
  };

  // Render each image item
  const renderItem = (item) => (
    <div className="relative my-4 w-40 h-24" key={item.id}>
      <div
        className={`p-2 ${
          item.isCover ? "bg-[#2E368F]" : "bg-black"
        } text-white rounded-t-lg text-center`}
      >
        <button onClick={() => handlePress(item.id)} className="relative">
          Set as cover
        </button>
      </div>
      <img
        src={item.uri}
        alt={item.name}
        className="w-full h-full  rounded-b-lg object-cover"
      />
      <div className="absolute w-[90%]  text-[11px]  -bottom-6 left-2 flex justify-center items-center">
        <button
          onClick={() => openModal(item.id)}
          className="w-full bg-white p-2 rounded-lg flex justify-between opacity-90"
        >
          <span className="text-black text-left">
            {item?.ImageTag || "Others"}
          </span>
          <span className="text-red-500">
            {selectedOptions.subType === "Plot / Land" ? "" : "Edit"}
          </span>
        </button>
      </div>
      <div
        className={`${
          item.isCover ? "hidden" : "block"
        } absolute top-0 right-0 p-2`}
      >
        <button onClick={() => removeImage(item.id)}>
          <div className="w-5 h-5 text-red-500 text-lg">
            <MdDeleteOutline />
          </div>
        </button>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col p-5">
      <div className="mb-5">
        <Dropzone
          accept={{ "image/jpeg": [], "image/png": [] }}
          onDrop={handleDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className:
                  "border-2 border-dashed border-gray-400 p-5 text-center cursor-pointer",
              })}
            >
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          )}
        </Dropzone>
        {uploadProgress > 0 && (
          <div className="mt-4">
            <Typography variant="body2">
              Upload Progress: {uploadProgress}%
            </Typography>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </div>
        )}
      </div>
      <div className="flex flex-wrap gap-4 text-sm">
        {pickerResponse.map((item) => renderItem(item))}
      </div>
      {modalVisible &&
        selectedOptions.subType !== "Plot / Land" &&
        createPortal(
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-5 rounded-lg">
              <h2>Edit Image Tag</h2>
              <FormControl component="fieldset" fullWidth margin="normal">
                {selectedOptions.propertyType === "Residential" ? (
                  <div>
                    <RadioGroup
                      value={selectedTag}
                      onChange={(e) => setSelectedTag(e.target.value)}
                      sx={{
                        fontWeight: 600,
                        fontSize: 19,
                      }}
                    >
                      {" "}
                      Exterior
                      {tags.residential.exterior.map((tag) => (
                        <FormControlLabel
                          key={tag}
                          value={tag}
                          control={<Radio />}
                          label={tag}
                        />
                      ))}
                    </RadioGroup>
                    <RadioGroup
                      value={selectedTag}
                      onChange={(e) => setSelectedTag(e.target.value)}
                      sx={{
                        fontWeight: 600,
                        fontSize: 19,
                      }}
                    >
                      {" "}
                      Interior
                      {tags.residential.interior.map((tag) => (
                        <FormControlLabel
                          key={tag}
                          value={tag}
                          control={<Radio />}
                          label={tag}
                        />
                      ))}
                    </RadioGroup>
                    <RadioGroup
                      value={selectedTag}
                      onChange={(e) => setSelectedTag(e.target.value)}
                      sx={{
                        fontWeight: 600,
                        fontSize: 19,
                      }}
                    >
                      {" "}
                      Amenities
                      {tags.residential.amenities.map((tag) => (
                        <FormControlLabel
                          key={tag}
                          value={tag}
                          control={<Radio />}
                          label={tag}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                ) : (
                  <div>
                    <RadioGroup
                      value={selectedTag}
                      onChange={(e) => setSelectedTag(e.target.value)}
                      sx={{
                        fontWeight: 600,
                        fontSize: 19,
                      }}
                    >
                      {" "}
                      Exterior
                      {tags.commercial.exterior.map((tag) => (
                        <FormControlLabel
                          key={tag}
                          value={tag}
                          control={<Radio />}
                          label={tag}
                        />
                      ))}
                    </RadioGroup>
                    <RadioGroup
                      value={selectedTag}
                      onChange={(e) => setSelectedTag(e.target.value)}
                      sx={{
                        fontWeight: 600,
                        fontSize: 19,
                      }}
                    >
                      {" "}
                      Interior
                      {tags.commercial.interior.map((tag) => (
                        <FormControlLabel
                          key={tag}
                          value={tag}
                          control={<Radio />}
                          label={tag}
                        />
                      ))}
                    </RadioGroup>
                    <RadioGroup
                      value={selectedTag}
                      onChange={(e) => setSelectedTag(e.target.value)}
                      sx={{
                        fontWeight: 600,
                        fontSize: 19,
                      }}
                    >
                      {" "}
                      Amenities
                      {tags.commercial.amenities.map((tag) => (
                        <FormControlLabel
                          key={tag}
                          value={tag}
                          control={<Radio />}
                          label={tag}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                )}
              </FormControl>
              <Button
                onClick={saveImageTag}
                variant="contained"
                sx={{
                  margin: "4px 10px 4px 0",
                  backgroundColor: "#7DAE3F",
                  ":hover": { backgroundColor: "#7DAE3F" },
                }}
              >
                Save
              </Button>
              <Button
                sx={{ margin: "4px 10px 4px 0" }}
                onClick={() => setModalVisible(false)}
                color="error"
              >
                Close
              </Button>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default ImageUploader;
