import React, { useEffect, useState } from "react";
import axiosInstance from "../../Interceptor/axiosInstance";
import { Skeleton } from "@mui/material";

const HelpReqCount = () => {
  const [count, setCount] = useState("");
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    async function help() {
      try {
        const response = await axiosInstance.get(
          "/api/admin/help/status/requestCount"
        );

        setCount(response.data);
      } catch (e) {
      } finally {
        setLoading(false); // Set loading to false after the request is done
      }
    }
    help();
  }, []);

  return (
    <div className="bg-white shadow-md p-8 rounded-[15px] h-[320px]">
      {loading ? ( // Conditional rendering based on loading state
        <Skeleton className="h-64" />
      ) : (
        <div className="flex flex-col gap-3 items-center justify-center">
          <p className="font-semibold uppercase text-xl">Help Request</p>
          <div className="bg-gray-200 w-[90%] p-6 rounded-[15px] text-center">
            <h3 className="text-[18px] uppercase">CompleteD</h3>
            <p className="text-3xl">{count.Complete}</p>
          </div>
          <div className="bg-gray-200 w-[90%] p-6 rounded-[15px] text-center">
            <h3 className="text-[18px] uppercase">Pending</h3>
            <p className="text-3xl">{count.Pending}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpReqCount;
