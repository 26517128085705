import React, { useContext } from "react";
import { VattaraPropsContext } from "../../../context/VattaraPropsContext";
import featuredMark from "../../../assets/featuredMark.png";
import { FaCircleExclamation } from "react-icons/fa6";
import dummyImg from "../../../assets/dummy.webp";

const VerifiedProperties = () => {
  const { originalList, viewTab, setViewTab } = useContext(VattaraPropsContext);

  

  const featuredProperties =
    originalList &&
    originalList.filter((prop) => prop?.home_screen_post === true);

  return (
    <>
      <div className="flex gap-4 text-xs font-bold">
        <button
          onClick={() => {
            setViewTab("Verified Properties");
          }}
          className={`${
            viewTab === "Verified Properties"
              ? "border-[0.3px] border-[#02a7e4] bg-[#02a7e4] text-white"
              : "bg-white text-black"
          } shadow-md rounded-lg px-6 py-[6px]`}
        >
          Verified Properties
        </button>
        <button
          onClick={() => {
            setViewTab("Vattara Properties");
          }}
          className={`${
            viewTab === "Vattara Properties"
              ? "border-[0.3px] border-[#02a7e4] bg-[#02a7e4] text-white"
              : "bg-white text-black"
          } shadow-md rounded-lg px-6 py-[6px]`}
        >
          Vattara Properties
        </button>
      </div>
      {viewTab === "Verified Properties" ? (
        <div className="bg-[#F6F8F9] rounded-[6px] border-[1px] border-[#E5E9EB] shadow-sm p-3 w-fit absolute right-8 top-16">
          <div className="flex items-center gap-4">
            <FaCircleExclamation className="text-[#B0BABF] text-lg" />
            <span className="font-Inter font-semibold flex text-[#252C32] text-sm">
              Please note
            </span>
          </div>
          <span className="font-Inter text-[#5B6871] text-xs ml-[34px]">
            Select up to 8 properties to feature on the homepage.
          </span>
        </div>
      ) : null}

      {viewTab === "Verified Properties" ? (
        <div className="mt-4 mb-8">
          <h4 className="text-[#7B7D8C] font-Inter font-bold text-[13px]">
            Vattara Verified Properties
          </h4>
          <div className="hide-scrollbar mt-4 flex items-center gap-5 pt-2 overflow-x-auto">
            {featuredProperties?.map((prop, i) => (
              <div className="w-fit relative" key={i}>
                <img
                  src={featuredMark}
                  alt="Featured Mark"
                  className="absolute -top-2 -right-2 z-10"
                />
                <div className="relative w-[170px]">
                  <img
                    src={prop?.images[0]?.uri || dummyImg}
                    alt="Property"
                    className="w-full h-[130px] object-cover"
                  />
                  <div className="text-white absolute bottom-1 px-2">
                    <p className="font-bold text-sm">{prop?.building_name}</p>
                    {/* <p className="font-medium text-xs">{prop?.city}</p> */}
                  </div>
                </div>
                <div className="p-2">
                  <p className="font-bold text-[#18181B] text-sm">
                    {prop?.monthly_rent
                      ? `${prop?.monthly_rent} / month`
                      : `${prop?.price}`}
                  </p>
                  <p className="font-medium text-[#71717A] text-[13px]">
                    {`${prop?.built_up_area} Sq.ft`}
                  </p>
                  <p className="font-medium text-[#71717A] text-[13px]">
                    {`${prop?.propertyID}`}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default VerifiedProperties;
