import { AdProvider } from "../../context/AdContext";
import ManageAdCarousel from "./components/ManageAdCarousel";

const AdPosting = () => {
  return (
    <AdProvider>
      <div className="py-4">
        <ManageAdCarousel />
      </div>
    </AdProvider>
  );
};

export default AdPosting;
