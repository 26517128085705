const MemberComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.6666 25.3333C30.6666 25.687 30.5261 26.0261 30.2761 26.2761C30.026 26.5262 29.6869 26.6667 29.3333 26.6667H13.3333C12.9796 26.6667 12.6405 26.5262 12.3904 26.2761C12.1404 26.0261 11.9999 25.687 11.9999 25.3333C11.9999 23.2116 12.8428 21.1768 14.3431 19.6765C15.8434 18.1762 17.8782 17.3333 19.9999 17.3333H22.6666C24.7883 17.3333 26.8232 18.1762 28.3234 19.6765C29.8237 21.1768 30.6666 23.2116 30.6666 25.3333ZM21.3333 5.33333C20.2784 5.33333 19.2473 5.64612 18.3702 6.23216C17.4931 6.81819 16.8096 7.65114 16.4059 8.62568C16.0022 9.60022 15.8966 10.6726 16.1024 11.7071C16.3082 12.7417 16.8161 13.692 17.562 14.4379C18.3079 15.1838 19.2582 15.6917 20.2928 15.8975C21.3273 16.1033 22.3997 15.9977 23.3742 15.594C24.3488 15.1904 25.1817 14.5068 25.7678 13.6297C26.3538 12.7526 26.6666 11.7215 26.6666 10.6667C26.6666 9.25217 26.1047 7.89562 25.1045 6.89543C24.1043 5.89523 22.7477 5.33333 21.3333 5.33333ZM9.33325 5.33333C8.27842 5.33333 7.24727 5.64612 6.37021 6.23216C5.49315 6.81819 4.80956 7.65114 4.40589 8.62568C4.00223 9.60022 3.89661 10.6726 4.1024 11.7071C4.30819 12.7417 4.81614 13.692 5.56202 14.4379C6.3079 15.1838 7.25821 15.6917 8.29277 15.8975C9.32734 16.1033 10.3997 15.9977 11.3742 15.594C12.3488 15.1904 13.1817 14.5068 13.7678 13.6297C14.3538 12.7526 14.6666 11.7215 14.6666 10.6667C14.6666 9.25217 14.1047 7.89562 13.1045 6.89543C12.1043 5.89523 10.7477 5.33333 9.33325 5.33333ZM9.33325 25.3333C9.33127 23.9328 9.60719 22.5458 10.145 21.2527C10.6829 19.9596 11.472 18.786 12.4666 17.8C11.6526 17.4927 10.79 17.3346 9.91992 17.3333H8.74659C6.78153 17.3369 4.89797 18.119 3.50846 19.5085C2.11895 20.898 1.33678 22.7816 1.33325 24.7467V25.3333C1.33325 25.687 1.47373 26.0261 1.72378 26.2761C1.97382 26.5262 2.31296 26.6667 2.66659 26.6667H9.57325C9.41802 26.2391 9.33686 25.7882 9.33325 25.3333Z"
      fill="#404040"
    />
  </svg>
);
export default MemberComponent;
