const RoleEditComponent = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={19} cy={19} r={19} fill="#D9D9D9" />
    <g clipPath="url(#clip0_2253_3085)">
      <path
        d="M26.0781 21.1563C25.6732 21.1563 25.2797 21.2058 24.903 21.2984L16.7017 13.0972C16.7943 12.7205 16.8438 12.327 16.8438 11.9221C16.8438 9.20821 14.6358 7.00021 11.9219 7.00021C11.3283 7.00021 10.747 7.10568 10.1938 7.31367C9.97014 7.39776 9.80453 7.58971 9.75405 7.82324C9.70356 8.05678 9.77519 8.30001 9.94412 8.46895L11.3591 9.88392L11.1631 11.1631L9.8838 11.3592L8.46883 9.9442C8.29989 9.77526 8.05656 9.70378 7.82313 9.75412C7.58955 9.80456 7.39759 9.97021 7.3135 10.1939C7.10547 10.747 7 11.3285 7 11.9221C7 14.6361 9.20795 16.844 11.9219 16.844C12.3268 16.844 12.7203 16.7945 13.0971 16.7019L21.2983 24.903C21.2057 25.2798 21.1562 25.6733 21.1562 26.0782C21.1562 28.7921 23.3642 31.0001 26.0781 31.0001C26.6717 31.0001 27.2531 30.8946 27.8063 30.6866C28.03 30.6025 28.1956 30.4105 28.246 30.177C28.2965 29.9434 28.2249 29.7002 28.0559 29.5313L26.6409 28.1163L26.8369 26.8371L28.1162 26.6411L29.5312 28.0561C29.7001 28.225 29.9433 28.2967 30.1769 28.2462C30.4105 28.1957 30.6024 28.0301 30.6865 27.8064C30.8945 27.2533 31 26.6719 31 26.0782C31 23.3643 28.792 21.1563 26.0781 21.1563Z"
        fill="#8E8E8E"
      />
      <path
        d="M28.805 12.7995L30.794 10.8105C30.9259 10.6787 31 10.4998 31 10.3134C31 10.1268 30.9259 9.94802 30.794 9.81621L28.1838 7.20597C27.9092 6.93142 27.464 6.93142 27.1894 7.20597L25.2004 9.19492L28.805 12.7995Z"
        fill="#8E8E8E"
      />
      <path
        d="M8.8896 25.822L7.05876 30.0155C6.94302 30.2806 7.00143 30.5895 7.20599 30.794C7.34062 30.9286 7.52048 30.9999 7.70334 30.9999C7.79821 30.9999 7.89393 30.9808 7.98454 30.9412L12.178 29.1103L8.8896 25.822Z"
        fill="#8E8E8E"
      />
      <path
        d="M19.9924 14.4019L24.2035 10.1908L27.8076 13.7948L23.5965 18.006L19.9924 14.4019Z"
        fill="#8E8E8E"
      />
      <path
        d="M9.72388 24.6683L14.3983 19.9939L18.0024 23.5979L13.3279 28.2724L9.72388 24.6683Z"
        fill="#8E8E8E"
      />
    </g>
    <defs>
      <clipPath id="clip0_2253_3085">
        <rect width={24} height={24} fill="white" transform="translate(7 7)" />
      </clipPath>
    </defs>
  </svg>
);
export default RoleEditComponent;
