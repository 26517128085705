
const KingComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.68 4.62782L16.3466 0.0624883C16.1226 -0.0201784 15.876 -0.0201784 15.652 0.0624883L3.31996 4.62782C2.92663 4.77182 2.66663 5.14649 2.66663 5.56515V16.2332C2.66663 28.3705 15.6133 31.9318 15.744 31.9665C15.828 31.9878 15.9146 31.9998 16 31.9998C16.0853 31.9998 16.172 31.9878 16.256 31.9665C16.3866 31.9318 29.3333 28.3692 29.3333 16.2332V5.56515C29.3333 5.14649 29.0733 4.77182 28.68 4.62782ZM25.3186 12.0585L23.8693 20.5025C23.7866 20.9825 23.3706 21.3332 22.884 21.3332H9.11596C8.62929 21.3332 8.21329 20.9825 8.13063 20.5025L6.68129 12.0585C6.61729 11.6892 6.76663 11.3145 7.06663 11.0892C7.36663 10.8638 7.76796 10.8265 8.10529 10.9905L11.964 12.8692L15.1266 7.17982C15.4786 6.54382 16.5226 6.54382 16.8746 7.17982L20.0373 12.8692L23.896 10.9905C24.2333 10.8265 24.6333 10.8638 24.9346 11.0892C25.2333 11.3145 25.3826 11.6892 25.3186 12.0585Z"
      fill="#404040"
    />
  </svg>
);
export default KingComponent;
