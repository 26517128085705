import { createContext, useState } from "react";

const FormContext = createContext();

const FormProvider = ({ children }) => {
  const [submittedData, setSubmittedData] = useState(null);
  const [generatedParagraph, setGeneratedParagraph] = useState("");
  const [selectedValues, setSelectedValues] = useState({});
  const [textFieldValues, setTextFieldValues] = useState({});
  const [customValues, setCustomValues] = useState({});
  const [selectedFloor, setSelectedFloor] = useState([]);
  const [totalFloors, setTotalFloors] = useState("");
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [pricePerSqFt, setPricePerSqFt] = useState("");
  const [builtUpArea, setBuiltUpArea] = useState("");
  const [plotFieldValue, setPlotFieldValue] = useState("");
  const [unit, setUnit] = useState("Sq.ft");
  const [totalPrice, setTotalPrice] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [selectedFurnishings, setSelectedFurnishings] = useState("");
  const [propertyAvailableFor, setPropertyAvailableFor] = useState("");
  const [propertySellFor, setPropertySellFor] = useState("");
  const [propertycommericalrentFor, setPropertycommericalrentFor] =
    useState("");
  const [propertycommericalrentStatusFor, setPropertycommericalrentStatusFor] =
    useState("");
    const [availableFrom, setAvailableFrom] = useState("");

  const [
    propertycommericalrentretailshowStatusFor,
    setPropertycommericalrentretailshowStatusFor,
  ] = useState("");
  const [propertySellcomInspFor, setPropertySellcomInspFor] = useState("");
  const [propertySellcomWarFor, setPropertySellcomWarFor] = useState("");
  const [
    propertySellcomRetaiAndShowroomFor,
    setPropertySellcomRetaiAndShowroomFor,
  ] = useState("");
  const [propertySellcomOffspaceFor, setPropertySellcomOffspaceFor] =
    useState("");
  const [securitydeposit, setSecurityDeposit] = useState("");
  const [taggedImages, setTaggedImages] = useState([]);
  const [selectedIds, setSelectedIds] = useState({});
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
   const [subLocality, setSubLocality] = useState("");


  const [showDetails, setShowDetails] = useState({
    ResidentialDetails: false,
    ResidentialSellPlot: false,
    Residentialsell: false,
    Residentialrent: false,
    CommercialRentOffice: false,
    CommercialRentRetailnShow: false,
    CommercialRentWareh: false,
    CommercialIndSpace: false,
    CommercialSellOff: false,
    CommercialSellRetailnShow: false,
    CommercialSellWare: false,
    CommercialSellIndSp: false,
  });
  const resetShowDetails = () => {
    setShowDetails({
      ResidentialDetails: false,
      Residentialsell: false,
      Residentialrent: false,
      ResidentialSellPlot: false,
      CommercialRentOffice: false,
      CommercialRentRetailnShow: false,
      CommercialRentWareh: false,
      CommercialIndSpace: false,
      CommercialSellOff: false,
      CommercialSellRetailnShow: false,
      CommercialSellWare: false,
      CommercialSellIndSp: false,
    });
  };

  const handleUnitChange = (e) => {
    setUnit(e.target.value);
  };

  const resetState = () => {
    setShowPreview(false); // Reset showPreview
    setSelectedValues({}); // Reset selectedValues
    setTextFieldValues({}); // Reset textFieldValues
    setGeneratedParagraph(""); // Reset generatedParagraph
    setAvailableFrom({});

    setCustomValues({}); // Reset customValues
    setSelectedFloor([]); // Reset selectedFloor
    setTotalFloors(""); // Reset totalFloors
    setPricePerSqFt(""); // Reset pricePerSqFt
    setBuiltUpArea(""); // Reset builtUpArea
    setTotalPrice(""); // Reset totalPrice
    setSelectedAmenities({}); // Reset selectedAmenities
    setSelectedIds({});
    setSelectedDropdownValue("");
    setTaggedImages([]);
    setPropertyAvailableFor("");
    setPropertySellFor("");
    setPropertycommericalrentFor("");
    setPropertycommericalrentStatusFor("");
    setPropertycommericalrentretailshowStatusFor("");
    setPropertySellcomInspFor("");
    setPropertySellcomWarFor("");
    setPropertySellcomRetaiAndShowroomFor("");
    setPropertySellcomOffspaceFor("");
    setSecurityDeposit("");
    setSubmittedData("");
  };

  return (
    <FormContext.Provider
      value={{
        submittedData,
        setSubmittedData, 
        generatedParagraph,
        setGeneratedParagraph,
        selectedValues,
        setSelectedValues,
        textFieldValues,
        setTextFieldValues,
        customValues,
        setCustomValues,
        selectedFloor,
        setSelectedFloor,
        totalFloors,
        setTotalFloors,
        selectedAmenities,
        setSelectedAmenities,
        pricePerSqFt,
        setPricePerSqFt,
        builtUpArea,
        setBuiltUpArea,
        totalPrice,
        setTotalPrice,
        showPreview,
        setShowPreview,
        resetState,
        selectedFurnishings,
        setSelectedFurnishings,
        propertyAvailableFor,
        setPropertyAvailableFor,
        propertySellFor,
        setPropertySellFor,
        availableFrom,
        setAvailableFrom,
        propertycommericalrentFor,
        setPropertycommericalrentFor,
        propertycommericalrentStatusFor,
        setPropertycommericalrentStatusFor,
        propertycommericalrentretailshowStatusFor,
        setPropertycommericalrentretailshowStatusFor,
        propertySellcomInspFor,
        setPropertySellcomInspFor,
        propertySellcomWarFor,
        setPropertySellcomWarFor,
        propertySellcomRetaiAndShowroomFor,
        setPropertySellcomRetaiAndShowroomFor,
        propertySellcomOffspaceFor,
        setPropertySellcomOffspaceFor,
        securitydeposit,
        setSecurityDeposit,
        taggedImages,
        setTaggedImages,
        selectedIds,
        setSelectedIds,
        selectedDropdownValue,
        setSelectedDropdownValue,
        showDetails,
        setShowDetails,
        resetShowDetails,
        unit,
        handleUnitChange,
        plotFieldValue,
        setPlotFieldValue,
        subLocality,
        setSubLocality,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export { FormContext, FormProvider };

// const formatPriceToIndianLocale = (price : string) => {
//     if (price === undefined || price === null) {
//       return "";
//     }

//     // Convert to string and reverse for easier processing
//     let str = price.toString().split("").reverse().join("");
//     let formattedStr = "";

//     // Add commas every 2 digits for the first segment (hundreds, thousands)
//     for (let i = 0; i < str.length; i++) {
//       if (i > 2 && (i - 2) % 2 === 1) {
//         formattedStr += ",";
//       }
//       formattedStr += str[i];
//     }

//     // Reverse back to the original order and return
//     return ₹ ${formattedStr.split("").reverse().join("")};
//   };