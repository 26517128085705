import React from "react";
import PropertyListing from "./components/PropertyListing";
import PropertyDetailView from "./components/PropertyDetailView";
import { PostPropertyProvider } from "../../context/postPropertyContext";
import Filters from "./components/Filters";

const PostApproval = () => {
  return (
    <PostPropertyProvider>
      <div className="">
        <h3 className="text-xl py-4 font-bold border-b-[1px] border-b-[#404040]">
          Post Property Approval
        </h3>
        <div className="flex gap-2">
          <div className="w-[70%]">
            <Filters />
            <div className="hide-scrollbar h-[calc(100vh-64px)] overflow-auto">
              <PropertyListing />
            </div>
          </div>
          <div className="w-[30%] hide-scrollbar mt-16 border-l-[1px] border-l-[#404040] h-[calc(100vh-64px)] overflow-auto">
            <PropertyDetailView />
          </div>
        </div>
      </div>
    </PostPropertyProvider>
  );
};

export default PostApproval;
