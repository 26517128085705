import React, { useContext, useEffect, useState } from "react";
import Filters from "./Filters";
import { VattaraPropsContext } from "../../../context/VattaraPropsContext";
import { IoIosMore } from "react-icons/io";
import Button from "@mui/material/Button";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Details from "../../PostApproval/components/Details";
import axiosInstance from "../../../Interceptor/axiosInstance";
import { useSelector } from "react-redux";
import { selectUserPermissions } from "../../../store/authSlice";
import Shimmer from "../../Shimmer";
import dummy from "../../../assets/dummy.webp";

const VattaraProperties = () => {
  const [open, setOpen] = useState(false);
  const {
    properties,
    setAsMenu,
    setSetAsMenu,
    fetchData,
    viewTab,
    setSelectedProperty,
    selectedProperty,
    propCount,
    shimmerState,
  } = useContext(VattaraPropsContext);
  const [editAccess, setEditAccess] = useState();
  const [deleteAccess, setDeleteAccess] = useState();

  const expectedPermission = "Vattara Property Management";
  const userRolePermission = useSelector(selectUserPermissions);

  useEffect(() => {
    const hasEditAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.edit === "true"
    );
    setEditAccess(hasEditAccess);

    const hasDeleteAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.delete === "true"
    );
    setDeleteAccess(hasDeleteAccess);
  }, [userRolePermission]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const residentialSellVacantLand = [
    // Residential
    // Sell
    // Plot / Land
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        { label: "Looking to", value: selectedProperty?.looking_to },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.landmark_street },
        { label: "Landmark/Street", value: selectedProperty?.city },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Plot Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        { label: "Length", value: selectedProperty?.length },
        { label: "Width", value: selectedProperty?.width },
        { label: "Property Status", value: selectedProperty?.approval_status },
        { label: "Patta Available", value: selectedProperty?.patta_available },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },
    {
      title: "Price Details",
      details: [
        { label: "Plot Price", value: selectedProperty?.price },
        { label: "Price/Sq.ft", value: selectedProperty?.PriceperSqft },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        {
          label: "Property Description",
          value: selectedProperty?.additional_details?.property_description,
        },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const residentialSell = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        { label: "Looking to", value: selectedProperty?.looking_to },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.landmark_street },
        { label: "Landmark/Street", value: selectedProperty?.city },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        { label: "BHK", value: selectedProperty?.bhk },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        { label: "Furnish Type", value: selectedProperty?.furnish_type },
        { label: "Furnishings", value: selectedProperty?.flat_furnishing },
        { label: "Amenities", value: selectedProperty?.society_amenities },
      ],
    },
    {
      title: "Price Details",
      details: [
        { label: "Price", value: selectedProperty?.price },
        { label: "Price/Sq.ft", value: selectedProperty?.PriceperSqft },
        {
          label: "Construction Status",
          value: selectedProperty?.possession_status,
        },
        { label: "Negotiable", value: selectedProperty?.negotiable },
      ],
    },
    {
      title: "Additional Details",
      details: [
        {
          label: "Bathroom",
          value: selectedProperty?.additional_details?.bathroom,
        },
        {
          label: "Balcony",
          value: selectedProperty?.additional_details?.balcony,
        },
        {
          label: "Parking",
          value: selectedProperty?.additional_details?.parking,
        },
        {
          label: "Carpet Area",
          value: selectedProperty?.additional_details?.carpet_area + " Sq.ft",
        },
        {
          label: "Facing",
          value: selectedProperty?.additional_details?.facing,
        },
        {
          label: "Servant Room",
          value: selectedProperty?.additional_details?.servant_room,
        },
        {
          label: "Eligible For Home Loan?",
          value: selectedProperty?.additional_details?.eligible_for_loan,
        },
        {
          label: "Property Description",
          value: selectedProperty?.additional_details?.property_description,
        },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const residentialRent = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        {
          label: "Looking to",
          value: selectedProperty?.property_available_for,
        },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.landmark_street },
        { label: "Landmark/Street", value: selectedProperty?.city },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        { label: "BHK", value: selectedProperty?.bhk },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        { label: "Furnish Type", value: selectedProperty?.furnish_type },
        { label: "Furnishings", value: selectedProperty?.flat_furnishing },
        { label: "Amenities", value: selectedProperty?.society_amenities },
      ],
    },
    {
      title: "Price Details",
      details: [
        ...(selectedProperty?.property_available_for === "Lease"
          ? [
            {
              label: "Lease Amount",
              value: selectedProperty?.lease_amount,
            },
            {
              label: "Available From",
              value: selectedProperty?.available_from,
            },
            { label: "Negotiable", value: selectedProperty?.negotiable },
          ]
          : [
            { label: "Monthly Rent", value: selectedProperty?.monthly_rent },
            {
              label: "Available From",
              value: selectedProperty?.available_from,
            },
            {
              label: "Security Deposit",
              value: selectedProperty?.security_deposit,
            },
            { label: "Negotiable", value: selectedProperty?.negotiable },
          ]),
      ],
    },
    {
      title: "Additional Details",
      details: [
        {
          label: "Bathroom",
          value: selectedProperty?.additional_details?.bathroom,
        },
        {
          label: "Balcony",
          value: selectedProperty?.additional_details?.balcony,
        },
        {
          label: "Parking",
          value: selectedProperty?.additional_details?.parking,
        },
        // { label: "Preferred Tenant Type", value: selectedProperty?. },
        {
          label: "Carpet Area",
          value: selectedProperty?.additional_details?.carpet_area + " Sq.ft",
        },
        // { label: "Maintenance Charges", value: selectedProperty?. },
        {
          label: "Facing",
          value: selectedProperty?.additional_details?.facing,
        },
        {
          label: "Servant Room",
          value: selectedProperty?.additional_details?.servant_room,
        },
        {
          label: "Property Description",
          value: selectedProperty?.additional_details?.property_description,
        },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const CommercialSellOfficeSpace = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        {
          label: "Looking to",
          value: selectedProperty?.property_available_for,
        },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
        // { label: "map", value: selectedProperty?. },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        {
          label: "Property Condition",
          value: selectedProperty?.property_condition,
        },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        {
          label: "Carpet Area",
          value: selectedProperty?.carpet_area + " Sq.ft",
        },
        { label: "Total Floors", value: selectedProperty?.total_floors },
        { label: "Your Floors", value: selectedProperty?.select_your_floor },
        { label: "Lift", value: selectedProperty?.lifts },
        { label: "Parking", value: selectedProperty?.parking },
        { label: "Washroom", value: selectedProperty?.washroom },
        { label: "Meeting Room", value: selectedProperty?.meeting_room },
        {
          label: "Min. Number of Seats",
          value: selectedProperty?.min_num_seat,
        },
        {
          label: "Max. Number of Seats",
          value: selectedProperty?.max_num_seat,
        },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },

    {
      title: "Price Details",
      details: [
        { label: "Price", value: selectedProperty?.price },
        { label: "Price/Sq.ft", value: selectedProperty?.PriceperSqft },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },

    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const CommercialSellWareHouse = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        { label: "Looking to", value: selectedProperty?.looking_to },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },

    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        {
          label: "Carpet Area",
          value: selectedProperty?.carpet_area + " Sq.ft",
        },
        { label: "Parking", value: selectedProperty?.parking },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },

    {
      title: "Price Details",
      details: [
        { label: "Price", value: selectedProperty?.price },
        { label: "Price/Sq.ft", value: selectedProperty?.PriceperSqft },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },

    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const CommercialSellIndustrialSpace = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        { label: "Looking to", value: selectedProperty?.looking_to },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
        // { label: "map", value: selectedProperty?. },
      ],
    },

    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        {
          label: "Plot Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },

    {
      title: "Price Details",
      details: [
        { label: "Price", value: selectedProperty?.price },
        { label: "Price/Sq.ft", value: selectedProperty?.PriceperSqft },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },

    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const CommercialSellRetailShopShowroom = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        { label: "Looking to", value: selectedProperty?.looking_to },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
        // { label: "map", value: selectedProperty?. },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        { label: "Suitable For", value: selectedProperty?.Suitable_for },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        // { label: "Ceiling height", value: selectedProperty?. },
        {
          label: "Total Floors",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        { label: "Your Floors", value: selectedProperty?.select_your_floor },
        { label: "Parking", value: selectedProperty?.parking },
        { label: "Washroom", value: selectedProperty?.washroom },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },
    {
      title: "Price Details",
      details: [
        { label: "Price", value: selectedProperty?.price },
        { label: "Price/Sq.ft", value: selectedProperty?.PriceperSqft },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },
    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const commercial_rent_officeSpace = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        {
          label: "Looking to",
          value: selectedProperty?.property_available_for,
        },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        {
          label: "Property Condition",
          value: selectedProperty?.property_condition,
        },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        {
          label: "Carpet Area",
          value: selectedProperty?.carpet_area + " Sq.ft",
        },
        { label: "Total Floors", value: selectedProperty?.total_floors },
        { label: "Your Floors", value: selectedProperty?.select_your_floor },
        { label: "Lift", value: selectedProperty?.lifts },
        { label: "Parking", value: selectedProperty?.parking },
        { label: "Washroom", value: selectedProperty?.washroom },
        { label: "Meeting Room", value: selectedProperty?.meeting_room },
        {
          label: "Min. Number of Seats",
          value: selectedProperty?.min_num_seat,
        },
        {
          label: "Max. Number of Seats",
          value: selectedProperty?.max_num_seat,
        },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },

    {
      title: "Price Details",
      details: [
        selectedProperty?.property_available_for === "Rent"
          ? { label: "Expected Rent", value: selectedProperty?.monthly_rent }
          : { label: "Lease Amount", value: selectedProperty?.lease_amount },
        // lablel:"Lease Amount" for lease property
        { label: "Negotiable", value: selectedProperty?.negotiable },
        selectedProperty?.property_available_for === "Rent" && {
          label: "Security Deposit",
          value: selectedProperty?.security_deposit,
        },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },

    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const commercial_rent_warehouse = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        {
          label: "Looking to",
          value: selectedProperty?.property_available_for,
        },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },

    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        {
          label: "Carpet Area",
          value: selectedProperty?.carpet_area + " Sq.ft",
        },
        { label: "Parking", value: selectedProperty?.parking },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },
    {
      title: "Price Details",
      details: [
        selectedProperty?.property_available_for === "Rent"
          ? { label: "Expected Rent", value: selectedProperty?.monthly_rent }
          : { label: "Lease Amount", value: selectedProperty?.lease_amount },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        selectedProperty?.property_available_for === "Rent" && {
          label: "Security Deposit",
          value: selectedProperty?.security_deposit,
        },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },
    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const commercial_rent_industrialSpace = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        {
          label: "Looking to",
          value: selectedProperty?.property_available_for,
        },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        {
          label: "Plot Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },
    {
      title: "Price Details",
      details: [
        selectedProperty?.property_available_for === "Rent"
          ? { label: "Expected Rent", value: selectedProperty?.monthly_rent }
          : { label: "Lease Amount", value: selectedProperty?.lease_amount },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        selectedProperty?.property_available_for === "Rent" && {
          label: "Security Deposit",
          value: selectedProperty?.security_deposit,
        },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },
    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const commercial_rent_retailShop = [
    {
      title: "Basic Details",
      details: [
        { label: "Property Type", value: selectedProperty?.res_or_com },
        {
          label: "Looking to",
          value: selectedProperty?.property_available_for,
        },
        { label: "Property Subtype", value: selectedProperty?.property_type },
        { label: "City", value: selectedProperty?.district },
        { label: "Locality", value: selectedProperty?.city },
        { label: "Landmark/Street", value: selectedProperty?.landmark_street },
      ],
    },
    {
      title: "Property Details",
      details: [
        { label: "Building/Project", value: selectedProperty?.building_name },
        {
          label: "Possession Status",
          value: selectedProperty?.possession_status,
        },
        { label: "Suitable For", value: selectedProperty?.Suitable_for },
        {
          label: "Built Up Area",
          value: selectedProperty?.built_up_area + " Sq.ft",
        },
        {
          label: "Carpet Area",
          value: selectedProperty?.carpet_area + " Sq.ft",
        },
        { label: "Total Floors", value: selectedProperty?.total_floors },
        { label: "Your Floors", value: selectedProperty?.select_your_floor },
        { label: "Parking", value: selectedProperty?.parking },
        { label: "Washroom", value: selectedProperty?.washroom },
        { label: "Facing", value: selectedProperty?.facing },
      ],
    },
    {
      title: "Price Details",
      details: [
        selectedProperty?.property_available_for === "Rent"
          ? { label: "Expected Rent", value: selectedProperty?.monthly_rent }
          : { label: "Lease Amount", value: selectedProperty?.lease_amount },
        { label: "Negotiable", value: selectedProperty?.negotiable },
        selectedProperty?.property_available_for === "Rent" && {
          label: "Security Deposit",
          value: selectedProperty?.security_deposite,
        },
        {
          label: "Property Description",
          value: selectedProperty?.property_description,
        },
      ],
    },
    {
      title: "Amenities",
      details: [
        { label: "Amenities", value: selectedProperty?.amenities?.amenities },
      ],
    },
    {
      title: "Photos",
      details: selectedProperty?.images,
    },
  ];

  const getDetails = () => {
    if (selectedProperty?.res_or_com === "Residential") {
      if (selectedProperty?.looking_to === "Sale") {
        if (selectedProperty?.property_type === "Plot / Land") {
          return residentialSellVacantLand;
        } else if (
          selectedProperty?.property_type === "Apartment" ||
          selectedProperty?.property_type === "Independent House" ||
          selectedProperty?.property_type === "Villa"
        ) {
          return residentialSell;
        }
      } else if (
        selectedProperty?.property_available_for === "Rent" ||
        selectedProperty?.property_available_for === "Lease"
      ) {
        return residentialRent;
      }
    } else if (selectedProperty?.res_or_com === "Commercial") {
      if (selectedProperty?.looking_to === "Sale") {
        if (selectedProperty?.property_type === "Office Space") {
          return CommercialSellOfficeSpace;
        } else if (selectedProperty?.property_type === "Industrial Space") {
          return CommercialSellIndustrialSpace;
        } else if (
          selectedProperty?.property_type === "Retail Shop" ||
          selectedProperty?.property_type === "Showroom"
        ) {
          return CommercialSellRetailShopShowroom;
        } else if (selectedProperty?.property_type === "Warehouse") {
          return CommercialSellWareHouse;
        }
      } else if (
        selectedProperty?.property_available_for === "Rent" ||
        selectedProperty?.property_available_for === "Lease"
      ) {
        if (selectedProperty?.property_type === "Office Space") {
          return commercial_rent_officeSpace;
        } else if (selectedProperty?.property_type === "Industrial Space") {
          return commercial_rent_industrialSpace;
        } else if (
          selectedProperty?.property_type === "Retail Shop" ||
          selectedProperty?.property_type === "Showroom"
        ) {
          return commercial_rent_retailShop;
        } else if (selectedProperty?.property_type === "Warehouse") {
          return commercial_rent_warehouse;
        }
      }
    }
    return [];
  };

  const details = getDetails();

  const handleSetAsFeaturedClick = async (property) => {
    const featuredProperties = properties.filter(
      (prop) => prop?.home_screen_post === true
    );

    if (!property?.home_screen_post && featuredProperties.length >= 8) {
      handleClick();
      return;
    }

    const body = {
      post_id: property?.post_id,
      property_type: property?.property_type,
      looking_to:
        property?.looking_to ||
        property?.property_available_for ||
        property?.rent_or_lease,
      home_screen_post: property?.home_screen_post === true ? false : true,
    };

    try {
      const response = await axiosInstance.post(
        "/api/admin/homescreen/post/update",
        body
      );

      fetchData();
    } catch (error) {
      console.error(error);
    }
    setSetAsMenu("");
  };

  const handleDeleteProperty = async (property) => {
    const response = await axiosInstance.delete(
      `/api/admin/vattara/PropertyDelete/${properties?.propertyID}`
    );

    fetchData();
  };

  async function handleFullDesc(id) {
    
    try {
      const response = await axiosInstance.get(`/api/admin/findId/propertyDetail/${id}`)
      
      setSelectedProperty( response.data.Data[0]);     
       setSetAsMenu("");
    } catch (e) {
      
    }
  }

  
  return (
    <div className="my-4">
      <h4 className="text-[#7B7D8C] font-Inter font-bold text-[13px]">
        Vattara Property List
      </h4>
      <Filters />
      {/* {shimmerState !== true && ( */}
      {shimmerState !== true ? (
        <h4 className="font-medium font-Inter text-[15px] pt-2 underline">
          {propCount} Properties Listed
        </h4>
      ) : null}

      {/* )} */}
      <div className="flex my-2">
        <div className="hide-scrollbar h-[calc(100vh-64px)] overflow-auto w-[70%] flex flex-col gap-4">
          {shimmerState === true ? (
            <Shimmer />
          ) : (
            properties.length > 0 &&
            properties?.map((property, i) => (
              <div
                key={i}
                className={`relative ${
                  property?.propertyID === selectedProperty?.propertyID
                    ? "bg-[#E4E0EE]"
                    : "bg-white"
                } p-4 w-[90%] border-[1px] border-[#E4E4E7] flex items-center justify-between gap-4`}
              >
                <div className="relative">
                  <span className="absolute bg-[#02a7e4] text-xs px-2 text-white rounded-r-[8px]">
                    {i + 1}
                  </span>
                  <img
                    src={property?.images[0]?.uri || dummy}
                    alt="Property"
                    className="w-[110px] h-[85px] object-cover"
                  />
                </div>
                <div className="flex flex-col gap-2 flex-1 max-w-[250px]">
                  <h3 className="text-[#18181B] text-xs font-bold">
                    {property?.building_name}
                  </h3>
                  <p className="text-[11px] font-medium text-[#71717A]">
                    {property?.city}
                  </p>
                </div>
                <div className="flex flex-col gap-2 flex-1 max-w-[100px]">
                  <h3 className="text-[#18181B] text-xs font-bold">
                    {property?.monthly_rent
                      ? `${property?.monthly_rent} / month`
                      : `${property?.price}`}
                  </h3>
                  <p className="text-[11px] font-medium text-[#71717A]">
                    {`${property?.built_up_area} Sq.ft`}
                  </p>
                </div>
                <span className="text-[#71717A] text-sm font-medium">
                  Verified
                </span>
                {viewTab === "Verified Properties" ? (
                  editAccess ? (
                    <IoIosMore
                      onClick={() =>
                        setAsMenu === ""
                          ? setSetAsMenu(property?.id)
                          : setSetAsMenu("")
                      }
                      className="text-[#71717A] text-xl cursor-pointer"
                    />
                  ) : null
                ) : viewTab === "Vattara Properties" ? (
                  <IoIosMore
                    onClick={() =>
                      setAsMenu === ""
                        ? setSetAsMenu(property?.id)
                        : setSetAsMenu("")
                    }
                    className="text-[#71717A] text-xl cursor-pointer"
                  />
                ) : null}

                {setAsMenu === property?.id ? (
                  <div className="bg-white rounded-[8px] shadow-md p-3 z-20 absolute top-[65px] -right-16 text-[13px] flex flex-col items-center gap-1">
                    {viewTab === "Verified Properties" ? (
                      <>
                        {editAccess ? (
                          <span
                            onClick={() => {
                              handleSetAsFeaturedClick(property);
                            }}
                            className="text-[#000000] hover:underline cursor-pointer"
                          >
                            {property?.home_screen_post === true
                              ? "Remove from Featured"
                              : "Set as Featured"}
                          </span>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <span
                          onClick={() => handleFullDesc(property.propertyID)}
                          className="text-[#000000] hover:underline cursor-pointer"
                        >
                          View Details
                        </span>
                        {deleteAccess ? (
                          <span
                            onClick={() => handleDeleteProperty(property)}
                            className="text-[#C91C1C] hover:underline cursor-pointer"
                          >
                            Delete
                          </span>
                        ) : null}
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            ))
          )}
        </div>
        {viewTab === "Vattara Properties" ? (
          <div className="hide-scrollbar h-[calc(100vh-64px)] overflow-auto w-[30%] bg-white shadow-md p-4 text-xs rounded-[8px]">
            <Details details={details} />
          </div>
        ) : null}
      </div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Featured properties limit reached (8)
        </Alert>
      </Snackbar>
    </div>
  );
};

export default VattaraProperties;
