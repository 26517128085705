import React, { useContext, useEffect, useState } from "react";
import { ServiceListingContext } from "../../../context/ServiceListingContext";
import { useSelector } from "react-redux";
import { selectEmpId, selectUserPermissions } from "../../../store/authSlice";
import userProfile from "../../../assets/userplaceholder.jpg";
import { CircularProgress } from "@mui/material";
import axiosInstance from "../../../Interceptor/axiosInstance";

const UserAndServiceDetail = () => {
  const { userAndServiceDetail, fetchData, setUserAndServiceDetail } =
    useContext(ServiceListingContext);
  const [loader, setLoader] = useState("");
  const [access, setAccess] = useState();

  const expectedPermission = "Services";
  const userRolePermission = useSelector(selectUserPermissions);

  useEffect(() => {
    const hasAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.edit === "true"
    );

    setAccess(hasAccess);
  }, [userRolePermission]);

  const details = userAndServiceDetail;

  const empId = useSelector(selectEmpId);

  const serviceCategories = [
    "Electrician",
    "Packers & Movers",
    "Construction Service",
    "Home Interior",
    "Painting",
    "Home Renovations",
  ];

  const [formData, setFormData] = useState({
    emp_id: "",
    user_name: "",
    mobile_no: "",
    service_category: "",
    district: "",
    business_profile: "",
    job_description: "",
    locality: "",
    business_name: "",
    user_id: "",
    id: null,
  });

  // Initialize formData with the details when the component mounts or details change
  useEffect(() => {
    if (userAndServiceDetail) {
      setFormData({
        emp_id: empId,
        user_name: userAndServiceDetail.user_name || "",
        mobile_no: userAndServiceDetail.mobile_no || "",
        service_category: userAndServiceDetail.service_category || "",
        district: userAndServiceDetail.district || "",
        business_profile: userAndServiceDetail.business_profile || "",
        job_description: userAndServiceDetail.job_description || "",
        locality: userAndServiceDetail.locality || "",
        business_name: userAndServiceDetail.business_name || "",
        user_id: userAndServiceDetail.user_id || "",
        id: userAndServiceDetail.id || null,
      });
    }
  }, [userAndServiceDetail]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  

  const handleVerifyAndReject = async (status) => {
    setLoader(status);
    try {
      const response = await axiosInstance.put(
        "/services/update/listApproval/status",
        {
          emp_id: empId,
          id: details?.id,
          list_approval: status,
        }
      );
      // const result = await response.json();
      // 
      setLoader("");
      setUserAndServiceDetail();
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async () => {
    setLoader("updating");
    try {
      const response = await axiosInstance.post(
        "/services/user/create",
        formData
      );
      // const result = await response.json();
      setLoader("");
      setUserAndServiceDetail();
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={`${
        details !== undefined ? "opacity-100" : "opacity-0"
      } transition-opacity duration-300 text-xs flex flex-col gap-4`}
    >
      <div className="bg-white p-4 rounded-[8px]">
        <h2 className="text-[#404040] text-sm font-bold">User Details</h2>
        <div className="w-full bg-[#8E8E8E] h-[0.5px] my-3"></div>
        <div className="flex justify-between items-start">
          <div className="flex items-center gap-2">
            <img
              className="w-[45px] h-[45px] rounded-full"
              src={
                details?.tbl_user_reg?.company_logo
                  ? details?.tbl_user_reg?.company_logo
                  : userProfile
              }
              alt="user-profile"
            />
            <div>
              <p className="font-semibold text-sm">
                {details?.tbl_user_reg?.name}
              </p>
              <p>{details?.tbl_user_reg?.category}</p>
              <p>{details?.tbl_user_reg?.company_name}</p>
            </div>
          </div>
          <div className="w-[0.3px] bg-[#8E8E8E] h-[45px]"></div>
          <div>
            <p>{details?.tbl_user_reg?.email}</p>
            <p className="font-semibold text-sm">
              {details?.tbl_user_reg?.mobile_no}
            </p>
          </div>
        </div>
        <p className="px-4 my-4">Submitted Date : {details?.createdAt}</p>
      </div>

      <div className="bg-white px-8 py-4 rounded-[8px] flex flex-col gap-2">
        <div className="my-3">
          <h2 className="text-[#8E8E8E] text-sm font-bold mb-2">
            Profile Information
          </h2>
          <p className="text-[#404040] mb-2 font-bold">Business Photo</p>
          <img
            className="rounded-full w-[65px] h-[65px] object-cover shadow-sm"
            src={
              details?.business_profile
                ? details?.business_profile
                : userProfile
            }
            alt="business-profile"
          />
          <div className="flex flex-col gap-3 my-4">
            <div>
              <p className="text-[#404040] font-bold">Name</p>
              <input
                onChange={handleChange}
                name="user_name"
                disabled={details?.list_approval !== "Verified"}
                value={formData?.user_name}
                className="border-[1px] border-[#D7D7D7] bg-white rounded-[30px] py-[6px] px-2 w-[77%] focus:outline-none"
                type="text"
              />
            </div>
            <div>
              <p className="text-[#404040] font-bold">Business Name</p>
              <input
                onChange={handleChange}
                name="business_name"
                disabled={details?.list_approval !== "Verified"}
                value={formData?.business_name}
                className="border-[1px] border-[#D7D7D7] bg-white rounded-[30px] py-[6px] px-2 w-[77%] focus:outline-none"
                type="text"
              />
            </div>
            <div>
              <p className="text-[#404040] font-bold">
                Contact Number/WhatsApp Number
              </p>
              <input
                onChange={handleChange}
                name="mobile_no"
                disabled={details?.list_approval !== "Verified"}
                value={formData?.mobile_no}
                className="border-[1px] border-[#D7D7D7] drop-shadow-md bg-white rounded-[30px] py-[6px] px-2 w-[77%] focus:outline-none"
                type="text"
              />
            </div>
          </div>
        </div>

        <div>
          <p className="text-[#8E8E8E] text-sm font-bold mb-2">
            Service Information
          </p>
          <div className="flex flex-col gap-5">
            <div className="flex flex-wrap gap-3">
              {serviceCategories.map((category, i) => (
                <div
                  className={`${
                    formData?.service_category
                      .toLowerCase()
                      .includes(category.toLowerCase())
                      ? "bg-[#02a7e4] text-white"
                      : "bg-white text-black"
                  } cursor-pointer border-[1px] border-[#D7D7D7] drop-shadow-md text-center py-[6px] px-2 font-medium rounded-[30px]`}
                  key={i}
                  onClick={
                    details?.list_approval === "Verified"
                      ? () =>
                          setFormData((prev) => ({
                            ...prev,
                            service_category: category,
                          }))
                      : null
                  }
                >
                  {category}
                </div>
              ))}
            </div>
            <div>
              <p className="text-[#404040] font-bold mb-1">Job Description</p>
              <textarea
                onChange={handleChange}
                name="job_description"
                disabled={details?.list_approval !== "Verified"}
                value={formData?.job_description}
                className="border-[1px] border-[#D7D7D7] w-full drop-shadow-md bg-white rounded-[8px] py-[6px] px-2 focus:outline-none"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="my-3">
          <p className="text-[#8E8E8E] text-sm font-bold mb-2">
            Location Information
          </p>
          <div className="flex flex-col gap-3 my-4">
            <div>
              <p className="text-[#404040] font-bold">District</p>
              <input
                onChange={handleChange}
                name="district"
                disabled={details?.list_approval !== "Verified"}
                value={formData?.district}
                className="border-[1px] border-[#D7D7D7] bg-white rounded-[30px] py-[6px] px-2 w-[77%] focus:outline-none"
                type="text"
              />
            </div>
            <div>
              <p className="text-[#404040] font-bold">Town/Locality</p>
              <input
                onChange={handleChange}
                name="locality"
                disabled={details?.list_approval !== "Verified"}
                value={formData?.locality}
                className="border-[1px] border-[#D7D7D7] bg-white rounded-[30px] py-[6px] px-2 w-[77%] focus:outline-none"
                type="text"
              />
            </div>
          </div>
        </div>

        {access ? (
          <div className="flex justify-between items-center">
            {userAndServiceDetail?.list_approval === "Rejected" ? null : (
              <div
                className={`${
                  loader === "Rejected" ? "px-12" : "px-8"
                } relative rounded-[8px] py-2 border-b-[0.3px] border-b-[#8E8E8E] bg-[#EEA5A5] font-bold`}
                onClick={() => handleVerifyAndReject("Rejected")}
              >
                <button>{loader === "Rejected" ? "" : "Reject"}</button>
                <div className="absolute inset-0 flex items-center justify-center">
                  {loader === "Rejected" ? (
                    <CircularProgress size={13} />
                  ) : null}
                </div>
              </div>
            )}

            {userAndServiceDetail?.list_approval !== "Verified" &&
            userAndServiceDetail?.list_approval !== "Rejected" ? (
              <div
                disabled
                className={`${
                  loader === "Verified" ? "px-12" : "px-8"
                } relative rounded-[8px] py-2 border-b-[0.3px] border-b-[#8E8E8E] bg-[#7DAE3F] font-bold text-white`}
                onClick={() => handleVerifyAndReject("Verified")}
              >
                <button>{loader === "Verified" ? "" : "Verify"}</button>
                <div className="absolute inset-0 flex items-center justify-center">
                  {loader === "Verified" ? (
                    <CircularProgress size={13} />
                  ) : null}
                </div>
              </div>
            ) : null}

            {userAndServiceDetail?.list_approval === "Verified" ? (
              <div
                className={`${
                  loader === "updating" ? "px-12" : "px-8"
                } relative rounded-[8px] py-2 border-b-[0.3px] border-b-[#8E8E8E] bg-[#7DAE3F] font-bold text-white`}
                onClick={handleUpdate}
              >
                <button>{loader === "updating" ? "" : "Update"}</button>
                <div className="absolute inset-0 flex items-center justify-center">
                  {loader === "updating" ? (
                    <CircularProgress size={13} />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UserAndServiceDetail;
