import { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import image from "../../../src/images/team-1.jpg";
import UploadComponent from "../../icons/Upload";
import ViewComponent from "../../icons/View";
import ReloadComponent from "../../icons/Reload";
import LogoComponent from "../../icons/Logo";
import CircleComponent from "../../icons/Circle";
import AWS from "aws-sdk";
import Resizer from "react-image-file-resizer";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { selectUserPermissions } from "../../store/authSlice";
import axiosInstance from "../../Interceptor/axiosInstance";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "#F5F4F8",
  },
  heading: {
    fontSize: "20px",
    fontFamily: "Roboto",
    fontWeight: "700",
    marginTop: "20px",
  },

  previewCard: {
    height: "800px",
    width: "360px",
    textAlign: "center",
    borderRadius: "20px",
    position: "relative",
    right: "10px",
  },
  previewCardMedia: {
    width: "100%",
    height: "500px",
    objectFit: "cover",
    borderRadius: "25px",
    marginTop: "39px",
  },
  actionButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
    gap: "5px",
  },
  previewText: {
    fontWeight: "800",
  },

  button: {
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: "700",
    borderRadius: "20px",
    width: "90px",
    position: "relative",
    height: "30px",
    right: "20px",
    top: "8px",
    backgroundColor: "#DFDFDF",
    color: "#000000",
  },

  vattara: {
    fontSize: "18px",
    fontFamily: "Roboto",
    fontWeight: "700",
    position: "relative",
    right: "8px",
    top: "4px",
  },

  previewscreen: {
    fontSize: "18px",
    fontFamily: "Roboto",
    fontWeight: "700",
    backgroundColor: "black",
    color: "white",
    padding: "6px",
  },

  text: {
    fontSize: "24px",
    fontFamily: "Roboto",
    fontWeight: "500",
    position: "relative",
    top: "50px",
    left: "20px",
  },

  span: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "24px",
    color: "#204D6C",
  },

  text1: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "13px",
    position: "relative",
    top: "60px",
    left: "20px",
    width: "250px",
  },

  line: {
    height: "100vh",
    position: "relative",
    right: "20px",
    top: "40px",
    color: "black",
  },

  discard: {
    padding: "1px",
    color: "#80848E",
    fontSize: "10px",
    height: "30px",
    position: "relative",
    right: "28px",
    top: "14px",
    zIndex: 10,
    fontFamily: "Roboto",
    fontWeight: "600",
  },

  save: {
    color: "white",
    fontSize: "10px",
    padding: "1px",
    height: "30px",
    backgroundColor: "#7DAE3F",
    position: "relative",
    right: "28px",
    top: "14px",
    zIndex: 10,
    fontFamily: "Roboto",
    fontWeight: "600",
  },
  simple: {
    padding: "2px",
    backgroundColor: "#FBB337",
    fontFamily: "Roboto",
    fontWeight: "700",
    width: "190px",
    borderRadius: "25px",
    fontSize: "16px",
    position: "relative",
    left: "10px",
  },
}));

AWS.config.update({
  region: "us-east-1",
  accessKeyId: "AKIAXKPUZW5AH27B7EGM",
  secretAccessKey: "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",
});

const S3 = new AWS.S3();

const InAppCustomization = () => {
  const [originalImages, setOriginalImages] = useState([]);
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [showOptions, setShowOptions] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [discardedImageUrl, setDiscardedImageUrl] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [access, setAccess] = useState();

  const expectedPermission = "In App Customization";
  const userRolePermission = useSelector(selectUserPermissions);

  useEffect(() => {
    const hasAccess = userRolePermission.some(
      (permission) =>
        permission.Permission_label === expectedPermission &&
        permission.edit === "true"
    );

    setAccess(hasAccess);
  }, [userRolePermission]);

  const classes = useStyles();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axiosInstance.get(
          "/api/vattara/app/splashScreen/image/get"
        );
        setOriginalImages(response.data.image);
        setImages(response.data.image);
        setPreviewImages(response.data.image.map(() => ""));
        setShowOptions(response.data.image.map(() => false));
        
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const handleReset = () => {
    setImages([...originalImages]);
    setPreviewImages(originalImages.map((item) => ""));
    setShowOptions(originalImages.map(() => false));
    setSelectedImageIndex(null);
  };

  const handleDiscardClick = (index) => {
    setImages((prevState) =>
      prevState.map((value, idx) =>
        idx === index ? originalImages[index] : value
      )
    );

    setPreviewImages((prevState) =>
      prevState.map((value, idx) =>
        idx === index ? originalImages[index]?.image?.url : value
      )
    );
    setShowOptions((prevState) =>
      prevState.map((value, idx) => (idx === index ? false : value))
    );

    if (selectedImageIndex === index) {
      setSelectedImageIndex(null);
    }

    setDiscardedImageUrl(discardedImageUrl);
  };

  const handlePreviewClick = (index) => {
    setSelectedImageIndex(index);
  };

  const uploadFileToS3 = async (bucketName, file) => {
    const fileName = file.name
      ? `images/${Date.now()}_${file.name}`
      : `images/${Date.now()}_default.png`; // Unique file name

    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };

    try {
      const uploadResponse = await S3.upload(params).promise();
      return uploadResponse.Location; // URL of the uploaded file
    } catch (error) {
      throw new Error(`Error uploading file to S3: ${error.message}`);
    }
  };
  const resizeImage = (file, fileName) => {
    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        2000, // New width
        1200, // New height
        "JPEG", // Format
        90, // Quality
        0, // Rotation
        (uri) => {
          resolve({
            blob: uri,
            name: fileName,
          });
        },
        "blob"
      );
    });
  };

  const uploadImage = async (uploadResponse) => {
    // try {
    //   // Extract imageUrl and imageName from the uploadResponse
    //   const response = await axios.post(
    //     "https://vattaram.in/api/vattara/app/splashScreen/imageUpload",
    //     uploadResponse,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data", // Set content type for FormData
    //       },
    //     }
    //   );
    //   return response;
    // } catch (error) {
    //   console.error("Error uploading image:", error);
    //   throw error;
    // }
  };

  const handleChange = async (event, index) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const { blob: resizedImageBlob, name: fileName } = await resizeImage(
          file,
          file.name
        );

        const resizedFile = new File([resizedImageBlob], fileName, {
          type: resizedImageBlob.type,
        });
        setUploading(index);
        //     setShowUpload(true);
        const fileUrl = await uploadFileToS3("vattara-img", resizedFile);
        setPreviewImages((prevState) =>
          prevState.map((value, idx) => (idx === index ? fileUrl : value))
        );
        setImages((prevState) =>
          prevState.map((value, idx) =>
            idx === index ? { ...value, image: fileUrl } : value
          )
        );
        setUploadedImages({
          image: {
            url: fileUrl,
            imagename: file.name,
          },
          id: images[index].id,
        });
        setShowOptions((prevState) =>
          prevState.map((value, idx) => (idx === index ? true : value))
        );
        //     setCurrentAdUpload((prev) => ({ ...prev, image: fileUrl }));
        setUploading("");
      } catch (error) {
        
      } finally {
        event.target.value = null;
      }
    }

    // const newImage = event.target.files && event.target.files[0];
    // if (newImage) {
    //   if (newImage.size > 200 * 1024) {
    //     alert(
    //       "file size exceeds 200kb. Please upload a file with size less than 200kb"
    //     );
    //     return;
    //   }
    //   const reader = new FileReader();
    //   reader.onload = async (e) => {
    //     const image = new Image();
    //     image.src = e.target.result;
    //     image.onload = async () => {
    //       const canvas = document.createElement("canvas");
    //       const maxWidth = 360; // Maximum width of the preview card image
    //       const maxHeight = 500; // Maximum height of the preview card image
    //       let width = image.width;
    //       let height = image.height;

    //       // Calculate new dimensions while maintaining aspect ratio
    //       if (width > maxWidth || height > maxHeight) {
    //         const ratio = Math.min(maxWidth / width, maxHeight / height);
    //         width *= ratio;
    //         height *= ratio;
    //       }

    //       // Set canvas dimensions
    //       canvas.width = width;
    //       canvas.height = height;

    //       // Draw image on canvas
    //       const ctx = canvas.getContext("2d");
    //       ctx.drawImage(image, 0, 0, width, height);

    //       // Convert canvas to base64 data URL
    //       const compressedImage = canvas.toDataURL("image/jpeg");

    //       // Update state with compressed image
    //       setPreviewImages((prevState) =>
    //         prevState.map((value, idx) =>
    //           idx === index ? compressedImage : value
    //         )
    //       );
    //       setImages((prevState) =>
    //         prevState.map((value, idx) =>
    //           idx === index ? { ...value, image: newImage } : value
    //         )
    //       );
    //       setShowOptions((prevState) =>
    //         prevState.map((value, idx) => (idx === index ? true : value))
    //       );

    //       // Upload image and update image data
    //       try {
    //         const formData = new FormData();
    //         formData.append("image", newImage);
    //         const response = await uploadImage(formData);
    //         

    //         const uploadResponse = await uploadImage(formData);
    //         setUploadResponse(uploadResponse);
    //         // Handle response as needed
    //       } catch (error) {
    //         console.error("Error uploading image:", error);
    //         // Handle error
    //       }
    //     };
    //   };
    //   reader.readAsDataURL(newImage);
    // }
  };

  const handleClickSave = async (index) => {
    try {
      // 
      // 

      // Make a PUT request to update image data
      const response = await axiosInstance.put(
        `/api/vattara/app/splashScreen/image/update`,
        {
          image: {
            url: uploadedImages?.image?.url,
            imagename: uploadedImages?.image?.imagename,
          },
          id: uploadedImages.id,
        }
      );

      // // Handle response from backend as needed
      

      // Update the state with the new image URL
      setImages((prevState) =>
        prevState.map((value, idx) =>
          idx === index
            ? {
                ...value,
                splash_screen_image: { url: uploadedImages.image.url },
              }
            : value
        )
      );

      setPreviewImages((prevState) =>
        prevState.map((value, idx) =>
          idx === index ? uploadedImages.image.url : value
        )
      );
      setShowOptions((prevState) =>
        prevState.map((value, idx) => (idx === index ? false : value))
      );

      // setSelectedImageIndex(null);
    } catch (error) {
      console.error("Error updating image data:", error);
    }
  };

  return (
    <>
      <p className={classes.heading}>Onboarding Screen Customization</p>
      <Divider
        orientation="horizontal"
        className=" lg:w-6/12 text-black relative top-4"
      />
      <div className={classes.root}>
        <Box className="bg-white w-full  h-[600px] xxl:h-[950px] xl:h-[980px]  lg:h-[1480px] md:h-[1450px] sm:h-[1500px] mt-5 rounded-lg ml-8 relative right-10 top-10">
          <div className="flex justify-between items-center flex-wrap">
            <p className={`${classes.heading} relative left-5`}>
              Upload your images here
            </p>
            <Button
              className="font-bold text-xs relative right-7 text-blue-600"
              onClick={handleReset}
            >
              <ReloadComponent className="relative top-[10px] right-[0.75px] lg:left-[100px] xl:left-[20px] xl:top-[8px] sm:left-[100px]" />{" "}
              <span className="relative top-[10px] right-[0.75px] lg:left-[105px] lg:top-[12px] xl:left-[30px] xl:top-[10px] sm:left-[105px] sm:top-[11px]">
                Reset all
              </span>
            </Button>
          </div>
          <Box className="flex w-full flex-wrap ">
            {images.map((imageData, index) => (
              <div
                key={imageData.id}
                className={`w-[240px] h-[320px] ml-8 custom-xl:mt-5 object-cover ${
                  index === 2
                    ? "custom-lg:mt-5 xxl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[100px] sm:mt-[100px] "
                    : "mt-5"
                } ${
                  index === 1
                    ? "custom-lg:mt-5 sl:mt-[100px] lg:mt-[100px] md:mt-[100px] xl:mt-5 sm:mt-[100px] "
                    : "mt-5"
                }`}
              >
                <Typography variant="subtitle1" className="font-bold text-sm">
                  Screen {index + 1}
                </Typography>
                <Card className="relative w-[220px] h-[320px] object-cover rounded-[5px]">
                  <img
                    src={
                      previewImages[index] ||
                      (imageData.image && imageData.image.url) ||
                      "" // Handle if url is undefined/null
                    }
                    alt={`Fetched image ${imageData.id}`}
                    onClick={() => handlePreviewClick(index)}
                    className="w-full h-full object-cover rounded"
                  />
                  {uploading === index && (
                    <div className="absolute inset-0 rounded-[4px] flex items-center justify-center w-full h-full bg-black opacity-50">
                      {<CircularProgress />}
                    </div>
                  )}
                </Card>
                <div
                  className={`flex flex-row items-start mt-2 transition-all duration-500 relative right-2 ${
                    showOptions[index] ? "ml-0" : "ml-[85px]" // Adjust the value based on the width of the buttons
                  }`}
                >
                  {access ? (
                    <IconButton
                      component="label"
                      htmlFor={`file-input-${index}`}
                      className="rounded-full bg-gray-300 relative  z-10"
                    >
                      <UploadComponent /> {/* Pass index here */}
                      <input
                        id={`file-input-${index}`}
                        type="file"
                        accept="image/*"
                        onChange={(event) => handleChange(event, index)}
                        className="hidden"
                      />
                    </IconButton>
                  ) : null}

                  {showOptions[index] && (
                    <div className="flex gap-2 mt-0 transition-all duration-500 ease-in-out opacity-100">
                      <IconButton
                        onClick={() => handlePreviewClick(index)}
                        className="font-bold p-0.5   relative right-4 z-10"
                      >
                        <ViewComponent />
                      </IconButton>
                      <Button
                        variant="contained"
                        color="inherit"
                        size="small"
                        onClick={() => handleDiscardClick(index)}
                        className={classes.discard}
                      >
                        Discard
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={() => handleClickSave(index, uploadResponse)}
                        className={classes.save}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <p className="mt-4 ml-2  text-xs font-roboto font-semibold relative top-24 left-4 sm:text-sm sm:mr-[5px]">
              Please ensure your image has the resized dimensions of 360*500 and
              file size under 200kb.
            </p>
          </Box>
        </Box>

        <Divider orientation="vertical" flexItem className={classes.line} />

        <div className="w-[360px] flex flex-col items-center ml-5 mt-14 ">
          <Card className={classes.previewCard}>
            <Typography variant="subtitle1" className={classes.previewscreen}>
              Preview Screen
            </Typography>
            <Box className="flex justify-between mt-2.5">
              <Box className="flex items-center gap-4 ml-2.5">
                <LogoComponent className="ml-2 mt-2" />
                <Typography className={classes.vattara}>Vattaram</Typography>
              </Box>
              <Button variant="outlined" className={classes.button}>
                Skip
              </Button>
            </Box>
            <Box className="m-2.5 text-left">
              <Typography className={classes.text}>
                FAST sell your property <br />
                in just <span className={classes.span}>one click</span>
              </Typography>
              <Typography variant="body2" className={classes.text1}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.
              </Typography>
              <div className="flex col relative top-[490px] left-8">
                <CircleComponent />
                <button className={classes.simple}>Next</button>
              </div>
            </Box>

            <CardMedia className={classes.previewCardMedia}>
              {selectedImageIndex !== null ? (
                <img
                  src={
                    previewImages[selectedImageIndex] ||
                    discardedImageUrl ||
                    (images[selectedImageIndex] &&
                      images[selectedImageIndex].image.url)
                  }
                  alt="Preview"
                  className="w-full h-full object-cover rounded-t-2xl"
                />
              ) : (
                <img
                  src={originalImages[0]?.image.url}
                  alt="Default"
                  className="w-full h-full object-cover rounded-t-3xl"
                />
              )}
            </CardMedia>
          </Card>
        </div>
      </div>
    </>
  );
};

export default InAppCustomization;
