import { createSlice } from "@reduxjs/toolkit";

export let formSlice = createSlice({
  name: "form",
  initialState: {
    selectedOptions: {},
    transactionType: "",
    subType: "",
    mergedValues: {}, // Initial state for mergedValues
  },

  reducers: {
    setSelectedOptions: (state, action) => {
      state.selectedOptions = action.payload.options;
      state.transactionType = action.payload.transactionType;
      state.subType = action.payload.subType;
    },
    resetBasicDetails: (state) => {
      state.selectedOptions = {};
      state.transactionType = "";
      state.subType = "";
      state.mergedValues = {};
    },
  },
});

export const { setSelectedOptions, setMergedValues, resetBasicDetails } =
  formSlice.actions;

export default formSlice.reducer;
