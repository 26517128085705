import { createContext, useEffect, useState } from "react";
import axiosInstance from "../Interceptor/axiosInstance";

const PostPropertyContext = createContext();

const PostPropertyProvider = ({ children }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [properties, setProperties] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [currentPropertyTypeTab, setCurrentPropertyTypeTab] =
    useState("Pending");
  const [originalList, setOriginalList] = useState();
  const [showDropDown, setShowDropDown] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedDateForFilter, setSelectedDateForFilter] = useState(null);
  const [listCount, setListCount] = useState(0);
  const [shimmerState, setShimmerState] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState();

  

  

  const handleSearchInput = (e) => {
    setSearchText(e.target.value);
  };
  const handleSearch = () => {
    setShimmerState(true);
    const filteredList = originalList.filter(
      (item) =>
        item?.propertyID.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.district.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.property_type.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.tbl_user_reg?.mobile_no
          .toLowerCase()
          .includes(searchText.toLowerCase())
    );
    setProperties(filteredList);
    setListCount(filteredList.length);
    setShimmerState(false);
  };
  const handleDateChange = (e) => {
    const date = new Date(e);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    setSelectedDateForFilter(formattedDate);
  };
  const handleDateFilter = () => {
    setShimmerState(true);
    const filteredData = originalList.filter((data) =>
      data.createdAt.includes(selectedDateForFilter)
    );
    setProperties(filteredData);
    setFilteredData(filteredData);
    setShowDropDown("");
    setListCount(filteredData.length);
    setShimmerState(false);
  };

  const handleUserCatgFilter = (catg) => {
    setSelectedFilter(catg);
    setShimmerState(true);

    const filteredList = filteredData.filter(
      (item) => item?.tbl_user_reg?.category === catg
    );
    setProperties(filteredList);
    setListCount(filteredList.length);
    setShimmerState(false);
  };

  const handleReset = () => {
    fetchData();
    setSelectedDateForFilter(null);
    setSearchText("");
    setSelectedFilter(null);
  };

  const fetchData = async () => {
    setShimmerState(true);
    const response = await axiosInstance.get("/api/admin/pending/properties");
    const data = response?.data;

    if (currentPropertyTypeTab === "Pending") {
      const propertiesList = data?.data?.filter(
        (item) => item?.post_approval === "Pending"
      );

      setProperties(propertiesList);
      setOriginalList(propertiesList);
      setFilteredData(propertiesList);
      setListCount(propertiesList.length);
    } else if (currentPropertyTypeTab === "Rejected") {
      const propertiesList = data?.data?.filter(
        (item) => item?.post_approval === "Rejected"
      );

      setProperties(propertiesList);
      setOriginalList(propertiesList);
      setFilteredData(propertiesList);
      setListCount(propertiesList.length);
    } else if (currentPropertyTypeTab === "Approved") {
      const propertiesList = data?.data?.filter(
        (item) => item?.post_approval === "Approved"
      );

      setProperties(propertiesList);
      setOriginalList(propertiesList);
      setFilteredData(propertiesList);
      setListCount(propertiesList.length);
    } else if (currentPropertyTypeTab === "All Properties") {
      const propertiesList = data?.data?.filter(
        (item) =>
          item?.post_approval === "Approved" ||
          item?.post_approval === "Rejected"
      );

      setProperties(propertiesList);
      setOriginalList(propertiesList);
      setFilteredData(propertiesList);
      setListCount(propertiesList.length);
    }
    // setProperties(data.data);
    // setOriginalList(data.data);
    

    setShimmerState(false);
  };

  useEffect(() => {
    fetchData();
    setSelectedProperty();
    setSelectedDateForFilter(null);
    setSelectedFilter(null);
  }, [currentPropertyTypeTab]);

  return (
    <PostPropertyContext.Provider
      value={{
        showDetails,
        setShowDetails,
        selectedProperty,
        setSelectedProperty,
        properties,
        setProperties,
        fetchData,
        currentPropertyTypeTab,
        setCurrentPropertyTypeTab,
        originalList,
        setOriginalList,
        showDropDown,
        setShowDropDown,
        searchText,
        setSearchText,
        selectedDateForFilter,
        setSelectedDateForFilter,
        handleSearchInput,
        handleSearch,
        handleDateChange,
        handleDateFilter,
        handleUserCatgFilter,
        filteredData,
        setFilteredData,
        listCount,
        setListCount,
        handleReset,
        shimmerState,
        setShimmerState,
        selectedFilter,
      }}
    >
      {children}
    </PostPropertyContext.Provider>
  );
};

export { PostPropertyContext, PostPropertyProvider };
