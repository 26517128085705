import React from "react";
import { FormProvider } from "../../context/formContext";
import PostPropertyForm from "./components/PostPropertyForm";
import { Provider } from "react-redux";
import store from "../../redux/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();

const PostProperty = () => {
  return (
    <Provider store={store}>
      <FormProvider>
        <QueryClientProvider client={queryClient}>
          <PostPropertyForm />
        </QueryClientProvider>
      </FormProvider>
    </Provider>
  );
};

export default PostProperty;
