import { Typography, Avatar, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectUserPermissions, logout } from "../../store/authSlice";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/framed logo.png";
import { MdChevronRight } from "react-icons/md";
import { useDispatch } from "react-redux";
import { IoSettingsOutline } from "react-icons/io5";

const useStyles = makeStyles({
  drawerPaper: {
    backgroundColor: "#0cb7d1", // Your desired background color
  },
});

const Sidebar = () => {
  const userRolePermission = useSelector(selectUserPermissions);
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const handleItemClick = () => {};

  function handleLogOut() {
    dispatch(logout());
    Navigate("/");
  }

  const renderButtons = () => {
    const buttons = [
      {
        items: [
          {
            label: "Dashboard",
            icon: (
              <div
                className={`${
                  location.pathname === "/dashboard"
                    ? "text-black"
                    : "text-[#ffffff]"
                }  text-2xl`}
              >
                <MdChevronRight />
              </div>
            ),
            path: "/dashboard",
            // No permission needed for Dashboard
          },
        ],
      },
      {
        permission: "Role Management",
        items: [
          {
            label: "Roles Management",
            icon: (
              <div className={`${
                location.pathname === "/rolesmanagement" || location.pathname === "/edit-role"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/rolesmanagement",
          },
        ],
      },
      {
        category: "Property Management",
        permission: "Property Management",
        items: [
          {
            label: "Report",
            icon: (
              <div className={`${
                location.pathname === "/reportedProperties"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/reportedProperties",
          },
          {
            label: "Review",
            icon: (
              <div className={`${
                location.pathname === "/propertyReviews"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/propertyReviews",
          },
          {
            label: "Post Approval",
            icon: (
              <div className={`${
                location.pathname === "/postApproval"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/postApproval",
          },
        ],
      },
      {
        category: "Employee Management",
        permission: "Employee Management",
        items: [
          {
            label: "Employee Management",
            icon: (
              <div className={`${
                location.pathname === "/usermanagement"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/usermanagement",
          },
        ],
      },
      {
        category: "Vattara Property Management",
        permission: "Vattara Property Management",
        items: [
          {
            label: "Vattara Property Management",
            icon: (
              <div className={`${
                location.pathname === "/vattaraverifiedproperties"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/vattaraverifiedproperties",
          },
          {
            label: "Post Property",
            icon: (
              <div className={`${
                location.pathname === "/postProperty"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/postProperty",
          },
        ],
      },
      {
        category: "In app customization",
        permission: "In App Customization",
        items: [
          {
            label: "Onboarding Screen",
            icon: (
              <div className={`${
                location.pathname === "/inApp"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/inApp",
          },
          {
            label: "Popular Places",
            icon: (
              <div className={`${
                location.pathname === "/popularPlaces"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/popularPlaces",
          },
        ],
      },
      {
        category: "Customer Support",
        permission: "Customer Support",
        items: [
          {
            label: "User Assistance",
            icon: (
              <div className={`${
                location.pathname === "/userAssistanceRequest"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/userAssistanceRequest",
          },
        ],
      },
      {
        category: "Customer Management",
        permission: "Customer Management",
        items: [
          {
            label: "Customer Details",
            icon: (
              <div className={`${
                location.pathname === "/customerManagement"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/customerManagement",
          },
        ],
      },
      {
        category: "Services",
        permission: "Services",
        items: [
          {
            label: "Service Listing Approval",
            icon: (
              <div className={`${
                location.pathname === "/servicelistingapproval"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/servicelistingapproval",
          },
        ],
      },
      {
        category: "Ad Posting",
        permission: "Ad Posting",
        items: [
          {
            label: "Ads Carousel",
            icon: (
              <div className={`${
                location.pathname === "/Adposting"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/Adposting",
          },
        ],
      },
      {
        category: "Upcoming Projects Ad",
        permission: "Upcoming Projects Ad",
        items: [
          {
            label: "Upcoming Projects",
            icon: (
              <div className={`${
                location.pathname === "/upcomingProjects"
                  ? "text-black"
                  : "text-[#ffffff]"
              }  text-2xl`}>
                <MdChevronRight />
              </div>
            ),
            path: "/upcomingProjects",
          },
        ],
      },
      // {
      //   permission: "Careers",
      //   category: "Careers",
      //   items: [
      //     {
      //       label: "Job Portal",
      //       icon: (
      //         <div className=" text-[#ffffff] text-2xl">
      //           <MdChevronRight />
      //         </div>
      //       ),
      //       path: "/jobPortal",
      //     },
      //   ],
      // },
    ];

    return buttons
      .filter((button) => {
        if (!button.permission) {
          return true;
        }
        const permission = userRolePermission.find(
          (permission) => permission.Permission_label === button.permission
        );

        if (permission) {
          if (permission.Permission_label === "Vattara Property Management") {
            return permission.view === "true" && permission.create === "true";
          }
          return permission.view === "true";
        }
        return false;
      })
      .map((button, i) => (
        <div className="mx-2 xl:ml-4 my-5 text-xs lg:text-sm" key={i}>
          {button.category ? (
            <div className="flex items-center gap-2 mb-3">
              <div>
                <IoSettingsOutline className="text-[20px]" />
              </div>
              <h2 className="text-[#000000] text-[15px] tracking-tighter ">
                {button.category}
              </h2>
            </div>
          ) : null}
          {button.items.map((item) => (
            <Link
              to={item.path}
              onClick={() => handleItemClick(item.path)}
              className={`${
                location.pathname === item.path ? "bg-white text-black" : "text-white"
              } transition-all font-Inter flex items-center gap-1 py-1 ml-5 hover:font-medium rounded-md`}
              key={item.label}
            >
              <div onClick={() => handleItemClick(item.path)}>{item.icon}</div>
              <div className=" font-Inter block">
                {item.label}
              </div>
            </Link>
          ))}
        </div>
      ));
  };

  return (
    <Drawer
      className="w-[195px] lg:w-64 flex-shrink-0 lg:flex-shrink-4 z-0" // Apply Tailwind CSS utility classes
      variant="permanent"
      classes={{
        paper: `${classes.drawerPaper} w-[195px] lg:w-64 flex-shrink-0 lg:flex-shrink-4`, // Apply the makeStyles class to override the default background color
      }}
    >
      <div className="bg-white py-6 px-2 flex justify-between items-center">
        <div className="flex items-center gap-1">
          <img className="w-[123px]" src={Logo} alt="" />
        </div>
        <div className="bg-[#E5E9EB] px-2 h-fit font-medium text-sm rounded-md">
          v1.2.8
        </div>
      </div>

      <div className="overflow-y-auto hide-scrollbar">
        {" "}
        <List>{renderButtons()}</List>
      </div>
      <div className="w-full flex justify-end">
        <button
          onClick={handleLogOut}
          className="bg-white text-red-500 cursor-pointer py-1 px-4 mr-4 my-5 rounded-md font-medium w-fit hover:shadow-md"
        >
          Logout
        </button>
      </div>
    </Drawer>
  );
};

export default Sidebar;
