import React, { useContext } from "react";
import { UpcomingProjectContext } from "../../../context/UpcomingProjectContext";
import { FaChevronLeft } from "react-icons/fa";

const PreviewForSingleProject = () => {
  const { projects, currentImgId } = useContext(UpcomingProjectContext);
  const currentProject =
    projects && projects.find((img) => img.id === currentImgId);

  return (
    <div className="w-[70%] mx-auto bg-white pb-4 my-8 rounded-2xl">
      <div className="bg-[#000000] font-roboto font-bold text-sm text-white rounded-t-2xl px-3 py-2 text-center">
        Preview Screen
      </div>
      <div className="">
        <div className="bg-[#F5F4F8] p-2 font-k2d font-bold text-sm flex items-center gap-1">
          <FaChevronLeft />
          Upcoming Projects
        </div>
        {currentProject &&
          currentProject.images.map((img, i) => (
            <>
              {img.uri ? (
                <img
                  className="w-full h-[100px] object-cover"
                  src={img.uri}
                  alt="projectImg"
                  key={i}
                />
              ) : null}
            </>
          ))}
      </div>
    </div>
  );
};

export default PreviewForSingleProject;
