import React, { useEffect, useState } from 'react';
import axiosInstance from '../../Interceptor/axiosInstance';
import { Skeleton } from '@mui/material';

const Report = () => {
  const [count, setCount] = useState("");
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    async function help() {
      try {
        const response = await axiosInstance.get('/api/admin/reported/status/count');
        
        setCount(response.data);
      } catch (e) {
        
      } finally {
        setLoading(false); // Set loading to false after the request is done
      }
    }
    help();
  }, []);

  return (
    // <div className="bg-gray-200 p-8 rounded h-64 ml-">
    //   {loading ? ( // Conditional rendering based on loading state
    //     <Skeleton className='h-64' />
    //   ) : (
    //     <div>
    //       <div>
    //         <p className='font-semibold text-lg'>Reported count</p>
    //       </div>
    //       <div className='flex flex-col gap-2'>
    //         <div className='flex mt-5'>
    //           <p className='w-[50%]'>Reviewed :</p>

    //           <p  className='w-[50%]'> {count.Reviewed }</p>
    //         </div>
    //         <div className='flex '>
    //           <p className='w-[50%]'>Pending:</p>

    //           <p  className='w-[50%]'> {count.Pending}</p>
    //         </div>

    //       </div>
    //     </div>
    //   )}
    // </div>
    <div className="bg-white shadow-md p-8 rounded-[15px] h-[320px]">
      {loading ? ( // Conditional rendering based on loading state
        <Skeleton className="h-64" />
      ) : (
        <div className="flex flex-col gap-3 items-center justify-center">
          <p className="font-semibold uppercase text-xl">Reported count</p>
          <div className="bg-gray-200 w-[90%]  p-6 rounded-[15px] text-center">
            <h3 className="text-[18px] uppercase">Reviewed</h3>
            <p className="text-3xl">{count.Reviewed}</p>
          </div>
          <div className="bg-gray-200 w-[90%]  p-6 rounded-[15px] text-center">
            <h3 className="text-[18px] uppercase">Pending</h3>
            <p className="text-3xl">{count.Pending}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Report;
